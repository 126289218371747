import clsx from "clsx";
import Avatar from "../Avatar";
import { MeetingRequestsActions } from "./MeetingRequestsActions";
import MeetingParticipant from "../meetings/MeetingParticipant";
import { MEETING_INVITATION_RESPONSE } from "../../utils/constants";
import dayjs from "dayjs";

import { ReactComponent as TickIcon } from "../../assets/icons/Tick.svg";
import { ReactComponent as CrossIcon } from "../../assets/icons/Cross.svg";
import { ReactComponent as ArrowForward } from "../../assets/icons/IoIoArrowForword.svg";

export const MeetingRequestCard = ({
  meetingRequest,
  onAccept,
  onDecline,
  setShowAgendaDetailModal,
}) => {
  return (
    <div
      className={clsx(
        "bg-neutral border border-gray500 rounded-[10px] shadow-theme relative",
        "py-6 mb-4 pl-4"
      )}
    >
      <div className="grid gap-4">
        {/* First row: Topic and forward icon */}
        <div className="flex items-center justify-between">
          <p className="text-base text-left">{meetingRequest.topic}</p>
          <div className="ml-auto">
            <ArrowForward className="mr-4" />
          </div>
        </div>

        {/* Second row: Date */}
        <div className="flex items-center justify-between relative">
          <p className="text-base">
            {dayjs(meetingRequest.proposalDateTimesToBeHeldStart).format(
              "MMM D"
            )}{" "}
            -{" "}
            {dayjs(meetingRequest.proposalDateTimesToBeHeldEnd).format(
              "MMM D, YYYY"
            )}
          </p>
          {meetingRequest.response === MEETING_INVITATION_RESPONSE.ACCEPTED &&
            !meetingRequest.isHost && (
              <div
                className={clsx(
                  "flex items-center gap-1 px-3 rounded-1",
                  "text-sm leading-loose absolute right-0",
                  "bg-success100 text-success"
                )}
              >
                <TickIcon fill="transparent" />
                <span>Accepted</span>
              </div>
            )}

          {meetingRequest.response === MEETING_INVITATION_RESPONSE.REJECTED && (
            <div
              className={clsx(
                "flex items-center gap-1 px-3 rounded-1",
                "text-sm leading-loose absolute right-0",
                "bg-danger100 text-danger"
              )}
            >
              <CrossIcon fill="transparent" />
              <span>Declined</span>
            </div>
          )}
        </div>

        {/* Third row: Avatar and actions */}
        <div className="flex items-center justify-between">
          <Avatar
            userId={meetingRequest.hostPersonID}
            firstName={meetingRequest.host}
            lastName=""
            className="w-8 h-8"
            profileById={true}
          />
          {!meetingRequest.isHost && (
            <MeetingRequestsActions
            
              response={meetingRequest.response}
              onAccept={onAccept}
              onDecline={onDecline}
              hideClose={true}
            />
          )}
        </div>
      </div>
    </div>
  );
};
