import {
  createSlice,
  createSelector,
  createAsyncThunk,
  createAction,
} from "@reduxjs/toolkit";
import axiosClient from "../utils/axiosClient";
import {
  MEETING_REQUEST_BASE_API,
  GET_INVITABLE_USERS_API,
  GET_MEETING_REQUEST_INVITES,
  RESPONSE_TO_PROPOSAL_API,
  CHECK_MEETING_VALIDITY,
  GET_External_INVITABLE_USERS_API,
  CHECK_MEPA_MEETING_VALIDITY,
  GET_Check_Meeting_Time_Slot,
  MEPA_MEETING_REQUEST,
  Response_To_MEPA,
  GET_MY_FUTURE_PROPOSALS,
  MEPA_SELECT_TIME_SLOTS,
} from "../utils/apiRoutes";
import {
  parseCreateMeetingRequestDataForApi,
  parseMyInvitationsData,
  parseMyInvitationsDataNew,
  queryfyUrl,
} from "../utils/dataParser";
import { toast } from "react-toastify";
import { refreshScheduledEvents, removeEventFromList } from "./scheduledEvents";
import { MEETING_INVITATION_RESPONSE } from "../utils/constants";

const initialState = {
  invitableUsers: [],
  externalInvitees: [],
  meetingTimeSlots: [],
  createdMepaResponseId: "",
  activeModal: "",
  invitableUsersLoading: false,
  invitableExternalUsersLoading: false,
  addMeetingRequestLoading: false,
  addMetingtimeSlotLoading: false,
  addMepaMeetingRequestLoading: false,
  addMeetingRequestSuccess: null,
  addMepaMeetingRequestSuccess: null,
  addMepaTimesloteSuccess: null,
  shouldContinueInterval: true,
  timeoutId: null,
  checkMeetingValidity: null,
  checkMeetingValidityError: null,
  addMeetingRequestError: null,
  addMepaMeetingRequestError: null,
  meetingInvitations: [],
  upComingMeetingInvitee: [],
  meetingInvitationsLoading: false,
  meetingInvitationsRefreshing: false,
  meetingResponseLoading: false,
  meetingResponseSuccess: null,
  meetingResponseError: null,
  meetingRequestDeletionLoading: false,
  meetingRequestDeletionSuccess: null,
  meetingRequestDeletionError: null,
};

export const updateTimeoutId = createAction("meetingRequests/updateTimeoutId");
export const clearTimeoutId = createAction("meetingRequests/clearTimeoutId");
export const checkMeetingTimeSlotsUser = createAsyncThunk(
  "meetingRequests/checkMeetingTimeSlotsUser",
  async (id, { getState, dispatch, rejectWithValue }) => {
    const { serverUrl } = getState().configs;
    const { createdMepaResponseId, shouldContinueInterval } =
      getState().meetingRequests;
    // const { ExternalinvitableUsers } = getState();

    if (!shouldContinueInterval) {
      return;
    }

    const { data: result } = await axiosClient.get(
      `${serverUrl}/${GET_Check_Meeting_Time_Slot}?id=${
        id ? id : createdMepaResponseId
      }`
    );

    if (!result.scheduled) {
      const timeoutId = setTimeout(() => {
        dispatch(checkMeetingTimeSlotsUser());
      }, 5000);

      dispatch(updateTimeoutId(timeoutId));
      return new Promise(() => {});
    }
    let isValidDate = result.meetingProposalStatus.filter(
      (item) => item.isValid
    );
    let payload = { ...result, meetingProposalStatus: isValidDate };

    if (payload?.meetingProposalStatus?.length === 0) {
      return rejectWithValue(
        " We are not able to find valid time slot. Please change the parameters of start and end date."
      );
    }
    return payload;

    // return result;
  }
);
export const postMeetingTimeSLotData = createAsyncThunk(
  "meetingRequests/postmeetingTimeSlots",
  async (data, { getState, rejectWithValue, fulfillWithValue, dispatch }) => {
    const { serverUrl } = getState().configs;
    const url = `${serverUrl}/${MEPA_SELECT_TIME_SLOTS}`;
    await axiosClient.post(url, data);
    return fulfillWithValue(data);
  }
);
export const fetchExternalInvitableUsers = createAsyncThunk(
  "meetingRequests/fetchExternalInvitableUsers",
  async (keyword, { getState }) => {
    const { serverUrl } = getState().configs;

    console.log("API hit", keyword);
    const { data: result } = await axiosClient.get(
      `${serverUrl}/${GET_External_INVITABLE_USERS_API}?keyword=${keyword}`
    );
    return result;
  }
);

export const fetchInvitableUsers = createAsyncThunk(
  "meetingRequests/fetchInvitableUsers",
  async (_, { getState }) => {
    const { serverUrl } = getState().configs;
    const { currentUser } = getState().user;
    const { invitableUsers } = getState().meetingRequests;

    // If list is already fetch, prevent re-fetching
    if (invitableUsers.length > 0) {
      return invitableUsers;
    }

    const { data: result } = await axiosClient.get(
      `${serverUrl}/${GET_INVITABLE_USERS_API}`
    );
    // console.log("resul for internal apois ;", result);
    // Remove current login user as cannot invite self
    const filteredResult = result.filter(
      (item) => item.id !== currentUser.userId
    );

    return filteredResult;
  }
);

export const addUpdateMeetingRequest = createAsyncThunk(
  "meetingRequests/addUpdateMeetingRequest",
  async (data, { getState, rejectWithValue, fulfillWithValue, dispatch }) => {
    const { serverUrl } = getState().configs;
    const { currentUser } = getState().user;
    const parsedData = parseCreateMeetingRequestDataForApi(data, currentUser);
    try {
      if (data.id) {
        await axiosClient.put(
          `${serverUrl}/${MEETING_REQUEST_BASE_API}`,
          parsedData
        );
        // Refresh Events
        dispatch(refreshScheduledEvents());

        return fulfillWithValue(
          "Meeting proposal has been updated successfully."
        );
      } else {
        await axiosClient.post(
          `${serverUrl}/${MEETING_REQUEST_BASE_API}`,
          parsedData
        );
        return fulfillWithValue(
          "Meeting proposal has been proposed successfully."
        );
      }
    } catch (error) {
      const parsedErrors = Object.values(error.response.data.errors).flat();
      return rejectWithValue(parsedErrors.join(" "));
    }
  }
);

export const validateMeetingRequest = createAsyncThunk(
  "meetingRequests/validateMeetingRequest",
  async (data, { getState, rejectWithValue, fulfillWithValue, dispatch }) => {
    const { serverUrl } = getState().configs;
    const { currentUser } = getState().user;
    const parsedData = parseCreateMeetingRequestDataForApi(data, currentUser);
    try {
      const { data } = await axiosClient.post(
        `${serverUrl}/${CHECK_MEETING_VALIDITY}`,
        parsedData
      );

      if (!data?.succeed) {
        return rejectWithValue(data);
      }

      return fulfillWithValue(data);
    } catch (error) {
      const parsedErrors = Object.values(error.response.data.errors).flat();
      return rejectWithValue(parsedErrors.join(" "));
    }
  }
);
export const validateMepaMeetingRequest = createAsyncThunk(
  "Scheduling/CheckMEPAValidity",
  async (data, { getState, rejectWithValue, fulfillWithValue, dispatch }) => {
    const { serverUrl } = getState().configs;
    const { currentUser } = getState().user;
    const parsedData = parseCreateMeetingRequestDataForApi(data, currentUser);
    try {
      const response = await axiosClient.post(
        `${serverUrl}/${CHECK_MEPA_MEETING_VALIDITY}`,
        parsedData
      );

      if (!response.data?.succeed) {
        return rejectWithValue(response.data);
      }
      const secondApiResponse = await axiosClient.post(
        `${serverUrl}/${MEPA_MEETING_REQUEST}`,
        parsedData
      );

      // if (!secondApiResponse.data?.succeed) {
      //   return rejectWithValue(secondApiResponse.data);
      // }

      dispatch(checkMeetingTimeSlotsUser(secondApiResponse.data));
      return fulfillWithValue(secondApiResponse.data);
    } catch (error) {
      const parsedErrors = Object.values(error.response.data.errors).flat();
      return rejectWithValue(parsedErrors.join(" "));
    }
  }
);

export const fetchMeetingInvitations = createAsyncThunk(
  "MeetingProposal/GetMyFutureInvites)",
  async (isRefreshing, { getState, dispatch }) => {
    const { serverUrl } = getState().configs;
    const { currentUser } = getState().user;
    if (isRefreshing) {
      dispatch(setMeetingRequestsRefresh());
    }
    const { data: result } = await axiosClient.get(
      `${serverUrl}/${GET_MEETING_REQUEST_INVITES}`
    );

    // Parsed the Data
    const parsedResult = parseMyInvitationsData(
      result.data,
      currentUser.userId
    );
    return parsedResult;
  }
);

export const fetchMyAllUpcomingMeetings = createAsyncThunk(
  "MeetingProposal/upcomingInvites)",
  async (isRefreshing, { getState, dispatch }) => {
    const { serverUrl } = getState().configs;
    const { currentUser } = getState().user;
    // if (isRefreshing) {
    //   dispatch(setMeetingRequestsRefresh());
    // }

    const { data: result } = await axiosClient.get(
      `${serverUrl}/${GET_MY_FUTURE_PROPOSALS}`,
      {
        params: {
          pageSize: 20,
          pageNumber: 0,
          eventsContributionType: 4,
        },
      }
    );

    // Parsed the Data
    const parsedResult = parseMyInvitationsDataNew(
      result.data,
      currentUser.userId
    );
    return parsedResult;
  }
);

export const responseToMeetingInvitation = createAsyncThunk(
  "meetingRequests/responseToMeetingInvitation",
  async (responseData, { getState, dispatch, fulfillWithValue }) => {
    const { serverUrl } = getState().configs;
    const { meetingInvitations } = getState().meetingRequests;

    let url = "";

    if (responseData?.mepaId) {
      url = queryfyUrl(`${serverUrl}/${Response_To_MEPA}`, responseData);
    } else {
      url = queryfyUrl(
        `${serverUrl}/${RESPONSE_TO_PROPOSAL_API}`,
        responseData
      );
    }

    await axiosClient.post(url);

    // Update the Existing Records
    const existingInvitations = [...meetingInvitations];
    const invitationToUpdateIndex = existingInvitations.findIndex(
      (item) => item.meetingProposalID === responseData.proposalId
    );
    if (invitationToUpdateIndex !== -1) {
      existingInvitations[invitationToUpdateIndex] = {
        ...existingInvitations[invitationToUpdateIndex],
        response: responseData.response,
      };
    }

    dispatch(setUpdatedInvitations(existingInvitations));

    // Remove the corresponding meeting from the meetings list when declined
    if (responseData.response === MEETING_INVITATION_RESPONSE.REJECTED) {
      dispatch(removeEventFromList(responseData.proposalId));
    }

    // Post appropriate message
    return fulfillWithValue(
      responseData.response === MEETING_INVITATION_RESPONSE.ACCEPTED
        ? "You have accepted the invitation"
        : "You have declined the invition"
    );
  }
);

export const deleteMeetingRequest = createAsyncThunk(
  "meetingRequests/deleteMeetingRequest",
  async (data, { getState, dispatch, fulfillWithValue }) => {
    const { serverUrl } = getState().configs;
    const { meetingInvitations } = getState().meetingRequests;

    const { mepaId, meetingRequestId, hostPersonID, userId } = data;

    if (mepaId) {
      const url = `${serverUrl}/${MEPA_MEETING_REQUEST}/${mepaId}`;
      await axiosClient.delete(url);
    } else {
      const url = `${serverUrl}/${MEETING_REQUEST_BASE_API}/${meetingRequestId}`;
      await axiosClient.delete(url);
    }

    // Update the Existing Records
    const existingInvitations = [...meetingInvitations].filter(
      (item) => item.meetingProposalID !== meetingRequestId
    );

    dispatch(setUpdatedInvitations(existingInvitations));

    // Remove the corresponding meeting from the meetings list when deleted
    dispatch(removeEventFromList(meetingRequestId));

    // Post appropriate message
    if (userId === hostPersonID) {
      return fulfillWithValue("The meeting has been deleted");
    } else {
      return fulfillWithValue("The meeting request has been deleted.");
    }
  }
);

const slice = createSlice({
  name: "meetingRequests",
  initialState,
  reducers: {
    resetAddMeetingStatus: (state) => {
      state.addMeetingRequestLoading = false;
      state.addMepaMeetingRequestSuccess = false;
      state.addMeetingRequestSuccess = null;
      state.addMeetingRequestError = null;
      state.addMepaTimesloteSuccess = false;
      state.checkMeetingValidity = null;
    },
    setUpdatedInvitations: (state, { payload }) => {
      state.meetingInvitations = payload;
    },
    setMeetingRequestsRefresh: (state) => {
      state.meetingInvitationsRefreshing = true;
    },
    resetAddMeetingError: (state) => {
      state.addMeetingRequestError = null;
      state.addMepaMeetingRequestError = null;
      state.addMepaTimesloteSuccess = null;
    },
    resetMeetingResponseError: (state) => {
      state.meetingResponseError = null;
    },
    resetMeetingRequestsDeletionError: (state) => {
      state.meetingRequestDeletionError = null;
    },
    resetMeetingResponseStatus: (state) => {
      state.meetingResponseLoading = false;
      state.meetingResponseSuccess = null;
      state.meetingResponseError = null;
    },
    resetMeetingRequestDeletionStatus: (state) => {
      state.meetingRequestDeletionLoading = false;
      state.meetingRequestDeletionSuccess = null;
      state.meetingRequestDeletionError = null;
    },
    setActiveModal: (state, { payload }) => {
      state.activeModal = payload;
    },
    updateIntervalValue: (state, { payload }) => {
      state.shouldContinueInterval = payload;
      // console.log("update function :",state.timeoutId)
    },
    updateTimeoutId: (state, action) => {
      state.timeoutId = action.payload;
    },
    clearTimeoutId: (state) => {
      clearTimeout(state.timeoutId);
      state.timeoutId = null;
    },
    resetMeetingRequestsState: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchInvitableUsers.pending, (state) => {
      state.invitableUsersLoading = true;
    });
    builder.addCase(fetchInvitableUsers.fulfilled, (state, { payload }) => {
      state.invitableUsersLoading = false;
      state.invitableUsers = payload;
    });
    builder.addCase(fetchInvitableUsers.rejected, (state) => {
      state.invitableUsersLoading = false;
    });
    builder.addCase(fetchExternalInvitableUsers.pending, (state) => {
      state.invitableExternalUsersLoading = true;
    });
    builder.addCase(
      fetchExternalInvitableUsers.fulfilled,
      (state, { payload }) => {
        state.invitableExternalUsersLoading = false;
        state.externalInvitees = payload;
      }
    );
    builder.addCase(fetchExternalInvitableUsers.rejected, (state) => {
      state.invitableExternalUsersLoading = false;
    });
    builder.addCase(checkMeetingTimeSlotsUser.pending, (state) => {
      // console.log("metting time slot state in redux pending", state);
      state.addMetingtimeSlotLoading = true;
    });
    builder.addCase(
      checkMeetingTimeSlotsUser.fulfilled,
      (state, { payload }) => {
        // console.log("metting time slot state in redux fullfilled", state);
        state.activeModal = "mepa";
        state.addMetingtimeSlotLoading = false;
        state.addMepaTimesloteSuccess = true;
        // state.checkMeetingValidity=true
        state.addMepaMeetingRequestSuccess = false;
        state.meetingTimeSlots = payload?.meetingProposalStatus;
      }
    );
    builder.addCase(
      checkMeetingTimeSlotsUser.rejected,
      (state, { payload }) => {
        console.log(
          "metting time slot state in redux rejection",
          state,
          payload
        );
        state.addMeetingRequestLoading = false;
        state.addMeetingRequestSuccess = null;
        state.addMepaMeetingRequestSuccess = null;
        state.addMetingtimeSlotLoading = false;
        state.addMepaTimesloteSuccess = false;
        state.addMepaMeetingRequestError =
          payload || "There was an error with your mepa meeting request";
      }
    );
    builder.addCase(postMeetingTimeSLotData.pending, (state) => {
      // console.log("metting post slot state in redux pending", state);
      state.addMetingtimeSlotLoading = true;
    });
    builder.addCase(postMeetingTimeSLotData.fulfilled, (state, { payload }) => {
      // console.log("metting post slot state in redux fullfilled", state);
      toast.success("submitted sucessfully");
      state.activeModal = "";
      state.addMeetingRequestLoading = false;
      state.addMepaMeetingRequestSuccess = false;
      state.addMeetingRequestSuccess = null;
      state.addMeetingRequestError = null;
      state.addMepaTimesloteSuccess = false;
      state.addMepaMeetingRequestError = null;
      state.addMetingtimeSlotLoading = false;
    });
    builder.addCase(postMeetingTimeSLotData.rejected, (state, { payload }) => {
      // console.log("metting post slot state in redux rejection", state);
      state.addMetingtimeSlotLoading = false;
      state.addMepaTimesloteSuccess = false;
      state.addMepaMeetingRequestError =
        payload?.errorMessage ||
        "There was an error with your mepa meeting request";
    });
    builder.addCase(addUpdateMeetingRequest.pending, (state) => {
      state.addMeetingRequestLoading = true;
      state.addMeetingRequestSuccess = null;
      state.addMeetingRequestError = null;
      state.checkMeetingValidityError = null;
      state.checkMeetingValidity = null;
    });
    builder.addCase(validateMeetingRequest.pending, (state) => {
      state.addMeetingRequestLoading = true;
      state.addMeetingRequestSuccess = null;
      state.addMeetingRequestError = null;
      state.checkMeetingValidityError = null;
      state.checkMeetingValidity = null;
    });
    builder.addCase(validateMeetingRequest.fulfilled, (state, { payload }) => {
      state.addMeetingRequestLoading = false;
      state.checkMeetingValidity = true;
      state.checkMeetingValidityError = payload.errorMessage;
    });
    builder.addCase(validateMeetingRequest.rejected, (state, { payload }) => {
      state.addMeetingRequestLoading = false;
      state.addMeetingRequestSuccess = null;
      state.addMeetingRequestError =
        payload?.errorMessage || "There was an error with your meeting request";
    });

    // Mepa Meeting Api field
    builder.addCase(validateMepaMeetingRequest.pending, (state) => {
      // console.log("mepa state === pending ")
      state.addMeetingRequestLoading = true;
      // state.addMeetingRequestLoading = true;
      state.addMeetingRequestSuccess = null;
      state.addMeetingRequestError = null;
      state.checkMeetingValidityError = null;
      state.checkMeetingValidity = null;
    });
    builder.addCase(
      validateMepaMeetingRequest.fulfilled,
      (state, { payload }) => {
        // console.log("mepa state === fulfilled ")
        state.addMepaMeetingRequestSuccess = true;
        state.addMeetingRequestLoading = false;
        state.checkMeetingValidity = true;
        // console.log("checkingId :",payload)
        state.createdMepaResponseId = payload;
      }
    );
    builder.addCase(
      validateMepaMeetingRequest.rejected,
      (state, { payload }) => {
        // console.log("mepa state === rejected ")
        state.addMeetingRequestLoading = false;
        state.addMeetingRequestSuccess = null;
        state.addMepaMeetingRequestError =
          payload?.errorMessage ||
          "There was an error with your mepa meeting request";
      }
    );
    builder.addCase(addUpdateMeetingRequest.fulfilled, (state, { payload }) => {
      state.addMeetingRequestLoading = false;
      state.addMeetingRequestSuccess = payload;
    });

    builder.addCase(addUpdateMeetingRequest.rejected, (state, { payload }) => {
      state.addMeetingRequestLoading = false;
      state.addMeetingRequestError =
        payload || "There was an error with your meeting request";
    });
    builder.addCase(fetchMeetingInvitations.pending, (state) => {
      state.meetingInvitationsLoading = true;
    });
    builder.addCase(fetchMeetingInvitations.fulfilled, (state, { payload }) => {
      state.meetingInvitationsLoading = false;
      state.meetingInvitationsRefreshing = false;
      state.meetingInvitations = payload;
    });
    builder.addCase(fetchMeetingInvitations.rejected, (state) => {
      state.meetingInvitationsLoading = false;
      state.meetingInvitationsRefreshing = false;
    });

    builder.addCase(fetchMyAllUpcomingMeetings.pending, (state) => {
      state.meetingInvitationsLoading = true;
    });
    builder.addCase(
      fetchMyAllUpcomingMeetings.fulfilled,
      (state, { payload }) => {
        state.meetingInvitationsLoading = false;
        state.meetingInvitationsRefreshing = false;
        state.upComingMeetingInvitee = payload;
      }
    );
    builder.addCase(fetchMyAllUpcomingMeetings.rejected, (state) => {
      state.meetingInvitationsLoading = false;
      state.meetingInvitationsRefreshing = false;
    });
    builder.addCase(responseToMeetingInvitation.pending, (state) => {
      state.meetingResponseLoading = true;
    });
    builder.addCase(
      responseToMeetingInvitation.fulfilled,
      (state, { payload }) => {
        state.meetingResponseLoading = false;
        state.meetingResponseSuccess = payload;
      }
    );
    builder.addCase(responseToMeetingInvitation.rejected, (state) => {
      state.meetingResponseLoading = false;
      state.meetingResponseError =
        "Sorry! Failed to process your request this time, please retry again,";
    });
    builder.addCase(deleteMeetingRequest.pending, (state) => {
      state.meetingRequestDeletionLoading = true;
    });
    builder.addCase(deleteMeetingRequest.fulfilled, (state, { payload }) => {
      state.meetingRequestDeletionLoading = false;
      state.meetingRequestDeletionSuccess = payload;
      state.addMetingtimeSlotLoading = false;
    });
    builder.addCase(deleteMeetingRequest.rejected, (state) => {
      state.meetingRequestDeletionLoading = false;
      state.meetingRequestDeletionError =
        "Sorry! Failed to process your request this time, please retry again,";
    });
  },
});

// ACTIONS

export const {
  resetAddMeetingError,
  setUpdatedInvitations,
  setMeetingRequestsRefresh,
  resetAddMeetingStatus,
  resetMeetingRequestsState,
  resetMeetingResponseStatus,
  resetMeetingResponseError,
  resetMeetingRequestsDeletionError,
  resetMeetingRequestDeletionStatus,
  setActiveModal,
  updateIntervalValue,
} = slice.actions;

// SELECTORS
const selectMeetingRequestsData = (state) => {
  return state.meetingRequests;
};

export const selectInvitableUsers = createSelector(
  selectMeetingRequestsData,
  ({ invitableUsers, invitableUsersLoading }) => ({
    invitableUsers,
    invitableUsersLoading,
  })
);
export const selectExternalInvitableUsers = createSelector(
  selectMeetingRequestsData,
  ({ externalInvitees, invitableExternalUsersLoading }) => ({
    externalInvitees,
    invitableExternalUsersLoading,
  })
);
export const selectMeetingTimeSlots = createSelector(
  selectMeetingRequestsData,
  ({ meetingTimeSlots, addMetingtimeSlotLoading }) => ({
    meetingTimeSlots,
    addMetingtimeSlotLoading,
  })
);

export const selectAddMeetingRequestStatus = createSelector(
  selectMeetingRequestsData,
  ({
    addMeetingRequestLoading,
    activeModal,
    addMeetingRequestSuccess,
    addMepaMeetingRequestError,
    addMeetingRequestError,
    addMepaMeetingRequestSuccess,
    checkMeetingValidity,
    addMepaTimesloteSuccess,
    checkMeetingValidityError,
  }) => ({
    addMeetingRequestLoading,
    activeModal,
    addMeetingRequestSuccess,
    addMepaMeetingRequestSuccess,
    addMepaMeetingRequestError,
    addMeetingRequestError,
    checkMeetingValidity,
    addMepaTimesloteSuccess,
    checkMeetingValidityError,
  })
);

export const selectMeetingInvitations = createSelector(
  selectMeetingRequestsData,
  ({
    meetingInvitations,
    meetingInvitationsLoading,
    meetingInvitationsRefreshing,
  }) => ({
    meetingInvitations,
    meetingInvitationsLoading,
    meetingInvitationsRefreshing,
  })
);

export const selectMeetingResponseStatus = createSelector(
  selectMeetingRequestsData,
  ({
    meetingResponseLoading,
    meetingResponseSuccess,
    meetingResponseError,
  }) => ({
    meetingResponseLoading,
    meetingResponseSuccess,
    meetingResponseError,
  })
);

export const selectMeetingRequestDeletionStatus = createSelector(
  selectMeetingRequestsData,
  ({
    meetingRequestDeletionLoading,
    meetingRequestDeletionSuccess,
    meetingRequestDeletionError,
  }) => ({
    meetingRequestDeletionLoading,
    meetingRequestDeletionSuccess,
    meetingRequestDeletionError,
  })
);

export default slice.reducer;
