import clsx from 'clsx';
import Avatar from './Avatar';

export const ParticipantsChip = ({
	participants,
	remainingCounter,
	containerClassName,
	onClick,
}) => {
	const handleClick = () => {
		if (onClick) {
			onClick();
		}
	};
	return (
		<div
			className={clsx(
				'flex bg-neutral rounded-full w-fit',
				participants.length === 0 ? 'border-0' : 'border-[3px]',
				containerClassName
			)}
			onClick={handleClick}
		>
			{participants.map((participant, index) => (
				<Avatar
					key={participant.id || index}
					userId={participant.id}
					firstName={participant.firstName}
					lastName={participant.lastName}
					profileById={true}
					className={clsx(
						'w-8 h-8',
						!onClick && '!cursor-default',
						index > 0 && '-ml-3'
					)}
				/>
			))}
			{remainingCounter > 0 && (
				<div
					className={clsx(
						'flex items-center justify-center w-8 h-8 rounded-full cursor-default',
						'-ml-3',
						'bg-gradient-to-b from-gradientDangerStart to-gradientDangerEnd text-danger'
					)}
				>
					<span>{remainingCounter}+</span>
				</div>
			)}
		</div>
	);
};
