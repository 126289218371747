import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import thunk from 'redux-thunk';

import configsReducer from './configs';
import userReducer from './user';
import settingsFormsReducer from './settingsForms';
import absencesReducer from './absences';
import meetingRequestsReducer from './meetingRequests';
import scheduledEventsReducer from './scheduledEvents';
import notificationsReducer from './notifications';

const rootReducer = combineReducers({
	configs: configsReducer,
	user: userReducer,
	settingsForms: settingsFormsReducer,
	absences: absencesReducer,
	meetingRequests: meetingRequestsReducer,
	scheduledEvents: scheduledEventsReducer,
	notifications: notificationsReducer,
});

export const store = configureStore({
	reducer: rootReducer,
	middleware: [thunk],
});
