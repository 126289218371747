import { useEffect } from 'react';
import { Provider as StoreProvider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import weekday from 'dayjs/plugin/weekday';
import timezone from 'dayjs/plugin/timezone';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import relativeTime from 'dayjs/plugin/relativeTime';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import 'dayjs/locale/en-gb';
import { AppRoutes } from './AppRoutes';
import { store } from './store';

import 'react-toastify/dist/ReactToastify.css';

dayjs.locale('en-gb');
dayjs.extend(utc);
dayjs.extend(weekday);
dayjs.extend(timezone);
dayjs.extend(isBetween);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(relativeTime);
dayjs.extend(customParseFormat);

const App = () => {
	useEffect(() => {
		document.addEventListener('contextmenu', (event) => {
			event.preventDefault();
		});
		document.addEventListener('selectstart', (event) => {
			event.preventDefault();
		});
	}, []);
	return (
		<div className='h-screen relative'>
			<StoreProvider store={store}>
				<AppRoutes />
				<ToastContainer />
			</StoreProvider>
		</div>
	);
};

export default App;
