import clsx from "clsx";
import { useNavigate } from "react-router-dom";

const PageTitle = ({ Icon, text, link }) => {
  const navigate = useNavigate();

  const navigateToLink = (link) => {
    navigate(`/${link}`);
  };

  return (
    <div className="flex items-center justify-between gap-4 w-full max-w-4xl px-4 mx-auto">
      <h1 className="text-2xl font-bold mt-2">{text}</h1>
      <button
        className="hover:bg-gray-300 rounded-full p-1 ml-auto"
        onClick={() => navigateToLink(link)}
      >
        <Icon className="text-primary w-8 h-8" fill="transparent" />
      </button>
    </div>
  );
};

export default PageTitle;
