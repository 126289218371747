import { Tooltip } from "../../../theme";

import { ReactComponent as WrenchIcon } from "../../../assets/icons/Wrench.svg";
import { ReactComponent as InfoIcon } from "../../../assets/icons/info.svg";
import { ReactComponent as CrossCircledIcon } from "../../../assets/icons/Circled_cross.svg";
import { ReactComponent as AgendaIcon } from "../../../assets/icons/Agenda.svg";
import { ReactComponent as CalendarIcon } from "../../../assets/icons/Calendar.svg";

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectLoggedInUser } from "../../../store/user";
dayjs.extend(relativeTime);

export const MeetingRow = ({
  eventItem,
  onViewDetail,
  onDecline,
  onEdit,
  onDelete,
}) => {
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);

  const currentUser = useSelector(selectLoggedInUser);

  const calculateEndTime = (startTimes, duration) => {
    const [startHour, startMinute] =
      startTimes && startTimes.split(":").map(Number);
    const [durationHour, durationMinute] =
      duration && duration.split(":").map(Number);

    let endHour = startHour + durationHour;
    let endMinute = startMinute + durationMinute;

    if (endMinute >= 60) {
      endHour += Math.floor(endMinute / 60);
      endMinute %= 60;
    }

    return `${endHour.toString().padStart(2, "0")}:${endMinute
      .toString()
      .padStart(2, "0")}`;
  };

  useEffect(() => {
    const startTimes = dayjs(eventItem.lastScheduleTime).format("HH:mm");
    if (startTimes && eventItem?.minLength) {
      const endTimes = calculateEndTime(startTimes, eventItem.minLength);
      setStartTime(startTimes);
      setEndTime(endTimes);
    }
  }, [eventItem]);

  // const parsedeventTime = (eventtime) => {
  //   let parsedTime = Number(eventtime) * 60;
  //   parsedTime = Math.ceil(parsedTime);
  //   if (parsedTime >= 60) {
  //     let hours = parsedTime / 60;
  //     let rhours = Math.floor(hours);
  //     let hoursTitle = rhours > 1 ? "hours" : "hour";
  //     var minutes = (hours - rhours) * 60;
  //     var rminutes = Math.round(minutes);
  //     let minuteTitle = rminutes > 1 ? "minutes" : "minute";
  //     return rhours + " " + hoursTitle + " " + rminutes + " " + minuteTitle;
  //   }
  //   return `${Math.ceil(parsedTime)} minutes`;
  // };

  return (
    <div
      className="mt-2 p-2 flex flex-col rounded"
      style={{ background: "#EAF9F8" }}
    >
      {/* First row: Meeting topic and info icon */}
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <AgendaIcon fill="transparent" className="mr-2" />
          <p className="text-base text-left font-normal">{eventItem.topic}</p>
        </div>
        <Tooltip content="View meeting">
          <button onClick={onViewDetail}>
            <InfoIcon fill="transparent" />
          </button>
        </Tooltip>
      </div>

      {/* Second row: Wrench icon */}
      {eventItem.isHost && (
        <div className="flex items-center justify-end">
          {!eventItem?.mepaId && !eventItem.nonAdjustable && (
            <Tooltip content="Edit meeting">
              <button onClick={onEdit}>
                <WrenchIcon fill="transparent" />
              </button>
            </Tooltip>
          )}
        </div>
      )}

      {/* Third row: Date and cross icon */}
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <CalendarIcon fill="transparent" className="mr-2" />
          <p className="text-base font-normal">
            {dayjs(eventItem.dateTimesToBeHeldStart).format("MMM D")} -{" "}
            {dayjs(eventItem.dateTimesToBeHeldEnd).format("MMM D, YYYY")}
          </p>
        </div>
        {!eventItem.isHost && eventItem.isProposal && (
          <Tooltip content="Decline meeting">
            <button onClick={onDecline}>
              <CrossCircledIcon fill="transparent" />
            </button>
          </Tooltip>
        )}
        {eventItem.isHost && (
          <Tooltip content="Delete meeting">
            <button onClick={onDelete}>
              <CrossCircledIcon fill="transparent" />
            </button>
          </Tooltip>
        )}
      </div>
    </div>
  );
};
