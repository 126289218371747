export const DEFAULT_SERVER_URL = "https://main.zelvor.com";
export const APP_DATE_FORMAT = 'YYYY-MM-DD';

export const SCREEN_MESSAGES = {
	'/admin/dashboard': "Let's check what awaits you today!",
	'/admin/manage-meetings': "Let's manage your meetings",
	'/admin/meeting-requests': "Let's manage your meeting requests",
	'/admin/calendar': "Let's check your calendar!",
	'/admin/settings': "Let's update your settings",
	'/admin/support-center': 'Let us know if you need help',
	'/admin/manage-absences': "Let's keep track of your days off",
};

export const DAYS_OF_WEEK = [
	{ value: '1', label: 'Monday' },
	{ value: '2', label: 'Tuesday' },
	{ value: '3', label: 'Wednesday' },
	{ value: '4', label: 'Thursday' },
	{ value: '5', label: 'Friday' },
	{ value: '6', label: 'Saturday' },
	{ value: '0', label: 'Sunday' },
];

export const WEEKDAYS_SHORT = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export const FLEXIBILITY_OPTIONS = [
	{ value: '0', label: '0 hours' },
	{ value: '30', label: '0.5 hours' },
	{ value: '60', label: '1 hour' },
	{ value: '90', label: '1.5 hour' },
	{ value: '120', label: '2 hours' },
	{ value: '150', label: '2.5 hours' },
	{ value: '180', label: '3 hours' },
	{ value: '210', label: '3.5 hours' },
	{ value: '240', label: '4 hours' },
];

export const MEETING_BLOCKS = [
	{ value: '01:00:00', label: '1 hour' },
	{ value: '01:30:00', label: '1.5 hours' },
	{ value: '02:00:00', label: '2 hours' },
	{ value: '02:30:00', label: '2.5 hours' },
	{ value: '03:00:00', label: '3 hours' },
	{ value: '03:30:00', label: '3.5 hours' },
	{ value: '04:00:00', label: '4 hours' },
];

export const MEETING_BREAKS_BLOCKS = [
	{ value: '00:00:00', label: '0 minutes' },
	{ value: '00:05:00', label: '5 minutes' },
	{ value: '00:10:00', label: '10 minutes' },
	{ value: '00:15:00', label: '15 minutes' },
	{ value: '00:20:00', label: '20 minutes' },
	{ value: '00:25:00', label: '25 minutes' },
	{ value: '00:30:00', label: '30 minutes' },
];

export const WORKING_TIME_BLOCKS = [
	{ value: '00:00', label: '0:00' },
	{ value: '00:15', label: '0:15' },
	{ value: '00:30', label: '0:30' },
	{ value: '00:45', label: '0:45' },
	{ value: '01:00', label: '1:00' },
	{ value: '01:15', label: '1:15' },
	{ value: '01:30', label: '1:30' },
	{ value: '01:45', label: '1:45' },
	{ value: '02:00', label: '2:00' },
	{ value: '02:15', label: '2:15' },
	{ value: '02:30', label: '2:30' },
	{ value: '02:45', label: '2:45' },
	{ value: '03:00', label: '3:00' },
	{ value: '03:15', label: '3:15' },
	{ value: '03:30', label: '3:30' },
	{ value: '03:45', label: '3:45' },
	{ value: '04:00', label: '4:00' },
	{ value: '04:15', label: '4:15' },
	{ value: '04:30', label: '4:30' },
	{ value: '04:45', label: '4:45' },
	{ value: '05:00', label: '5:00' },
	{ value: '05:15', label: '5:15' },
	{ value: '05:30', label: '5:30' },
	{ value: '05:45', label: '5:45' },
	{ value: '06:00', label: '6:00' },
	{ value: '06:15', label: '6:15' },
	{ value: '06:30', label: '6:30' },
	{ value: '06:45', label: '6:45' },
	{ value: '07:00', label: '7:00' },
	{ value: '07:15', label: '7:15' },
	{ value: '07:30', label: '7:30' },
	{ value: '07:45', label: '7:45' },
	{ value: '08:00', label: '8:00' },
	{ value: '08:15', label: '8:15' },
	{ value: '08:30', label: '8:30' },
	{ value: '08:45', label: '8:45' },
	{ value: '09:00', label: '9:00' },
	{ value: '09:15', label: '9:15' },
	{ value: '09:30', label: '9:30' },
	{ value: '09:45', label: '9:45' },
	{ value: '10:00', label: '10:00' },
	{ value: '10:15', label: '10:15' },
	{ value: '10:30', label: '10:30' },
	{ value: '10:45', label: '10:45' },
	{ value: '11:00', label: '11:00' },
	{ value: '11:15', label: '11:15' },
	{ value: '11:30', label: '11:30' },
	{ value: '11:45', label: '11:45' },
	{ value: '12:00', label: '12:00' },
	{ value: '12:15', label: '12:15' },
	{ value: '12:30', label: '12:30' },
	{ value: '12:45', label: '12:45' },
	{ value: '13:00', label: '13:00' },
	{ value: '13:15', label: '13:15' },
	{ value: '13:30', label: '13:30' },
	{ value: '13:45', label: '13:45' },
	{ value: '14:00', label: '14:00' },
	{ value: '14:15', label: '14:15' },
	{ value: '14:30', label: '14:30' },
	{ value: '14:45', label: '14:45' },
	{ value: '15:00', label: '15:00' },
	{ value: '15:15', label: '15:15' },
	{ value: '15:30', label: '15:30' },
	{ value: '15:45', label: '15:45' },
	{ value: '16:00', label: '16:00' },
	{ value: '16:15', label: '16:15' },
	{ value: '16:30', label: '16:30' },
	{ value: '16:45', label: '16:45' },
	{ value: '17:00', label: '17:00' },
	{ value: '17:15', label: '17:15' },
	{ value: '17:30', label: '17:30' },
	{ value: '17:45', label: '17:45' },
	{ value: '18:00', label: '18:00' },
	{ value: '18:15', label: '18:15' },
	{ value: '18:30', label: '18:30' },
	{ value: '18:45', label: '18:45' },
	{ value: '19:00', label: '19:00' },
	{ value: '19:15', label: '19:15' },
	{ value: '19:30', label: '19:30' },
	{ value: '19:45', label: '19:45' },
	{ value: '20:00', label: '20:00' },
	{ value: '20:15', label: '20:15' },
	{ value: '20:30', label: '20:30' },
	{ value: '20:45', label: '20:45' },
	{ value: '21:00', label: '21:00' },
	{ value: '21:15', label: '21:15' },
	{ value: '21:30', label: '21:30' },
	{ value: '21:45', label: '21:45' },
	{ value: '22:00', label: '22:00' },
	{ value: '22:15', label: '22:15' },
	{ value: '22:30', label: '22:30' },
	{ value: '22:45', label: '22:45' },
	{ value: '23:00', label: '23:00' },
	{ value: '23:15', label: '23:15' },
	{ value: '23:30', label: '23:30' },
	{ value: '23:45', label: '23:45' },
];

export const HOURSE_IN_DAY = [
	{ value: '00:00', label: '0:00' },
	{ value: '01:00', label: '1:00' },
	{ value: '02:00', label: '2:00' },
	{ value: '03:00', label: '3:00' },
	{ value: '04:00', label: '4:00' },
	{ value: '05:00', label: '5:00' },
	{ value: '06:00', label: '6:00' },
	{ value: '07:00', label: '7:00' },
	{ value: '08:00', label: '8:00' },
	{ value: '09:00', label: '9:00' },
	{ value: '10:00', label: '10:00' },
	{ value: '11:00', label: '11:00' },
	{ value: '12:00', label: '12:00' },
	{ value: '13:00', label: '13:00' },
	{ value: '14:00', label: '14:00' },
	{ value: '15:00', label: '15:00' },
	{ value: '16:00', label: '16:00' },
	{ value: '17:00', label: '17:00' },
	{ value: '18:00', label: '18:00' },
	{ value: '19:00', label: '19:00' },
	{ value: '20:00', label: '20:00' },
	{ value: '21:00', label: '21:00' },
	{ value: '22:00', label: '22:00' },
	{ value: '23:00', label: '23:00' },
];

export const MEETING_REQUESTS_FILTER_OPTIONS = [
	{ value: 'all', label: 'All' },
	{ value: 'accepted', label: 'Accepted' },
	{ value: 'declined', label: 'Declined' },
	{ value: 'pending', label: 'Pending' },
];
export const MANAGE_MEETING_FILTER_OPTIONS = [
	{ value: 'all', label: 'All' },
	{ value: 'hosted', label: 'Hosted' },
	{ value: 'invited', label: 'Invited' },
];

export const MEETING_INVITATION_RESPONSE = {
	ACCEPTED: 1,
	REJECTED: 2,
};
