import React from 'react';
import { ReactComponent as CrossIcon } from '../../assets/icons/Cross.svg';

const SuccessfullModal = ({ onClose, title, description }) => {

    return (
        <>

            <div className="bg-white rounded-lg p-8">
                <div className='flex justify-end'>
                    <button onClick={onClose} className="focus:outline-none">
                        <CrossIcon fill='transparent' />
                    </button>
                </div>

                <div className="">
                    <h2 className="text-2xl text-center font-semibold text-gray-800">{title}</h2>

                </div>
                <p className="text-lg text-gray-700 text-center mt-7" >{description}</p>
            </div>

        </>
    )
}

export default SuccessfullModal
