import { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import dayjs from 'dayjs';

let timoutRef;

export const CurrentTimePointer = ({ activeDateTime, dayStartTime }) => {
	const [currentDateTime, setCurrentDateTime] = useState(dayjs());

	const { isToday, currentTimeDisplay } = useMemo(() => {
		const data = {
			isToday: true,
			currentTimeDisplay: '',
		};
		if (!currentDateTime.isSame(activeDateTime, 'day')) {
			data.isToday = false;
			return data;
		}
		data.currentTimeDisplay = currentDateTime.format('HH:mm');

		return data;
	}, [currentDateTime, activeDateTime, dayStartTime]);

	const updateCurrentTime = () => {
		const dateTime = dayjs();
		setCurrentDateTime(dateTime);
	};

	useEffect(() => {
		updateCurrentTime();

		// Update the time every 10 seconds
		timoutRef = setInterval(() => {
			updateCurrentTime();
		}, 1000 * 10);

		return () => {
			clearInterval(timoutRef);
		};
	}, []);

	return (
		isToday && (
			<div
				className={clsx(
					'absolute left-0 w-full border-b border-info400',
					'pl-[60px] top-[150px]'
				)}
			>
				<p className='text-2xl text-center w-[120px]'>{currentTimeDisplay}</p>
			</div>
		)
	);
};
