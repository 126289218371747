import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { selectMyMeetingPreferences } from '../store/user';
import { WORKING_TIME_BLOCKS } from '../utils/constants';

export const useWorkingSchedule = (
	rangeStart,
	rangeEnd,
	selectedStartTime,
	selectedEndTime
) => {
	const myMeetingPreferences = useSelector(selectMyMeetingPreferences);
	const calendarDays =
		myMeetingPreferences?.workingCalendar?.calendarWeekDays || [];

	const [workingTimes, setWorkingTimes] = useState([]);

	useEffect(() => {
		const startDay = dayjs(rangeStart).get('day');
		const endDay = dayjs(rangeEnd).get('day');

		if (calendarDays.length === 0) {
			setWorkingTimes(WORKING_TIME_BLOCKS);
		} else {
			const filteredCalendarDays = calendarDays.filter(
				(calendarDay) =>
					calendarDay.dayOfWeek >= startDay && calendarDay.dayOfWeek <= endDay
			);

			// Set an initial value to find the earliest start time
			let earliestStartTime = dayjs().hour(23).minute(59);
			// Set an initial value to find the latest end time
			let latestEndTime = dayjs().hour(0).minute(0);

			for (const workingDayItem of filteredCalendarDays) {
				const [startTime, endTime] = workingDayItem.workingTime.split('T');
				const [startHour, startMinute] = startTime.split(':');
				const [endHour, endMinute] = endTime.split(':');

				const startDateTime = dayjs()
					.hour(Number(startHour))
					.minute(Number(startMinute))
					.second(0)
					.millisecond(0);

				const endDateTime = dayjs()
					.hour(Number(endHour))
					.minute(Number(endMinute))
					.second(0)
					.millisecond(0);

				if (startDateTime.isBefore(earliestStartTime)) {
					earliestStartTime = startDateTime;
				}

				if (endDateTime.isAfter(latestEndTime)) {
					latestEndTime = endDateTime;
				}
			}

			const workingTimesWithGap = [];
			let currentTime = earliestStartTime;

			while (currentTime.isBefore(latestEndTime)) {
				const formattedTime = currentTime.format('HH:mm');
				workingTimesWithGap.push({
					value: formattedTime,
					label: formattedTime,
				});
				currentTime = currentTime.add(15, 'minutes');
			}

			// If workingTime is Empty:
			// If no selected fromTime or toTime
			if (
				// workingTimesWithGap.length > 0 &&
				selectedStartTime &&
				selectedEndTime
			) {
				const firstElementTime = dayjs(workingTimesWithGap[0]?.value, 'HH:mm');
				const lastElementTime = dayjs(
					workingTimesWithGap[workingTimesWithGap.length - 1]?.value,
					'HH:mm'
				);

				const isFirstElementTimeValid = firstElementTime.isValid();
				const fromTimeObj = dayjs(selectedStartTime, 'HH:mm');
				const toTimeObj = dayjs(selectedEndTime, 'HH:mm');

				if (toTimeObj.isBefore(firstElementTime) || !isFirstElementTimeValid) {
					workingTimesWithGap.unshift({
						value: toTimeObj.format('HH:mm'),
						label: toTimeObj.format('HH:mm'),
						isDisabled: true,
					});
				}
				if (
					fromTimeObj.isBefore(firstElementTime) ||
					!isFirstElementTimeValid
				) {
					workingTimesWithGap.unshift({
						value: fromTimeObj.format('HH:mm'),
						label: fromTimeObj.format('HH:mm'),
						isDisabled: true,
					});
				}

				if (fromTimeObj.isAfter(lastElementTime)) {
					workingTimesWithGap.push({
						value: fromTimeObj.format('HH:mm'),
						label: fromTimeObj.format('HH:mm'),
						isDisabled: true,
					});
				}
				if (toTimeObj.isAfter(lastElementTime)) {
					workingTimesWithGap.push({
						value: toTimeObj.format('HH:mm'),
						label: toTimeObj.format('HH:mm'),
						isDisabled: true,
					});
				}
			}

			setWorkingTimes(workingTimesWithGap);
		}
	}, [rangeStart, rangeEnd, calendarDays, selectedStartTime, selectedEndTime]);

	return { workingTimes };
};

export const useCalendarWorkingHours = (
	activeDate,
	activeView,
	scheduledEvents
) => {
	const myMeetingPreferences = useSelector(selectMyMeetingPreferences);
	const calendarDays =
		myMeetingPreferences?.workingCalendar?.calendarWeekDays || [];

	const [calendarWorkingHours, setCalendarWorkingHours] = useState([]);

	useEffect(() => {
		const startDay = dayjs(activeDate).startOf(activeView).get('day');
		const endDay = dayjs(activeDate).endOf(activeView).get('day');

		const allEvents = Object.values(scheduledEvents).flat();

		const filteredCalendarDays = calendarDays.filter(
			(calendarDay) =>
				calendarDay.dayOfWeek >= startDay && calendarDay.dayOfWeek <= endDay
		);

		let earliestStartTime = dayjs().hour(23).minute(59);
		let latestEndTime = dayjs().hour(0).minute(0);

		for (const workingDayItem of filteredCalendarDays) {
			const [startTime, endTime] = workingDayItem.workingTime.split('T');
			const [startHour, startMinute] = startTime.split(':');
			const [endHour, endMinute] = endTime.split(':');

			const startDateTime = dayjs()
				.hour(Number(startHour))
				.minute(Number(startMinute))
				.second(0)
				.millisecond(0);

			const endDateTime = dayjs()
				.hour(Number(endHour))
				.minute(Number(endMinute))
				.second(0)
				.millisecond(0);

			if (startDateTime.isBefore(earliestStartTime)) {
				earliestStartTime = startDateTime;
			}

			if (endDateTime.isAfter(latestEndTime)) {
				latestEndTime = endDateTime;
			}
		}

		// There can be 3 possibilities
		// Events are in sync with the current working times - Do nothing
		// Events start before the working hours - Push all those hours at start
		// Event Ends after the working hours - Push all those hours at end

		for (let scheduledEvent of allEvents) {
			const [startHour, startMinute] = scheduledEvent.startTime.split(':');
			const [endHour, endMinute] = scheduledEvent.endTime.split(':');
			const startDateTime = dayjs()
				.hour(Number(startHour))
				.minute(Number(startMinute))
				.second(0)
				.millisecond(0);

			const endDateTime = dayjs()
				.hour(Number(endHour))
				.minute(Number(endMinute))
				.second(0)
				.millisecond(0);
			if (startDateTime.isBefore(earliestStartTime)) {
				earliestStartTime = startDateTime;
			}

			if (endDateTime.isAfter(latestEndTime)) {
				latestEndTime = endDateTime;
			}
		}

		const workingTimesWithGap = [];
		let currentTime = earliestStartTime;

		while (currentTime.isBefore(latestEndTime)) {
			const formattedTime = currentTime.format('HH:mm');
			workingTimesWithGap.push({
				value: formattedTime,
				label: formattedTime,
			});
			currentTime = currentTime.add(1, 'hours');
		}
		setCalendarWorkingHours(workingTimesWithGap);
	}, [activeDate, activeView, calendarDays, scheduledEvents]);

	return { calendarWorkingHours };
};

export const useWorkingDayStartTime = () => {
	const myMeetingPreferences = useSelector(selectMyMeetingPreferences);
	const calendarDays =
		myMeetingPreferences?.workingCalendar?.calendarWeekDays || [];

	const [workingDayStartTime, setWorkingStartTime] = useState('00:00');

	useEffect(() => {
		let earliestStartTime = dayjs().hour(23).minute(59);

		for (const workingDayItem of calendarDays) {
			const [startTime] = workingDayItem.workingTime.split('T');
			const [startHour, startMinute] = startTime.split(':');

			const startDateTime = dayjs()
				.hour(Number(startHour))
				.minute(Number(startMinute))
				.second(0)
				.millisecond(0);

			if (startDateTime.isBefore(earliestStartTime)) {
				earliestStartTime = startDateTime;
			}
		}
		setWorkingStartTime(earliestStartTime.format('HH:mm'));
	}, [calendarDays]);

	return workingDayStartTime;
};

export const useGetWorkingDays = () => {
	const myMeetingPreferences = useSelector(selectMyMeetingPreferences);
	const calendarDays =
		myMeetingPreferences?.workingCalendar?.calendarWeekDays || [];
	if (!calendarDays.length) {
		return [0, 1, 2, 3, 4, 5, 6, 7];
	}
	return calendarDays.map((workingDay) => workingDay.dayOfWeek);
};
