import React from "react";
import { Fragment, useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import PageTitle from "../../components/PageTitle";
import { MeetingDetailModal } from "../../components/modals/MeetingDetailModal";
import { Select } from "../../theme";
import { Modal } from "../../components/layout/Modal";
import {
  MeetingDayGroupRow,
  MeetingListingLoader,
  MeetingRow,
  NoMeetingMessage,
} from "../../components/meetings/listing";
import { ConfirmationModal } from "../../components/modals/ConfirmationModal";
import { CreateMeetingModal } from "../../components/modals/CreateMeetingModal";
import {
  deleteScheduledEvent,
  fetachGetInviteStatus,
  fetchMepaScheduleTimeSlots,
  fetchScheduledEventDetailById,
  fetchScheduledEvents,
  refreshScheduledEvents,
  rescheduleDeleteMeeting,
  rescheduleFinalizeSuccess,
  rescheduleFixSuccess,
  resetScheduledEventDeletionError,
  resetScheduledEventDeletionStatus,
  resetUpdateScheduledMeetingStatus,
  selectScheduledEvents,
} from "../../store/scheduledEvents";
import {
  useEventDeleteOrDeclineActionStatus,
  useEventUpdateActionStatus,
  useScheduledEventsNew,
  useUnscheduledEvents,
} from "../../hooks/scheduleEvents";
import {
  deleteMeetingRequest,
  fetchInvitableUsers,
  fetchMyAllUpcomingMeetings,
  resetAddMeetingStatus,
  resetMeetingRequestDeletionStatus,
  resetMeetingRequestsDeletionError,
  resetMeetingResponseError,
  resetMeetingResponseStatus,
  responseToMeetingInvitation,
  selectAddMeetingRequestStatus,
} from "../../store/meetingRequests";

import { MANAGE_MEETING_FILTER_OPTIONS } from "../../utils/constants";

import { ReactComponent as ArrowBack } from "../../assets/icons/IoIosArrowBack.svg";
import { ReactComponent as RefreshIcon } from "../../assets/icons/refresh.svg";
import { MeetingTimeSlotModalMepa } from "../../components/modals/MeetingTimeSlotModal";
import SuccessfullModal from "../../components/modals/SuccessfullModal";
import { MeetingDetailLoader } from "../../components/meetings/MeetingDetailLoader";
import { selectLoggedInUser } from "../../store/user";

export const Meetings = () => {
  const dispatch = useDispatch();
  const { scheduledEventsLoading, scheduledEventsRefreshing } = useSelector(
    selectScheduledEvents
  );

  const {
    checkMeetingValidity,
    checkMeetingValidityError,
    // selectAddMeetingRequestStatus,
  } = useSelector(selectAddMeetingRequestStatus);

  const currentUser = useSelector(selectLoggedInUser);

  const [today] = useState(dayjs());
  const [lastEventDate, setLastEventDate] = useState(dayjs());

  const [collapsedGroupKeys, setCollapsedGroupKey] = useState([]);
  const [activeFilter, setActiveFilter] = useState("all");
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [meetingAction, setMeetingAction] = useState({});
  const [meetingToUpdate, setMeetingToUpdate] = useState(null);
  const [showDiscardConfirmation, setShowDiscardConfirmation] = useState(false);
  const [formHaveUnsavedChanges, setFormHaveUnsavedChanges] = useState(false);
  const [meetingDetails, setMeetingDetails] = useState(null);
  const [isStatusModalShow, setIsStatusModalShow] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isMeetingDelete, setIsMeetingDelete] = useState(false);
  const [isFinalizeMepaSuccessModal, setFinalizeMepaSuccessModal] =
    useState(false);
  const [isFixMepaSuccessModal, setIsFixMepaSuccessModal] = useState(false);

  // const upcomingScheduledEvents = useUpcomingScheduleEvents(activeFilter);
  const unscheduledEvents = useUnscheduledEvents(activeFilter);
  const newUpcoming = useScheduledEventsNew(activeFilter);
  const isDeleteSuccess = useSelector(
    (state) => state?.scheduledEvents?.isDeleteSuccess
  );
  const isFinalizeMepaSuccess = useSelector(
    (state) => state?.scheduledEvents?.isFinalizeMepaSuccess
  );
  const isFixMepaSuccess = useSelector(
    (state) => state?.scheduledEvents?.isFixMepaSuccess
  );
  const isSuccessLoader = useSelector(
    (state) => state?.scheduledEvents?.isSuccessLoader
  );

  const {
    deleteOrDeclineActionLoading,
    deleteOrDeclineActionSuccess,
    deleteOrDeclineActionError,
  } = useEventDeleteOrDeclineActionStatus();
  const {
    evnetUpdateActionLoading,
    evnetUpdateActionSuccess,
    evnetUpdateActionError,
  } = useEventUpdateActionStatus();

  const onChangeActiveFilter = () => (value) => {
    setActiveFilter(value);
  };

  const lastEventDateFormatted = useMemo(() => {
    if (!newUpcoming?.length) {
      return lastEventDate.format("MMM DD, YYYY");
    }

    // Get the last event daysGroup and parse it
    const lastEventDateKey = newUpcoming[newUpcoming?.length - 1];
    const lastEventDateTime = dayjs(lastEventDateKey.dateKey);

    // If the last event days-group is after the local dateTime, then reset it
    if (lastEventDateTime.isAfter(lastEventDate)) {
      setLastEventDate(lastEventDateTime);
    }
    return lastEventDate.format("MMM DD, YYYY");
  }, [lastEventDate, newUpcoming]);

  const isUnScheduledSectionExapned = useMemo(() => {
    return !collapsedGroupKeys.includes("unscheduled");
  }, [collapsedGroupKeys]);

  const onToggleRowExpanding = (rowKey) => {
    const existingKeys = [...collapsedGroupKeys];
    const foundIndex = existingKeys.indexOf(rowKey);
    if (foundIndex === -1) {
      existingKeys.push(rowKey);
    } else {
      existingKeys.splice(foundIndex, 1);
    }
    setCollapsedGroupKey(existingKeys);
  };

  const onHideConfirmationModal = () => {
    setMeetingAction({});
    if (meetingAction.actionType === "decline") {
      dispatch(resetMeetingResponseStatus());
    } else {
      dispatch(resetMeetingRequestDeletionStatus());
      dispatch(resetScheduledEventDeletionStatus());
    }
  };

  const onDeclineAction = (event) => {
    setMeetingAction({
      actionType: "decline",
      eventId: event?.entityID,
      isProposal: true,
      mepaId: event?.mepaId,
    });
  };

  const onEditAction = (eventData) => {
    setMeetingToUpdate(eventData);
    setMeetingAction({
      actionType: "update",
      eventId: eventData.eventId,
      isProposal: eventData.isProposal,
    });
    dispatch(
      fetchScheduledEventDetailById({
        eventId: eventData?.entityID,
        isProposal: eventData?.isProposal,
      })
    );
  };

  const onCloseEditModal = () => {
    setMeetingToUpdate(null);
    setMeetingAction({});
    dispatch(resetUpdateScheduledMeetingStatus());
    dispatch(resetAddMeetingStatus());
  };

  const onFormStatusChange = (haveChanges) => {
    setFormHaveUnsavedChanges(haveChanges);
  };

  const onTryToCloseEditModal = () => {
    if (formHaveUnsavedChanges) {
      setShowDiscardConfirmation(true);
    } else {
      onCloseEditModal();
    }
  };

  const onDiscardAndCloseCreateMeetingModal = () => {
    setFormHaveUnsavedChanges(false);
    setShowDiscardConfirmation(false);
    onCloseEditModal();
  };

  const onDeleteAction = (event) => {
    setMeetingAction({
      actionType: "delete",
      meetingRequestId: event?.entityID,
      mepaId: event?.mepaId,
      isProposal: event?.isProposal,
      hostPersonID: event?.hostPersonID,
      userId: currentUser?.userId,
    });
  };

  const onViewEventDetail = (eventItem) => {
    setMeetingDetails(eventItem);
    if (eventItem.mepaId) {
      dispatch(
        fetchScheduledEventDetailById({
          mepaId: eventItem.mepaId,
          isProposal: eventItem?.isProposal,
        })
      );
    } else {
      dispatch(
        fetchScheduledEventDetailById({
          eventId: eventItem?.entityID,
          isProposal: eventItem?.isProposal,
        })
      );
    }

    setShowDetailModal(true);
  };

  const handleGetInviteStatus = (payload) => {
    if (meetingDetails?.mepaId) {
      if (payload?.fixing) {
        dispatch(fetachGetInviteStatus({ mepaId: meetingDetails?.mepaId }));
      } else {
        dispatch(
          fetchMepaScheduleTimeSlots({ mepaId: meetingDetails?.mepaId })
        );
      }
      setShowDetailModal(false);
      setIsStatusModalShow(true);
    }
  };

  const onHideEventDetailModal = () => {
    setShowDetailModal(false);
  };

  const handleDeleteOrDeclineConfirmation = () => {
    if (meetingAction.actionType === "decline") {
      if (meetingAction?.mepaId) {
        dispatch(
          responseToMeetingInvitation({
            response: 2,
            mepaId: meetingAction.mepaId,
          })
        );
      } else {
        dispatch(
          responseToMeetingInvitation({
            response: 2,
            proposalId: meetingAction.eventId,
          })
        );
      }
    } else {
      if (meetingAction.isProposal) {
        dispatch(deleteMeetingRequest(meetingAction));
      } else {
        dispatch(deleteScheduledEvent(meetingAction.eventId));
      }
    }

    setTimeout(() => {
      dispatch(fetchMyAllUpcomingMeetings());
    }, 2000);
  };

  // Reset the error, so user can retry without closing the modal
  const handleDeleteOrDeclineErrorReset = () => {
    if (meetingAction.actionType === "decline") {
      dispatch(resetMeetingResponseError());
    } else {
      dispatch(resetMeetingRequestsDeletionError());
      dispatch(resetScheduledEventDeletionError());
    }
  };

  const onRefresh = () => {
    const range = {
      start: today.format("YYYY-MM-DD"),
      end: lastEventDate.format("YYYY-MM-DD"),
    };
    dispatch(refreshScheduledEvents(range));
    dispatch(fetchMyAllUpcomingMeetings(true));
  };

  const loadMoreEvents = () => {
    // Get the last point until the data was fetched::
    const newStartDateTime = lastEventDate.add(1, "day");
    const newEndDateTime = newStartDateTime.endOf("month");

    // Add 1 day to the last fetch day and set to start
    // Add 1 month and get the lastof than month and set to end
    const range = {
      start: newStartDateTime.format("YYYY-MM-DD"),
      end: newEndDateTime.format("YYYY-MM-DD"),
    };
    // Trigger the fetch action
    dispatch(fetchScheduledEvents(range));

    // Save the endDateTime
    setLastEventDate(newEndDateTime);
  };

  useEffect(() => {
    setIsActive(false);
    const start = today.format("YYYY-MM-DD");
    const endDateTime = today.endOf("month");
    const end = endDateTime.format("YYYY-MM-DD");
    dispatch(fetchScheduledEvents({ start, end }));
    setLastEventDate(endDateTime);

    dispatch(fetchInvitableUsers());
    // dispatch(fetchMeetingInvitations());
    dispatch(fetchMyAllUpcomingMeetings());
  }, [isActive]);

  useEffect(() => {
    if (isDeleteSuccess) {
      dispatch(fetchMyAllUpcomingMeetings());
      setIsMeetingDelete(true);
    } else {
      setIsMeetingDelete(false);
      dispatch(rescheduleDeleteMeeting());
    }
  }, [isDeleteSuccess]);

  useEffect(() => {
    if (isFinalizeMepaSuccess) {
      dispatch(fetchMyAllUpcomingMeetings());
      setFinalizeMepaSuccessModal(true);
    } else {
      setFinalizeMepaSuccessModal(false);
      dispatch(rescheduleFinalizeSuccess());
    }
  }, [isFinalizeMepaSuccess]);

  useEffect(() => {
    if (isFixMepaSuccess) {
      dispatch(fetchMyAllUpcomingMeetings());
      setIsFixMepaSuccessModal(true);
    } else {
      setIsFixMepaSuccessModal(false);
      dispatch(rescheduleFixSuccess());
    }
  }, [isFixMepaSuccess]);

  function groupEventsByDate(events) {
    return events.reduce((acc, eventItem) => {
      const dateKey = dayjs(eventItem?.lastScheduleTime).format("DD MMM YYYY");
      if (!acc[dateKey]) {
        acc[dateKey] = [];
      }
      acc[dateKey].push(eventItem);
      return acc;
    }, {});
  }

  function renderEventsGroup(eventsGroup) {
    return Object.entries(eventsGroup).map(([date, events]) => (
      <React.Fragment key={date}>
        <tr className="h-[30px]">
          <td colSpan="7">
            <p
              className={clsx(
                "font-semibold text-base py-2",
                "cursor-pointer hover:bg-secondary select-none"
              )}
            >
              {date}
            </p>
          </td>
        </tr>
        {Array.isArray(events) &&
          events.map((eventItem, index) => (
            <MeetingRow
              key={index}
              eventItem={eventItem}
              onViewDetail={() => onViewEventDetail(eventItem)}
              onDecline={() => onDeclineAction(eventItem)}
              onEdit={() => onEditAction(eventItem)}
              onDelete={() => onDeleteAction(eventItem)}
            />
          ))}
      </React.Fragment>
    ));
  }

  return (
    <div className="font-semibold text-sm">
      <div className="flex items-center justify-between">
        <PageTitle Icon={ArrowBack} text="Manage meetings" link="dashboard" />
      </div>

      {/* 
      <div className="flex ml-[34px] mt-7 mb-4 gap-[10px]">
        <button
          className={clsx(
            "flex items-center",
            "border border-gray500",
            "w-[280px] px-[25px] py-3 bg-neutral rounded-full",
            "shadow-theme"
          )}
          disabled={scheduledEventsRefreshing}
          onClick={onRefresh}
        >
          <RefreshIcon
            className={clsx(scheduledEventsRefreshing && "animate-spin")}
            fill="transparent"
          />
          <span className="flex-grow">Refresh</span>
        </button>
        <Select
          options={MANAGE_MEETING_FILTER_OPTIONS}
          value={activeFilter}
          onChange={onChangeActiveFilter}
          containerClassName="mr-[56px] min-w-[120px]"
          controlClassName=""
        />
      </div> */}

      <div className="flex items-center justify-between py-4 px-3.5">
        {/* Left side: Refresh button */}
        <div className="flex items-center">
          <button
            className="flex items-center rounded-2xl border border-gray-400 py-1 px-2 cursor-pointer scheduleButton"
            disabled={scheduledEventsRefreshing}
            onClick={onRefresh}
          >
            <RefreshIcon
              className={clsx(scheduledEventsRefreshing && "animate-spin")}
              fill="transparent"
            />
            <span className="flex-grow mx-2">Refresh</span>
          </button>
        </div>

        {/* Right side: Select menu */}
        <div className="ml-auto">
          <Select
            options={MANAGE_MEETING_FILTER_OPTIONS}
            value={activeFilter}
            onChange={onChangeActiveFilter}
            containerClassName=" min-w-[120px]"
            controlClassName=""
          />
        </div>
      </div>

      <div
        className={clsx(
          "border border-gray500 rounded-[10px] bg-neutral shadow-theme",
          "py-3 px-4 mx-3.5",
          "overflow-scroll"
        )}
      >
        {activeFilter !== "host" && (
          <Fragment>
            <MeetingDayGroupRow
              dateDisplay={"Unscheduled Meetings"}
              onClick={() => onToggleRowExpanding("unscheduled")}
            />
            {isUnScheduledSectionExapned &&
              unscheduledEvents?.map((eventItem, index) => (
                <>
                  <MeetingRow
                    key={index}
                    eventItem={eventItem}
                    onViewDetail={() => onViewEventDetail(eventItem)}
                    onDecline={() => onDeclineAction(eventItem)}
                    onEdit={() => onEditAction(eventItem)}
                    onDelete={() => onDeleteAction(eventItem)}
                  />
                </>
              ))}

            {isUnScheduledSectionExapned && unscheduledEvents?.length === 0 && (
              <NoMeetingMessage />
            )}
          </Fragment>
        )}

        <Fragment>
          <MeetingDayGroupRow
            dateDisplay={"Scheduled Meetings"}
            onClick={() => onToggleRowExpanding("Scheduled")}
          />
          {Array.isArray(newUpcoming) &&
            renderEventsGroup(groupEventsByDate(newUpcoming))}
          {newUpcoming?.length === 0 && <NoMeetingMessage />}
        </Fragment>

        {scheduledEventsLoading && <MeetingListingLoader />}
       
       
        {!scheduledEventsLoading && (
          <div className="mt-3 text-base">
            {/* First line: Loaded events until */}
            <div className="flex">
              <p className="text-gray500">
                Events until: {lastEventDateFormatted}
              </p>
            </div>
            {/* Second line: Load More button */}
            <div className="flex justify-start">
              <button className="border-b" onClick={loadMoreEvents}>
                Load More
              </button>
            </div>
          </div>
        )}
      </div>
      {isSuccessLoader && (
        <Modal
          isOpen={isSuccessLoader}
          onClose={onHideEventDetailModal}
          preventAnimate={true}
          hideHeader={true}
          modalClassName="border border-gray500 shadow-theme px-8"
        >
          <MeetingDetailLoader />
        </Modal>
      )}
      {showDetailModal && (
        <Modal
          isOpen={showDetailModal}
          onClose={onHideEventDetailModal}
          preventAnimate={true}
          hideHeader={true}
          modalClassName="border border-gray500 shadow-theme px-8"
        >
          <MeetingDetailModal
            onClose={onHideEventDetailModal}
            meetingDetails={meetingDetails}
            handleGetInviteStatus={handleGetInviteStatus}
          />
        </Modal>
      )}

      {isMeetingDelete && (
        <Modal
          isOpen={isMeetingDelete}
          onClose={() => {
            setIsMeetingDelete(false);
            dispatch(rescheduleDeleteMeeting());
          }}
          preventAnimate={true}
          hideHeader={true}
          modalClassName="border border-gray500 shadow-theme px-8"
        >
          <SuccessfullModal
            onClose={() => {
              setIsMeetingDelete(false);
              dispatch(rescheduleDeleteMeeting());
            }}
            title="Meeting Deleted"
            description="The meeting has been successfully deleted."
          />
        </Modal>
      )}

      {isFinalizeMepaSuccessModal && (
        <Modal
          isOpen={isFinalizeMepaSuccessModal}
          onClose={() => {
            setFinalizeMepaSuccessModal(false);
            dispatch(rescheduleFinalizeSuccess());
          }}
          preventAnimate={true}
          hideHeader={true}
          modalClassName="border border-gray500 shadow-theme px-8"
        >
          <SuccessfullModal
            onClose={() => {
              setFinalizeMepaSuccessModal(false);
              dispatch(rescheduleFinalizeSuccess());
            }}
            title="Finalized Meeting"
            description="You have successfully sent out invitations for your external meeting."
          />
        </Modal>
      )}

      {isFixMepaSuccessModal && (
        <Modal
          isOpen={isFixMepaSuccessModal}
          onClose={() => {
            setIsFixMepaSuccessModal(false);
            dispatch(rescheduleFixSuccess());
          }}
          preventAnimate={true}
          hideHeader={true}
          modalClassName="border border-gray500 shadow-theme px-8"
        >
          <SuccessfullModal
            onClose={() => {
              setIsFixMepaSuccessModal(false);
              dispatch(rescheduleFixSuccess());
            }}
            title="Confirmation"
            description="The External Meeting has been scheduled successfully."
          />
        </Modal>
      )}

      {(meetingAction.actionType === "decline" ||
        meetingAction.actionType === "delete") && (
        <Modal
          isOpen={
            meetingAction.actionType === "decline" ||
            meetingAction.actionType === "delete"
          }
          onClose={onHideConfirmationModal}
          preventAnimate={true}
          hideHeader={true}
          modalClassName="border border-gray500 shadow-theme"
          preventBackdropClose={deleteOrDeclineActionLoading}
        >
          <ConfirmationModal
            description={`Are you sure you want to ${meetingAction.actionType}?`}
            confirmButtonTitle="Confirm"
            errorModalTitle={`Failed to ${meetingAction.actionType} the meeting.`}
            actionLoading={deleteOrDeclineActionLoading}
            actionSuccess={deleteOrDeclineActionSuccess}
            actionError={deleteOrDeclineActionError}
            onConfirm={handleDeleteOrDeclineConfirmation}
            onCancel={onHideConfirmationModal}
            onClearError={handleDeleteOrDeclineErrorReset}
          />
        </Modal>
      )}
      {meetingAction.actionType === "update" && (
        <Modal
          isOpen={meetingAction.actionType === "update"}
          onClose={onTryToCloseEditModal}
          preventAnimate={true}
          Header={() => (
            <p className="text-2xl font-semibold">Scheduling Assistant</p>
          )}
          modalClassName="border border-gray500 shadow-theme p-8 pb-0"
          modalContentClassName="-mx-8"
          preventBackdropClose={evnetUpdateActionLoading}
          hideHeader={!!(evnetUpdateActionSuccess || evnetUpdateActionError)}
        >
          <CreateMeetingModal
            existingMeetingData={meetingToUpdate}
            actionLoading={evnetUpdateActionLoading}
            actionSuccess={evnetUpdateActionSuccess}
            checkMeetingValidity={checkMeetingValidity}
            checkMeetingValidityError={checkMeetingValidityError}
            actionError={evnetUpdateActionError}
            onFormStatusChange={onFormStatusChange}
            onClose={onTryToCloseEditModal}
            isUpdate={true}
            setIsActive={setIsActive}
          />
        </Modal>
      )}
      {isStatusModalShow && (
        <Modal
          isOpen={isStatusModalShow}
          onClose={() => setIsStatusModalShow(false)}
          preventAnimate={true}
          hideHeader={true}
          modalClassName="border border-gray500 shadow-theme p-0"
        >
          <MeetingTimeSlotModalMepa
            onClose={() => setIsStatusModalShow(false)}
            mepaId={meetingDetails?.mepaId}
            setIsActive={setIsActive}
          />
        </Modal>
      )}
      {showDiscardConfirmation && (
        <Modal
          isOpen={showDiscardConfirmation}
          onClose={() => {}}
          preventAnimate={true}
          preventBackdropClose={true}
          hideHeader={true}
        >
          <ConfirmationModal
            description="Your unsaved changes will be discarded, do you want to quit?"
            confirmButtonTitle="Confirm"
            onConfirm={onDiscardAndCloseCreateMeetingModal}
            onCancel={() => setShowDiscardConfirmation(false)}
          />
        </Modal>
      )}
    </div>
  );
};
