import axios from 'axios';
import { getStoredAuthToken } from './localStorage';

const axiosApiInstance = axios.create();

axiosApiInstance.interceptors.request.use(
	async (config) => {
		const token = getStoredAuthToken();
		if (token) {
			config.headers = {
				Authorization: `Bearer ${token}`,
			};
		}
		return config;
	},
	(error) => {
		Promise.reject(error);
	}
);

export default axiosApiInstance;
