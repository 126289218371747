import { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { generateAvatarColor } from '../utils/general.utils';
import { useSelector } from 'react-redux';
import { selectServerUrl } from '../store/configs';
import { GET_USER_PROFILE_IMAGE_API } from '../utils/apiRoutes';
import axiosApiInstance from '../utils/axiosClient';

const Avatar = ({
	url,
	userId,
	firstName,
	lastName = '',
	profileById = false,
	className,
	onClick,
}) => {
	const serverUlr = useSelector(selectServerUrl);
	const [imageSrc, setImageSrc] = useState('');

	const bgColor = useMemo(() => {
		let fullName = firstName.concat(lastName);
		return generateAvatarColor(fullName || 'User');
	}, [firstName, lastName]);

	useEffect(() => {
		if (profileById && userId) {
			axiosApiInstance
				.get(`${serverUlr}/${GET_USER_PROFILE_IMAGE_API}?userID=${userId}`, {
					responseType: 'blob',
				})
				.then(({ data }) => {
					if (data.size > 0) {
						const imageUrl = URL.createObjectURL(data);
						setImageSrc(imageUrl);
					}
				})
				.catch((err) => {
					console.log('FAILED TO FETCH PROFILE BY ID:::', err);
				});
		} else {
			setImageSrc(url);
		}
	}, [url]);

	return imageSrc ? (
		<img
			src={imageSrc}
			className={clsx('w-12 h-12 rounded-full cursor-pointer', className)}
			alt={firstName}
			onClick={onClick}
		/>
	) : (
		<span
			className={clsx(
				'flex items-center justify-center cursor-pointer',
				'w-12 h-12 rounded-full',
				'text-neutral font-semibold',
				className
			)}
			style={{ backgroundColor: bgColor }}
			onClick={onClick}
		>
			{firstName[0]}
		</span>
	);
};

export default Avatar;
