import { useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Input, Select } from '../../theme';
import { SettingsCard } from './SettingsCard';
import { ReactComponent as ProfileIcon } from '../../assets/icons/Profile.svg';
import { ReactComponent as CrossIcon } from '../../assets/icons/Cross.svg';
import { selectAvailableTimezones } from '../../store/configs';

export const SettingsMyProfileCard = ({
	listRef,
	currentUser,
	formInstance,
	sectionHaveChanges,
	profilePreview,
	onRemoveCurrentProfilePic,
	handleFileChange,
}) => {
	const fileInputRef = useRef();
	const avaialbleTimezones = useSelector(selectAvailableTimezones);
	const {
		values,
		errors,
		touched,
		resetForm,
		handleChange,
		handleBlur,
		handleSubmit,
	} = formInstance;

	const parsedTimezones = useMemo(() => {
		return avaialbleTimezones.map((timezone) => ({
			value: timezone,
			label: timezone,
		}));
	}, [avaialbleTimezones]);

	const fileSelectHandler = () => {
		fileInputRef.current?.click();
	};

	useEffect(() => {
		if (currentUser) {
			resetForm({
				values: {
					userFirstName: currentUser.userFirstName,
					userLastName: currentUser.userLastName,
					userName: currentUser.userName,
					timeZone: currentUser.timeZone,
					phoneNumber: currentUser.phoneNumber || '',
				},
			});
		}
	}, [currentUser]);

	return (
		<SettingsCard
			title='My Details'
			description='Here you can set up your general details'
			Icon={ProfileIcon}
			sectionKey='profile'
			listRef={listRef}
			haveChanges={sectionHaveChanges}
		>
			<div className='flex items-center'>
				<div className='flex items-start gap-5 mr-20 shrink-0'>
					<div className='w-[123px] h-[123px] relative'>
						{profilePreview && [
							<img
								className='w-full h-full rounded-[50px] border border-gray500'
								src={profilePreview}
								alt='Profile'
								key='profileImage'
							/>,
							<button
								className='absolute w-6 h-6 rounded-full bg-[red] top-0 right-0 text-[white]'
								onClick={onRemoveCurrentProfilePic}
								key='ProfileRemoveBtn'
							>
								<CrossIcon fill='transparent' />
							</button>,
						]}
					</div>
					<input
						ref={fileInputRef}
						className='hidden w-0 h-0'
						type='file'
						accept='image/*'
						onChange={handleFileChange}
					/>
					<button
						className='text-sm font-semibold text-left underline text-primary w-[102px]'
						onClick={fileSelectHandler}
					>
						Update profile picture
					</button>
				</div>
				<form onSubmit={handleSubmit}>
					<div className='flex flex-wrap gap-4'>
						<Input
							name='userFirstName'
							label='First Name'
							placeholder='First Name'
							type='text'
							value={values.userFirstName}
							className='mt-2 text-primary'
							containerClassName='!mt-0 w-[calc(50%-8px)]'
							onChange={handleChange}
							onBlur={handleBlur}
							hasError={!!(errors.userFirstName && touched.userFirstName)}
							error={errors.userFirstName}
						/>
						<Input
							name='userLastName'
							label='Last Name'
							placeholder='Last Name'
							type='text'
							value={values.userLastName}
							className='mt-2'
							containerClassName='!mt-0 w-[calc(50%-8px)]'
							onChange={handleChange}
							onBlur={handleBlur}
							hasError={!!(errors.userLastName && touched.userLastName)}
							error={errors.userLastName}
						/>
						<Input
							name='userName'
							label='E-mail'
							placeholder='Email'
							type='email'
							value={values.userName}
							className='mt-2'
							containerClassName='!mt-0 w-[calc(50%-8px)]'
							onChange={handleChange}
							onBlur={handleBlur}
							hasError={!!(errors.userName && touched.userName)}
							error={errors.userName}
						/>
						<Input
							name='phoneNumber'
							label='Phone'
							placeholder='Phone'
							type='text'
							value={values.phoneNumber}
							className='mt-2'
							containerClassName='!mt-0 w-[calc(50%-8px)]'
							onChange={handleChange}
							onBlur={handleBlur}
							hasError={!!(errors.phoneNumber && touched.phoneNumber)}
							error={errors.phoneNumber}
						/>
						<div className='!mt-0 w-[calc(50%-8px)]'>
							<label className='text-sm font-semibold'>Time zone</label>
							<Select
								name='timeZone'
								placeholder='Timezone'
								value={values.timeZone}
								options={parsedTimezones}
								onChange={handleChange}
								onBlur={handleBlur}
								containerClassName='!mt-2'
								controlClassName='!border-primary h-[42px] text-xs !font-normal'
								hasError={!!(errors.timeZone && touched.timeZone)}
								error={errors.timeZone}
								isSearchable={true}
								scrollId='scroll'
							/>

						</div>
					</div>
				</form>
			</div>
		</SettingsCard>
	);
};
