import clsx from 'clsx';

export const SettingsSectionsMenu = ({
	secitonButtons,
	activeCardId,
	unsavedSections,
	handleItemClick,
}) => {
	return (
		<div className='flex flex-col gap-3 w-[220px]'>
			{secitonButtons.map((sectionButtonItem) => (
				<button
					key={sectionButtonItem.key}
					className={clsx(
						'flex items-center font-semibold pr-4 gap-2 h-10 justify-between',
						activeCardId === sectionButtonItem.key
							? 'text-primary'
							: 'text-gray500'
					)}
					onClick={() => handleItemClick(sectionButtonItem.key)}
				>
					<div className='flex gap-2'>
						{sectionButtonItem.Icon}
						<span className='whitespace-nowrap'>{sectionButtonItem.title}</span>
					</div>
					{unsavedSections[sectionButtonItem.key] &&
						(unsavedSections[sectionButtonItem.key] === 'valid' ? (
							<span className='w-2 h-2 rounded-full bg-success'></span>
						) : (
							<span className='w-2 h-2 rounded-full bg-warning'></span>
						))}
				</button>
			))}
		</div>
	);
};
