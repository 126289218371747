import clsx from 'clsx';
import {
	useGetWorkingDays,
	useWorkingDayStartTime,
} from '../../hooks/workingHours';
import { CalendarWeekGrid } from './CalendarWeekGrid';
import { useMemo } from 'react';
import { CurrentTimePointer } from './CurrentTimePointer';
import { HOURSE_IN_DAY } from '../../utils/constants';

export const WeekCalendar = ({
	activeDate,
	today,
	scheduledEventsList,
	showFullWeek,
	onViewEventDetail,
}) => {
	const workingDays = useGetWorkingDays();
	const workingDayStartTime = useWorkingDayStartTime();

	const activeDayFormatted = useMemo(() => {
		return activeDate.format('dddd');
	}, [activeDate]);
	return (
		<div className='relative h-full select-none'>
			{showFullWeek ? (
				<div
					className={clsx(
						'flex items-center columns-7',
						'text-sm text-center bg-secondary h-10 pl-[60px]',
						'border-b border-gray500'
					)}
				>
					{['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].map(
						(weekDay, index) => (
							<p key={index} className='w-full'>
								{weekDay}
							</p>
						)
					)}
				</div>
			) : (
				<div
					className={clsx(
						'flex items-center justify-start',
						'text-sm text-center bg-secondary h-10 pl-[60px]',
						'border-b border-gray500'
					)}
				>
					<p>{activeDayFormatted}</p>
				</div>
			)}
			<CalendarWeekGrid
				today={today}
				currentMonth={activeDate}
				workingDays={workingDays}
				workingDayStartTime={workingDayStartTime}
				showFullWeek={showFullWeek}
				scheduledEventsList={scheduledEventsList}
				onViewEventDetail={onViewEventDetail}
			/>
			{!showFullWeek && (
				<CurrentTimePointer
					activeDateTime={activeDate}
					dayStartTime={HOURSE_IN_DAY[0]?.value}
				/>
			)}
		</div>
	);
};
