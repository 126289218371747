import { useEffect, useState } from 'react';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { CalendarPageHeader } from '../../components/calendar-page';
import { MonthCalendar } from '../../components/calendar-page/MonthCalendar';
import { WeekCalendar } from '../../components/calendar-page/WeekCalendar';
import { CreateMeetingModal } from '../../components/modals/CreateMeetingModal';
import { Modal } from '../../components/layout/Modal';
import { ManageAbsenceModal } from '../../components/modals/ManageAbsenceModal';
import {
	addNewAbsence,
	resetAbsencesActionData,
	selectAbsenceActionData,
} from '../../store/absences';
import { MeetingDetailModal } from '../../components/modals/MeetingDetailModal';
import {
	fetchInvitableUsers,
	resetAddMeetingStatus,
	selectAddMeetingRequestStatus,
} from '../../store/meetingRequests';
import {
	fetchScheduledEventDetailById,
	fetchScheduledEvents,
	selectScheduledEvents,
} from '../../store/scheduledEvents';
import { useScheduleEventsByDuration } from '../../hooks/scheduleEvents';
import { ConfirmationModal } from '../../components/modals/ConfirmationModal';

export const Calendar = () => {
	const dispatch = useDispatch();
	const [today] = useState(dayjs());
	const [activeDate, setActiveDate] = useState(dayjs());
	const [activeView, setActiveView] = useState('month');
	const [activeModal, setActiveModal] = useState('');
	const [showDiscardConfirmation, setShowDiscardConfirmation] = useState(false);
	const [formHaveUnsavedChanges, setFormHaveUnsavedChanges] = useState(false);

	const { scheduledEventsRefreshing } = useSelector(selectScheduledEvents);

	const scheduledEventsList = useScheduleEventsByDuration(
		activeDate,
		activeView
	);

	const {
		absenceActionLoading,
		absenceActionErrorMessage,
		absenceActionSuccessMessage,
	} = useSelector(selectAbsenceActionData);

	const {
		addMeetingRequestLoading,
		addMeetingRequestSuccess,
		addMeetingRequestError,
		checkMeetingValidity,
		checkMeetingValidityError,
	} = useSelector(selectAddMeetingRequestStatus);

	const changeActiveView = (updatedView) => {
		setActiveView(updatedView);
		setActiveDate(today);
	};

	const updateActiveDate = (action, updateUnit) => {
		const updatedAmount = action === 'next' ? 1 : -1;
		const currentActiveDate = activeDate.add(updatedAmount, updateUnit);
		setActiveDate(currentActiveDate);
	};

	const onViewEventDetail = (eventId, isProposal) => {
		dispatch(fetchScheduledEventDetailById({ eventId, isProposal }));
		setActiveModal('detail');
	};

	const onViewAllDateEvents = (date) => {
		setActiveDate(date);
		setActiveView('day');
	};

	const onRefreshCalendar = () => {
		const start = activeDate.startOf(activeView).format('YYYY-MM-DD');
		const end = activeDate.endOf(activeView).format('YYYY-MM-DD');
		dispatch(
			fetchScheduledEvents({
				start,
				end,
				isRefreshing: true,
				syncExternal: true,
			})
		);
	};

	const handleAbsenceCreate = (updatedValues) => {
		dispatch(addNewAbsence(updatedValues));
	};

	const onShowModal = (modalType) => {
		setActiveModal(modalType);
	};
	const onHideModal = () => {
		setActiveModal('');
	};

	const onHideCreateMeetingModal = () => {
		onHideModal();
		dispatch(resetAddMeetingStatus());
	};

	const onFormStatusChange = (haveChanges) => {
		setFormHaveUnsavedChanges(haveChanges);
	};

	const onTryToCloseCreateMeetingModal = () => {
		if (formHaveUnsavedChanges) {
			setShowDiscardConfirmation(true);
		} else {
			onHideCreateMeetingModal();
		}
	};

	const onDiscardAndCloseCreateMeetingModal = () => {
		setFormHaveUnsavedChanges(false);
		setShowDiscardConfirmation(false);
		onHideCreateMeetingModal();
	};

	const onHideCreateAbsenceModal = () => {
		onHideModal();
		dispatch(resetAbsencesActionData());
	};

	useEffect(() => {
		const start = activeDate.startOf(activeView).format('YYYY-MM-DD');
		const end = activeDate.endOf(activeView).format('YYYY-MM-DD');
		dispatch(fetchScheduledEvents({ start, end }));
	}, [activeDate, activeView]);

	useEffect(() => {
		dispatch(fetchInvitableUsers());
	}, []);

	return (
		<>
			<div className='text-sm font-semibold'>
				<CalendarPageHeader
					refreshLoading={scheduledEventsRefreshing}
					activeView={activeView}
					today={today}
					activeDate={activeDate}
					onRefresh={onRefreshCalendar}
					onAddNewMeeting={() => onShowModal('meeting')}
					onAddAbsence={() => onShowModal('absence')}
					changeActiveView={changeActiveView}
					updateActiveDate={updateActiveDate}
				/>
				<div
					className={clsx(
						'border border-gray500 rounded-[10px] bg-neutral shadow-theme',
						'mx-[34px] mb-[38px] overflow-hidden'
					)}
				>
					{activeView === 'month' ? (
						<MonthCalendar
							today={today}
							activeDate={activeDate}
							scheduledEventsList={scheduledEventsList}
							onViewEventDetail={onViewEventDetail}
							onViewAllDateEvents={onViewAllDateEvents}
						/>
					) : (
						<WeekCalendar
							today={today}
							activeDate={activeDate}
							scheduledEventsList={scheduledEventsList}
							showFullWeek={activeView === 'week'}
							onViewEventDetail={onViewEventDetail}
						/>
					)}
				</div>
			</div>
			{activeModal === 'meeting' && (
				<Modal
					isOpen={activeModal === 'meeting'}
					onClose={onTryToCloseCreateMeetingModal}
					preventAnimate={true}
					Header={() => (
						<p className='text-2xl font-semibold'>Scheduling Assistant</p>
					)}
					modalClassName='p-8 pb-0'
					modalContentClassName='-mx-8'
					preventBackdropClose={addMeetingRequestLoading}
					hideHeader={
						!!(
							addMeetingRequestSuccess ||
							addMeetingRequestError ||
							checkMeetingValidityError
						)
					}
				>
					<CreateMeetingModal
						checkMeetingValidity={checkMeetingValidity}
						checkMeetingValidityError={checkMeetingValidityError}
						actionLoading={addMeetingRequestLoading}
						actionSuccess={addMeetingRequestSuccess}
						actionError={addMeetingRequestError}
						onFormStatusChange={onFormStatusChange}
						onClose={onTryToCloseCreateMeetingModal}
					/>
				</Modal>
			)}
			{activeModal === 'absence' && (
				<Modal
					isOpen={activeModal === 'absence'}
					onClose={onHideCreateAbsenceModal}
					preventAnimate={true}
					preventBackdropClose={absenceActionLoading}
					hideHeader={true}
				>
					<ManageAbsenceModal
						actionType='add'
						actionLoading={absenceActionLoading}
						actionError={absenceActionErrorMessage}
						actionSuccess={absenceActionSuccessMessage}
						onConfirm={handleAbsenceCreate}
						onCancel={onHideCreateAbsenceModal}
					/>
				</Modal>
			)}
			{activeModal === 'detail' && (
				<Modal
					isOpen={activeModal === 'detail'}
					onClose={onHideModal}
					preventAnimate={true}
					hideHeader={true}
					modalClassName='border border-gray500 shadow-theme px-8'
				>
					<MeetingDetailModal onClose={onHideModal} />
				</Modal>
			)}
			{showDiscardConfirmation && (
				<Modal
					isOpen={showDiscardConfirmation}
					onClose={() => { }}
					preventAnimate={true}
					preventBackdropClose={true}
					hideHeader={true}
				>
					<ConfirmationModal
						description='Your unsaved changes will be discarded, do you want to quit?'
						confirmButtonTitle='Confirm'
						onConfirm={onDiscardAndCloseCreateMeetingModal}
						onCancel={() => setShowDiscardConfirmation(false)}
					/>
				</Modal>
			)}
		</>
	);
};
