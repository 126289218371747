import { useCallback, useMemo } from 'react';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { CalendarWeeklyDay } from './CalendarWeeklyDay';
import { ScrollerToTime } from './ScrollerToTime';
import { HOURSE_IN_DAY } from '../../utils/constants';

export const CalendarWeekGrid = ({
	today,
	workingDays,
	workingDayStartTime,
	currentMonth,
	showFullWeek,
	scheduledEventsList,
	onViewEventDetail,
}) => {
	const daysArray = useMemo(() => {
		const currentDate = dayjs(currentMonth);
		if (showFullWeek) {
			const startOfWeek = currentDate.startOf('week');
			const weekdays = [];
			for (let day = 0; day < 7; day++) {
				weekdays.push(startOfWeek.add(day, 'day'));
			}
			return weekdays;
		} else {
			return [currentDate];
		}
	}, [currentMonth, showFullWeek]);

	const getCurrentDayValues = useCallback(
		(date) => {
			const isToday = date.isSame(today, 'date');
			const isPreviousMonth = date.isBefore(currentMonth, 'month');
			const isNextMonth = date.isAfter(currentMonth, 'month');
			const isWorkingDay = workingDays.includes(date.get('day'));

			const formattedDate = date.format('YYYY-MM-DD');
			const eventsOnDay = scheduledEventsList[formattedDate] || [];

			return {
				dayKey: date.toISOString(),
				dayValue: date.get('date'),
				isToday,
				disabled: isPreviousMonth || isNextMonth,
				isWorkingDay,
				eventsOnDay: eventsOnDay,
				hasEvents: eventsOnDay.length > 0,
			};
		},
		[today, currentMonth, workingDays, scheduledEventsList]
	);

	return (
		<div className='relative h-[calc(100vh-340px-40px)] bg-secondary overflow-y-scroll'>
			<div className='absolute left-0 top-0 w-full pt-[50px]'>
				{HOURSE_IN_DAY.map((workingHours, index) => (
					<div
						key={index}
						className='border-t border-gray500 border-dashed h-[91px]'
					>
						<p className='w-[60px] text-center pt-1 bg-neutral h-full'>
							{workingHours.value}
						</p>
					</div>
				))}
			</div>
			<div
				className={clsx(
					'grid',
					showFullWeek
						? 'grid-cols-7 ml-[60px]'
						: 'grid-cols-1 ml-[180px] border-l border-gray500',
					'text-sm h-full'
				)}
				style={{ minHeight: HOURSE_IN_DAY.length * 91 + 50 }}
			>
				{daysArray.map((date) => {
					const {
						dayKey,
						dayValue,
						isToday,
						disabled,
						isWorkingDay,
						eventsOnDay,
						hasEvents,
					} = getCurrentDayValues(date);

					return (
						<CalendarWeeklyDay
							key={dayKey}
							dateValue={dayValue}
							isToday={isToday}
							disabled={disabled}
							isWorkingDay={isWorkingDay}
							isFullWeek={showFullWeek}
							hasMeeting={hasEvents}
							scheduledEvents={eventsOnDay}
							workingHours={HOURSE_IN_DAY}
							onViewEventDetail={onViewEventDetail}
						/>
					);
				})}
			</div>
			<ScrollerToTime
				viewStartTime={HOURSE_IN_DAY[0]?.value}
				workingDayStartTime={workingDayStartTime}
			/>
		</div>
	);
};
