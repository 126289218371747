import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LinkCard } from "../../components/dashboard";
import { Modal } from "../../components/layout/Modal";
import { CreateMeetingModal } from "../../components/modals/CreateMeetingModal";
import { ConfirmationModal } from "../../components/modals/ConfirmationModal";

import {
  fetchInvitableUsers,
  resetAddMeetingStatus,
  fetchExternalInvitableUsers,
  selectAddMeetingRequestStatus,
  setActiveModal,
  updateIntervalValue,
  clearTimeoutId,
  fetchMeetingInvitations,
  deleteMeetingRequest,
} from "../../store/meetingRequests";
import { MepaMeeting } from "./MepaMeeting";
import { resetScheduledEventUpdateError } from "../../store/scheduledEvents";
import { logout, selectLoggedInUser } from "../../store/user";
import DashboardIcon from "../../assets/icons/dashboard.svg";
import MEPAIcon from "../../assets/icons/stars.svg";
import MeetRequestIcon from "../../assets/icons/Request.svg";
import IntegrationIcon from "../../assets/icons/integrations.svg";
import SettingIcon from "../../assets/icons/Settings.svg";
import ManageMeetIcon from "../../assets/icons/Manage.svg";
import { Header } from "../../components/layout/Header";

export const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showDiscardConfirmation, setShowDiscardConfirmation] = useState(false);
  const [formHaveUnsavedChanges, setFormHaveUnsavedChanges] = useState(false);

  const {
    addMeetingRequestLoading,
    addMepaMeetingRequestError,
    addMeetingRequestSuccess,
    addMepaMeetingRequestSuccess,
    checkMeetingValidity,
    checkMeetingValidityError,
    addMeetingRequestError,
    addMepaTimesloteSuccess,
    activeModal,
  } = useSelector(selectAddMeetingRequestStatus);

  const navigateToLink = (link) => {
    navigate(`../${link}`, { relative: true });
  };

  const showModal = (modalType) => {
    dispatch(setActiveModal(modalType));
    // setActiveModal(modalType);
  };

  const onHideCreateMeetingModal = () => {
    dispatch(setActiveModal(""));
    dispatch(resetAddMeetingStatus());
    dispatch(resetScheduledEventUpdateError());
  };

  const onFormStatusChange = (haveChanges) => {
    setFormHaveUnsavedChanges(haveChanges);
  };

  const newCreatedMepaMeeting = useSelector(
    (state) => state?.meetingRequests?.createdMepaResponseId
  );
  const currentUser = useSelector(selectLoggedInUser);

  const handleCancel = () => {
    const payload = {
      mepaId: newCreatedMepaMeeting,
      userId: currentUser?.userId,
      hostPersonID: currentUser?.userId,
    };
    dispatch(deleteMeetingRequest(payload));
  };

  const onTryToCloseCreateMeetingModal = () => {
    if (formHaveUnsavedChanges) {
      setShowDiscardConfirmation(true);
    } else {
      onHideCreateMeetingModal();
    }
  };

  const onDiscardAndCloseCreateMeetingModal = () => {
    dispatch(clearTimeoutId());
    setFormHaveUnsavedChanges(false);
    setShowDiscardConfirmation(false);
    updateIntervalValue(false);
    onHideCreateMeetingModal();
    if (newCreatedMepaMeeting) {
      handleCancel();
    }
  };

  const location = useLocation();

  useEffect(() => {
    dispatch(fetchInvitableUsers());
    dispatch(fetchMeetingInvitations());
  }, [location.pathname]);

  const redirectToNewTab = (url) => {
    window.open(url, "_blank");
  };

  const onLogout = () => {
    dispatch(logout());
    navigate("/auth/login");
  };

  return (
    <>
      <Header />
      <div className="font-semibold text-sm mx-[34px] pt-[30px] pb-[22px]">
        <h5 className="text-left text-lg font-semibold text-gray-400 mb-4 w-full">
          Main menu
        </h5>
        <div className="mb-6 flex gap-x-6">
          <div className="flex flex-wrap gap-2 w-full">
            <LinkCard
              title="Schedule internal meeting"
              onClick={() => showModal("meeting")}
              iconSrc={DashboardIcon}
            />
            <LinkCard
              title="Schedule external meeting"
              onClick={() => showModal("mepa")}
              iconSrc={MEPAIcon}
            />
            <LinkCard
              title="Review meeting requests"
              onClick={() => navigateToLink("meeting-requests")}
              iconSrc={MeetRequestIcon}
            />
            <LinkCard
              title="Manage meetings"
              onClick={() => navigateToLink("manage-meetings")}
              iconSrc={ManageMeetIcon}
            />
            <LinkCard
              title="Integrations"
              onClick={() =>
                redirectToNewTab("https://app.zelvor.com/admin/settings")
              }
              iconSrc={IntegrationIcon}
            />
            <LinkCard
              title="Settings"
              onClick={() =>
                redirectToNewTab("https://app.zelvor.com/admin/settings")
              }
              iconSrc={SettingIcon}
            />
            <button
              className="mt-2 w-full p-3 border border-gray-400 rounded-full logOutBtn shadow-2xl font-bold"
              onClick={onLogout}
            >
              Logout
            </button>
          </div>
        </div>
        {activeModal === "meeting" && (
          <Modal
            isOpen={activeModal === "meeting"}
            onClose={onTryToCloseCreateMeetingModal}
            preventAnimate={true}
            Header={() => (
              <p className="text-2xl font-semibold">Scheduling Assistant</p>
            )}
            modalClassName="p-8 pb-0"
            modalContentClassName="-mx-8"
            preventBackdropClose={addMeetingRequestLoading}
            hideHeader={
              !!(
                addMeetingRequestSuccess ||
                addMeetingRequestError ||
                checkMeetingValidityError
              )
            }
          >
            <CreateMeetingModal
              actionLoading={addMeetingRequestLoading}
              actionSuccess={addMeetingRequestSuccess}
              checkMeetingValidity={checkMeetingValidity}
              checkMeetingValidityError={checkMeetingValidityError}
              actionError={addMeetingRequestError}
              onFormStatusChange={onFormStatusChange}
              onClose={onTryToCloseCreateMeetingModal}
            />
          </Modal>
        )}
        {activeModal === "mepa" && (
          <Modal
            isOpen={activeModal === "mepa"}
            onClose={onTryToCloseCreateMeetingModal}
            preventAnimate={true}
            Header={() => (
              <p className="text-2xl font-semibold">Meeting scheduler</p>
            )}
            modalClassName="p-8 pb-0"
            modalContentClassName="-mx-8"
            preventBackdropClose={addMeetingRequestLoading}
            hideHeader={
              !!(
                addMeetingRequestSuccess ||
                addMepaMeetingRequestError ||
                checkMeetingValidityError
              )
            }
          >
            <MepaMeeting
              actionLoading={addMeetingRequestLoading}
              // actionLoading={addMepaMeetingRequestLoading}
              actionSuccess={addMepaMeetingRequestSuccess}
              checkMeetingValidity={checkMeetingValidity}
              checkMeetingValidityError={checkMeetingValidityError}
              actionError={addMepaMeetingRequestError}
              onfetchExternalInvities={fetchExternalInvitableUsers}
              onFormStatusChange={onFormStatusChange}
              onClose={onTryToCloseCreateMeetingModal}
              onHideCreateMeetingModal={onHideCreateMeetingModal}
              addMepaTimesloteSuccess={addMepaTimesloteSuccess}
              mepa="mepa"
            />
          </Modal>
        )}
        {showDiscardConfirmation && (
          <Modal
            isOpen={showDiscardConfirmation}
            onClose={() => {}}
            preventAnimate={true}
            preventBackdropClose={true}
            hideHeader={true}
          >
            <ConfirmationModal
              description="Your unsaved changes will be discarded, do you want to quit?"
              confirmButtonTitle="Confirm"
              onConfirm={onDiscardAndCloseCreateMeetingModal}
              onCancel={() => setShowDiscardConfirmation(false)}
            />
          </Modal>
        )}
      </div>
    </>
  );
};
