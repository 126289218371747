import clsx from 'clsx';
import { ReactComponent as ChevronIcon } from '../../assets/icons/Chevron.svg';
import { Tooltip } from '../../theme';

export const CalendarMonthDay = ({
	isToday,
	dateValue,
	disabled,
	isWorkingDay,
	hasMeeting,
	scheduledEvents,
	overflowMeetingsCount,
	onViewEventDetail,
	onViewAllDateEvents,
}) => {
	return (
		<div
			className={clsx(
				'flex aspect-[1.2]',
				'border-l border-t border-gray500',
				'[&:nth-child(7n+1)]:border-l-0 [&:nth-child(-n+7)]:border-t-0',
				isToday && '!border-t-[4px] border-t-info400',
				!isWorkingDay && 'bg-info100',
				hasMeeting && '!bg-secondary'
			)}
			onClick={onViewAllDateEvents}
		>
			<div
				className={clsx(
					'py-[8px] px-[18px] w-full',
					'min-h-[calc((100vh-380px)/6) overflow-y-scroll]'
				)}
			>
				<p
					className={clsx(
						'text-right pb-2',
						disabled && 'text-gray500 opacity-50'
					)}
				>
					{dateValue}
				</p>
				{hasMeeting && (
					<div
						className='flex flex-col items-center justify-center gap-2'
						onClick={(e) => e.stopPropagation()}
					>
						{scheduledEvents.map((calendarEvent) => {
						
							return calendarEvent.isExternalMeeting ? (
								<Tooltip
									containerClassName='w-full'
									tooltipClassName='min-w-[110px]'
									content='Please check your external calendar'
								>
									<p
										key={calendarEvent.multidaysEventKey}
										className={clsx(
											'border rounded-[8px] px-4 text-xs text-left',
											'overflow-hidden whitespace-nowrap text-ellipsis w-full',
											'bg-success100 border-success text-success'
										)}
									>
										External Meeting
									</p>
								</Tooltip>
							) : (
								<button
									key={calendarEvent.multidaysEventKey}
									className={clsx(
										'border rounded-[8px] px-4 text-left',
										'overflow-hidden whitespace-nowrap text-ellipsis w-full',
										calendarEvent.nonAdjustable
											? 'bg-danger100 border-danger text-xs text-danger'
											: 'bg-info100 border-info400 text-info'
									)}
									onClick={() =>
										onViewEventDetail(
											calendarEvent.entityID,
											calendarEvent.isProposal
										)
									}
								>
									{calendarEvent.topic}
								</button>
							);
						})}
						{overflowMeetingsCount > 0 && (
							<button
								className='flex text-gray500 items-center'
								onClick={onViewAllDateEvents}
							>
								<span className='underline text-xs'>
									{overflowMeetingsCount} more meetings
								</span>
								<ChevronIcon fill='transparent' />
							</button>
						)}
					</div>
				)}
			</div>
		</div>
	);
};
