import clsx from 'clsx';

export const Checkbox = ({
	label,
	containerStyle,
	fillColor,
	checked,
	onChange,
}) => {
	return (
		<div
			className={clsx(
				'flex items-center w-fit cursor-pointer select-none',
				containerStyle
			)}
			onClick={() => onChange(!checked)}
		>
			<div
				className={clsx(
					'h-[18px] w-[18px] border-2 rounded-[2px]',
					fillColor ? 'border-[transparent]' : 'border-primary',
					checked && !!fillColor && fillColor
				)}
			>
				{checked && (
					<div
						className={clsx(
							'h-[12px] w-[6px] ml-[4px]',
							'border-b-2 border-r-2 rotate-45',
							{
								'border-[#fff]': fillColor,
							}
						)}
					/>
				)}
			</div>
			{label && <span className='ml-[8px] text-sm text-primary'>{label}</span>}
		</div>
	);
};
