import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { SettingsCard } from './SettingsCard';
import {
	DayChip,
	DaySelector,
	HoursRange,
	SectionHeading,
} from '../meeting-preferences';

import { Select } from '../../theme';
import { selectMyMeetingPreferences } from '../../store/user';
import {
	FLEXIBILITY_OPTIONS,
	MEETING_BLOCKS,
	MEETING_BREAKS_BLOCKS,
	WEEKDAYS_SHORT,
	WORKING_TIME_BLOCKS,
} from '../../utils/constants';
import { convertToMinutes } from '../../utils/dateTime';
import {
	getMeetingPreferencesInitial,
	preferencesTimeSlicFields,
	workingWeekDayFields,
} from '../../pages/Admin/Settings/settingsForms';

import { ReactComponent as PreferencesIcon } from '../../assets/icons/Preferences.svg';
import { ReactComponent as PlusIcon } from '../../assets/icons/Plus.svg';
import { ReactComponent as MinusIcon } from '../../assets/icons/Minus.svg';

export const MeetingPreferencesCard = ({ listRef, formInstance }) => {
	const myPreferences = useSelector(selectMyMeetingPreferences);
	const {
		values,
		errors,
		handleChange,
		setFieldValue,
		dirty: hasFromChanges,
	} = formInstance;

	const onFieldChange = (fieldName, updateValue) => {
		setFieldValue(fieldName, updateValue);
	};

	const onUnselectDay = (fieldName, fieldValues, dayToRemove, workingTimes) => {
		const updatedFieldsValues = fieldValues.filter(
			(day) => day !== dayToRemove
		);
		onFieldChange(fieldName, updatedFieldsValues);

		// Remove the workingTimes if the last day are unselected
		if (
			updatedFieldsValues.length === 0 &&
			typeof workingTimes !== 'undefined' &&
			workingTimes
		) {
			const updatedFieldName = fieldName.replace(
				'.daysOfWeek',
				'.workingTimes'
			);
			onFieldChange(updatedFieldName, Array.isArray(workingTimes) ? [] : {});
		}
	};

	const onUnselectWorkingDay = (fieldName, fieldValues, dayToRemove) => {
		const updatedFieldsValues = fieldValues.filter(
			(day) => day !== dayToRemove
		);
		onFieldChange(fieldName, updatedFieldsValues);

		// Remove the Day from the Preferred Frames as well
		const preferentialWindowIndex = values.preferentialMeetingWindows.findIndex(
			({ daysOfWeek }) => daysOfWeek.includes(dayToRemove)
		);
		if (preferentialWindowIndex !== -1) {
			// Update the Preferential Block
			const windowElementToUpdate =
				values.preferentialMeetingWindows[preferentialWindowIndex];
			const windowsDays = windowElementToUpdate.daysOfWeek.filter(
				(day) => day !== dayToRemove
			);
			onFieldChange(`preferentialMeetingWindows[${preferentialWindowIndex}]`, {
				daysOfWeek: windowsDays,
				workingTimes:
					windowsDays.length > 0 ? windowElementToUpdate.workingTimes : [],
			});
		}

		const blockedWindowIndex = values.blockedMeetingWindows.findIndex(
			({ daysOfWeek }) => daysOfWeek.includes(dayToRemove)
		);
		if (blockedWindowIndex !== -1) {
			// Update the Blocked Block
			const windowElementToUpdate =
				values.blockedMeetingWindows[blockedWindowIndex];
			const windowsDays = windowElementToUpdate.daysOfWeek.filter(
				(day) => day !== dayToRemove
			);
			onFieldChange(`blockedMeetingWindows[${blockedWindowIndex}]`, {
				daysOfWeek: windowsDays,
				workingTimes:
					windowsDays.length > 0 ? windowElementToUpdate.workingTimes : [],
			});
		}
	};

	const onAddWorkingDaysGroup = () => {
		const existingWorkingDays = [...values.calendarWeekDays];
		existingWorkingDays.push(workingWeekDayFields);
		onFieldChange('calendarWeekDays', existingWorkingDays);
	};

	const onRemoveWorkingDaysGroup = (groupIndex) => {
		let existingWorkingDays = [...values.calendarWeekDays];
		const { daysOfWeek: daysToRemove } = existingWorkingDays[groupIndex];
		existingWorkingDays.splice(groupIndex, 1);
		onFieldChange('calendarWeekDays', existingWorkingDays);
		if (daysToRemove.length > 0) {
			const updatedPreferentialWindows =
				values.preferentialMeetingWindows.filter(
					({ daysOfWeek }) =>
						!daysOfWeek.some((day) => daysToRemove.includes(day))
				);
			const updatedBlockedWindows = values.blockedMeetingWindows.filter(
				({ daysOfWeek }) =>
					!daysOfWeek.some((day) => daysToRemove.includes(day))
			);

			if (updatedPreferentialWindows.length > 0) {
				onFieldChange('preferentialMeetingWindows', updatedPreferentialWindows);
			}
			if (updatedBlockedWindows.length > 0) {
				onFieldChange('blockedMeetingWindows', updatedBlockedWindows);
			}
		}
	};

	const syncMeetingAndBlockWindows = (
		existingWindows,
		weekDaysGroup,
		fieldName
	) => {
		const daysInGroup = weekDaysGroup.daysOfWeek;
		const startTimeMinutes = convertToMinutes(weekDaysGroup.startTime);
		const endTimeMinutes = convertToMinutes(weekDaysGroup.endTime);

		let shouldUpdateWindows = false;
		const windowToUpdate = [...existingWindows];

		for (let index = 0; index < windowToUpdate.length; index++) {
			const meetingWindow = windowToUpdate[index];
			const haveCommonDays = meetingWindow.daysOfWeek.some((weekDay) =>
				daysInGroup.includes(weekDay)
			);
			// If no days of this block changed then skip the iteration
			if (!haveCommonDays) {
				continue;
			}

			let shouldOverwriteCurrent = false;
			const filteredTimes = [];

			// Check the selected slices/frames in the current group
			for (const workingTimeItem of meetingWindow.workingTimes) {
				const workingTimeStart = convertToMinutes(workingTimeItem.start);
				const workingTimeEnd = convertToMinutes(workingTimeItem.end);
				// If the Range has cross any of the slice start or end position
				// Then remove that slice
				if (
					workingTimeStart < startTimeMinutes ||
					workingTimeEnd > endTimeMinutes
				) {
					shouldOverwriteCurrent = true;
					shouldUpdateWindows = true;
					continue;
				}
				filteredTimes.push(workingTimeItem);
			}

			// If any of the slice/frame was changed, then overrite the meetingWindowTime
			if (shouldOverwriteCurrent) {
				windowToUpdate[index] = {
					daysOfWeek: meetingWindow.daysOfWeek,
					workingTimes: filteredTimes,
				};
			}
		}

		if (shouldUpdateWindows) {
			onFieldChange(fieldName, windowToUpdate);
		}
	};

	const onUpdateWorkingDaysGroupeTimes = (fieldName) => (updateValue) => {
		onFieldChange(fieldName, updateValue);

		// Get the Updated WorkingDaysGroup
		const workingDaysGroups = values.calendarWeekDays;
		const regex = /calendarWeekDays\[(\d+)\]\.(startTime|endTime)/;
		const matches = fieldName.match(regex);
		const index = parseInt(matches[1], 10);
		const timeType = matches[2];
		const selectedGroup = {
			...workingDaysGroups[index],
			[timeType]: updateValue,
		};

		syncMeetingAndBlockWindows(
			values.preferentialMeetingWindows,
			selectedGroup,
			'preferentialMeetingWindows'
		);

		syncMeetingAndBlockWindows(
			values.blockedMeetingWindows,
			selectedGroup,
			'blockedMeetingWindows'
		);
	};

	const onAddPrefrentialMeetingWindowsException = () => {
		const existingWindows = [...values.preferentialMeetingWindows];
		existingWindows.push(preferencesTimeSlicFields);

		onFieldChange('preferentialMeetingWindows', existingWindows);
	};

	const onRemovePrefrentialMeetingWindowsException = (elementIndex) => {
		let existingWindows = [...values.preferentialMeetingWindows];
		existingWindows.splice(elementIndex, 1);
		onFieldChange('preferentialMeetingWindows', existingWindows);
	};

	const onAddBlockedMeetingWindowsException = () => {
		const existingWindows = [...values.blockedMeetingWindows];
		existingWindows.push(preferencesTimeSlicFields);

		onFieldChange('blockedMeetingWindows', existingWindows);
	};

	const onRemoveBlockedMeetingWindowsException = (elementIndex) => {
		let existingWindows = [...values.blockedMeetingWindows];
		existingWindows.splice(elementIndex, 1);
		onFieldChange('blockedMeetingWindows', existingWindows);
	};

	const getUnavailableWorkingDays = useCallback(
		(elementIndex) => {
			const takenCalendarDaysLists = values.calendarWeekDays.map(
				(item) => item.daysOfWeek
			);
			takenCalendarDaysLists.splice(elementIndex, 1);
			return takenCalendarDaysLists.flat();
		},
		[values.calendarWeekDays]
	);

	const getPreferentialMeetingDays = useCallback(
		(elementIndex) => {
			const allWorkingDays = values.calendarWeekDays.flatMap(
				(calendarDays) => calendarDays.daysOfWeek
			);
			const takenDays = values.preferentialMeetingWindows.flatMap(
				(meetingWindow) => meetingWindow.daysOfWeek
			);
			const freeDays = allWorkingDays.filter((day) => !takenDays.includes(day));

			const workingDaysFrame = values.preferentialMeetingWindows[elementIndex];

			const allowedDays = [];
			let workingDayStartTime = '';
			let workingDayEndTime = '';

			if (workingDaysFrame.daysOfWeek.length === 0) {
				allowedDays.push(...freeDays);
			} else {
				const firstFoundDay = workingDaysFrame.daysOfWeek.find((weekDay) =>
					values.calendarWeekDays.some(({ daysOfWeek }) =>
						daysOfWeek.includes(weekDay)
					)
				);

				const sameWorkingHoursWeekDays = values.calendarWeekDays.find(
					({ daysOfWeek }) => daysOfWeek.includes(firstFoundDay)
				);

				if (sameWorkingHoursWeekDays) {
					const freeDaysWithSameHours = freeDays.filter((day) =>
						sameWorkingHoursWeekDays.daysOfWeek.includes(day)
					);
					allowedDays.push(...freeDaysWithSameHours);
					workingDayStartTime = sameWorkingHoursWeekDays.startTime;
					workingDayEndTime = sameWorkingHoursWeekDays.endTime;
				}
			}

			return {
				allowedDays,
				workingDayStartTime,
				workingDayEndTime,
			};
		},
		[values.calendarWeekDays, values.preferentialMeetingWindows]
	);

	const getBlockedMeetingDays = useCallback(
		(elementIndex) => {
			const allWorkingDays = values.calendarWeekDays.flatMap(
				(calendarDays) => calendarDays.daysOfWeek
			);
			const takenDays = values.blockedMeetingWindows.flatMap(
				(meetingWindow) => meetingWindow.daysOfWeek
			);
			const freeDays = allWorkingDays.filter((day) => !takenDays.includes(day));

			const workingDaysFrame = values.blockedMeetingWindows[elementIndex];

			const allowedDays = [];
			let workingDayStartTime = '';
			let workingDayEndTime = '';

			if (workingDaysFrame.daysOfWeek.length === 0) {
				allowedDays.push(...freeDays);
			} else {
				const firstFoundDay = workingDaysFrame.daysOfWeek.find((weekDay) =>
					values.calendarWeekDays.some(({ daysOfWeek }) =>
						daysOfWeek.includes(weekDay)
					)
				);
				const sameWorkingHoursWeekDays = values.calendarWeekDays.find(
					({ daysOfWeek }) => daysOfWeek.includes(firstFoundDay)
				);

				if (sameWorkingHoursWeekDays) {
					const freeDaysWithSameHours = freeDays.filter((day) =>
						sameWorkingHoursWeekDays.daysOfWeek.includes(day)
					);
					allowedDays.push(...freeDaysWithSameHours);
					workingDayStartTime = sameWorkingHoursWeekDays.startTime;
					workingDayEndTime = sameWorkingHoursWeekDays.endTime;
				}
			}

			return {
				allowedDays,
				workingDayStartTime,
				workingDayEndTime,
			};
		},
		[values.calendarWeekDays, values.blockedMeetingWindows]
	);

	const onUpdateTimeRanges = (fieldName, values) => {
		const parsedValues = values.map((slice) => ({
			start: slice.start,
			end: slice.end,
		}));
		onFieldChange(fieldName, parsedValues);
	};

	useEffect(() => {
		if (myPreferences) {
			// Get Initials Value by preferences
			const parsedInitialValues = getMeetingPreferencesInitial(myPreferences);
			formInstance.resetForm({
				values: parsedInitialValues,
			});
		}
	}, [myPreferences]);

	return (
		<SettingsCard
			title='My meeting preferences'
			description='Inform your collegues of your needs, availability and preferences for meetings'
			Icon={PreferencesIcon}
			sectionKey='meeting-preferences'
			listRef={listRef}
			haveChanges={hasFromChanges}
		>
			<div className='ml-[302px]'>
				<div className='flex flex-wrap'>
					<div className='w-1/2 pr-2'>
						<SectionHeading
							title='Working hours'
							content='When does your normal workday start?'
							hasTooltip={true}
						/>
						{values.calendarWeekDays.map((calendarDay, index) => {
							const unavailableDays = getUnavailableWorkingDays(index);
							return (
								<div key={index} className='mb-8'>
									<DaySelector
										buttonType={index === 0 ? 'add' : 'remove'}
										name={`calendarWeekDays[${index}].daysOfWeek`}
										selectedDays={calendarDay.daysOfWeek}
										unavailableDays={unavailableDays}
										onSelect={onFieldChange}
										onAddException={onAddWorkingDaysGroup}
										onRemoveException={() => onRemoveWorkingDaysGroup(index)}
										hasError={
											!!(
												hasFromChanges &&
												errors.calendarWeekDays &&
												errors.calendarWeekDays[index]?.daysOfWeek
											)
										}
										error={
											errors.calendarWeekDays &&
											errors.calendarWeekDays[index]?.daysOfWeek
										}
									/>
									<div className='flex flex-wrap gap-2'>
										{calendarDay.daysOfWeek.map((selectedDay) => (
											<DayChip
												key={selectedDay}
												day={WEEKDAYS_SHORT[selectedDay]}
												type='info'
												onRemove={() =>
													onUnselectWorkingDay(
														`calendarWeekDays[${index}].daysOfWeek`,
														calendarDay.daysOfWeek,
														selectedDay
													)
												}
											/>
										))}
									</div>
									<div className='flex gap-3 mt-3'>
										<Select
											name={`calendarWeekDays[${index}].startTime`}
											placeholder='From'
											value={calendarDay.startTime}
											options={WORKING_TIME_BLOCKS}
											disabled={calendarDay.daysOfWeek.length === 0}
											onChange={onUpdateWorkingDaysGroupeTimes}
											containerClassName='min-w-[100px]'
											controlClassName='!border-primary'
											menuPortalClassName='!z-[11]'
											scrollId='scroll'
											hasError={
												!!(
													hasFromChanges &&
													errors.calendarWeekDays &&
													errors.calendarWeekDays[index]?.startTime
												)
											}
											error={
												errors.calendarWeekDays &&
												errors.calendarWeekDays[index]?.startTime
											}
											errorClassName='max-w-[100px]'
										/>
										<Select
											name={`calendarWeekDays[${index}].endTime`}
											placeholder='To'
											value={calendarDay.endTime}
											options={WORKING_TIME_BLOCKS}
											disabled={calendarDay.daysOfWeek.length === 0}
											onChange={onUpdateWorkingDaysGroupeTimes}
											containerClassName='min-w-[100px]'
											controlClassName='!border-primary'
											menuPortalClassName='!z-[11]'
											scrollId='scroll'
											hasError={
												!!(
													hasFromChanges &&
													errors.calendarWeekDays &&
													errors.calendarWeekDays[index]?.endTime
												)
											}
											error={
												errors.calendarWeekDays &&
												errors.calendarWeekDays[index]?.endTime
											}
											errorClassName='max-w-[100px]'
										/>
									</div>
								</div>
							);
						})}
					</div>
					<div className='w-1/2 pl-2'>
						<SectionHeading
							title='Overtime flexibility'
							content='In special cases, Are you also available for meetings before and after your normal workday as a last resort?'
							hasTooltip={true}
						/>
						<div className='flex gap-3 mt-3 mb-8'>
							<Select
								name='overTimeFlexibilityBeforeStart'
								placeholder='Morning'
								options={FLEXIBILITY_OPTIONS}
								value={values.overTimeFlexibilityBeforeStart}
								onChange={handleChange}
								containerClassName='w-[115px]'
								controlClassName='!border-primary'
								scrollId='scroll'
								hasError={
									!!(hasFromChanges && errors.overTimeFlexibilityBeforeStart)
								}
								error={errors.overTimeFlexibilityBeforeStart}
							/>
							<Select
								name='overTimeFlexibilityAfterEnd'
								placeholder='Afternoon'
								options={FLEXIBILITY_OPTIONS}
								value={values.overTimeFlexibilityAfterEnd}
								onChange={handleChange}
								containerClassName='w-[115px]'
								controlClassName='!border-primary'
								scrollId='scroll'
								hasError={
									!!(hasFromChanges && errors.overTimeFlexibilityAfterEnd)
								}
								error={errors.overTimeFlexibilityAfterEnd}
							/>
						</div>
					</div>
					<div className='w-1/2 pr-2'>
						<SectionHeading
							title='Preferential meeting block length'
							content='What is your maximum for a consecutive run of meetings?'
							hasTooltip={true}
						/>
						<Select
							name='meetingBlockPreference'
							placeholder='00:00'
							value={values.meetingBlockPreference}
							options={MEETING_BLOCKS}
							onChange={handleChange}
							containerClassName='flex mt-4'
							controlClassName='!border-primary !w-[120px]'
							scrollId='scroll'
							hasError={!!(hasFromChanges && errors.meetingBlockPreference)}
							error={errors.meetingBlockPreference}
						/>
					</div>
					<div className='w-1/2 pl-2'>
						<SectionHeading
							title='Preferential meeting break length'
							content='How long do you want to take a break after your preferred meeting block length?'
							hasTooltip={true}
						/>
						<Select
							name='requiredBreakIfMaxReached'
							placeholder='00:00'
							value={values.requiredBreakIfMaxReached}
							options={MEETING_BREAKS_BLOCKS}
							onChange={handleChange}
							containerClassName='flex mt-4'
							controlClassName='!border-primary !w-[120px]'
							scrollId='scroll'
							hasError={!!(hasFromChanges && errors.requiredBreakIfMaxReached)}
							error={errors.requiredBreakIfMaxReached}
						/>
					</div>
				</div>
				<div className='mt-[30px]'>
					<SectionHeading
						title='Preferred meeting time windows'
						content='At what times of the day do you prefer meetings?'
						hasTooltip={true}
					/>
					{values.preferentialMeetingWindows.map(
						(preferredMeetingWindow, index) => {
							// GET Allowed Days, Day Start, Day End
							const { allowedDays, workingDayStartTime, workingDayEndTime } =
								getPreferentialMeetingDays(index);
							return (
								<div
									key={index}
									className='flex items-center justify-between mb-8'
								>
									<div className=''>
										<DaySelector
											name={`preferentialMeetingWindows[${index}].daysOfWeek`}
											selectedDays={preferredMeetingWindow.daysOfWeek}
											availableDays={allowedDays}
											onSelect={onFieldChange}
											hasError={
												!!(
													hasFromChanges &&
													errors.preferentialMeetingWindows &&
													errors.preferentialMeetingWindows[index]?.daysOfWeek
												)
											}
											error={
												errors.preferentialMeetingWindows &&
												errors.preferentialMeetingWindows[index]?.daysOfWeek
											}
										/>
										<div className='flex gap-2 flex-wrap max-w-[322px]'>
											{preferredMeetingWindow.daysOfWeek.map((selectedDay) => (
												<DayChip
													key={selectedDay}
													day={WEEKDAYS_SHORT[selectedDay]}
													type='info'
													onRemove={() =>
														onUnselectDay(
															`preferentialMeetingWindows[${index}].daysOfWeek`,
															preferredMeetingWindow.daysOfWeek,
															selectedDay,
															preferredMeetingWindow.workingTimes
														)
													}
												/>
											))}
										</div>
										{preferredMeetingWindow.daysOfWeek.length > 0 &&
											workingDayStartTime &&
											workingDayEndTime && (
												<HoursRange
													type='info'
													startTime={workingDayStartTime}
													endTime={workingDayEndTime}
													selectedRanges={preferredMeetingWindow.workingTimes}
													onUpdateTimeRanges={(ranges) =>
														onUpdateTimeRanges(
															`preferentialMeetingWindows[${index}].workingTimes`,
															ranges
														)
													}
													hasError={
														!!(
															hasFromChanges &&
															errors.preferentialMeetingWindows &&
															errors.preferentialMeetingWindows[index]
																?.workingTimes
														)
													}
													error={
														errors.preferentialMeetingWindows &&
														errors.preferentialMeetingWindows[index]
															?.workingTimes
													}
												/>
											)}
									</div>
									{index === 0 ? (
										<button
											className='flex items-center text-sm font-semibold'
											onClick={onAddPrefrentialMeetingWindowsException}
										>
											<PlusIcon />
											<span className='underline'>Add an exception</span>
										</button>
									) : (
										<button
											className='flex items-center text-sm font-semibold'
											onClick={() =>
												onRemovePrefrentialMeetingWindowsException(index)
											}
										>
											<MinusIcon />
											<span className='underline'>Remove exception</span>
										</button>
									)}
								</div>
							);
						}
					)}
				</div>
				<div className='mt-[30px]'>
					<SectionHeading
						title='Blocked time windows'
						content='At which times of the day do you want to have no meetings?'
						hasTooltip={true}
					/>
					{values.blockedMeetingWindows.map((blockedMeetingWindow, index) => {
						const { allowedDays, workingDayStartTime, workingDayEndTime } =
							getBlockedMeetingDays(index);
						return (
							<div
								key={index}
								className='flex items-center justify-between mb-8'
							>
								<div className=''>
									<DaySelector
										name={`blockedMeetingWindows[${index}].daysOfWeek`}
										selectedDays={blockedMeetingWindow.daysOfWeek}
										availableDays={allowedDays}
										onSelect={onFieldChange}
										hasError={
											!!(
												hasFromChanges &&
												errors.blockedMeetingWindows &&
												errors.blockedMeetingWindows[index]?.daysOfWeek
											)
										}
										error={
											errors.blockedMeetingWindows &&
											errors.blockedMeetingWindows[index]?.daysOfWeek
										}
									/>
									<div className='flex gap-2 flex-wrap max-w-[322px]'>
										{blockedMeetingWindow.daysOfWeek.map((selectedDay) => (
											<DayChip
												key={selectedDay}
												day={WEEKDAYS_SHORT[selectedDay]}
												type='danger'
												onRemove={() =>
													onUnselectDay(
														`blockedMeetingWindows[${index}].daysOfWeek`,
														blockedMeetingWindow.daysOfWeek,
														selectedDay,
														blockedMeetingWindow.workingTimes
													)
												}
											/>
										))}
									</div>
									{blockedMeetingWindow.daysOfWeek.length > 0 &&
										workingDayStartTime &&
										workingDayEndTime && (
											<HoursRange
												type='danger'
												startTime={workingDayStartTime}
												endTime={workingDayEndTime}
												selectedRanges={blockedMeetingWindow.workingTimes}
												onUpdateTimeRanges={(ranges) =>
													onUpdateTimeRanges(
														`blockedMeetingWindows[${index}].workingTimes`,
														ranges
													)
												}
												hasError={
													!!(
														hasFromChanges &&
														errors.blockedMeetingWindows &&
														errors.blockedMeetingWindows[index]?.workingTimes
													)
												}
												error={
													errors.blockedMeetingWindows &&
													errors.blockedMeetingWindows[index]?.workingTimes
												}
											/>
										)}
								</div>
								{index === 0 ? (
									<button
										className='flex items-center text-sm font-semibold'
										onClick={onAddBlockedMeetingWindowsException}
									>
										<PlusIcon />
										<span className='underline'>Add an exception</span>
									</button>
								) : (
									<button
										className='flex items-center text-sm font-semibold'
										onClick={() =>
											onRemoveBlockedMeetingWindowsException(index)
										}
									>
										<MinusIcon />
										<span className='underline'>Remove exception</span>
									</button>
								)}
							</div>
						);
					})}
				</div>
			</div>
		</SettingsCard>
	);
};
