import clsx from 'clsx';
import { CalendarMonthGrid } from './CalendarMonthGrid';
import { useGetWorkingDays } from '../../hooks/workingHours';

export const MonthCalendar = ({
	activeDate,
	today,
	scheduledEventsList,
	onViewEventDetail,
	onViewAllDateEvents,
}) => {
	const workingDays = useGetWorkingDays();

	return (
		<div className='select-none h-full'>
			<div
				className={clsx(
					'flex items-center columns-7',
					'text-sm text-center bg-secondary h-10',
					'border-b border-gray500'
				)}
			>
				{['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].map(
					(weekDay, index) => (
						<p key={index} className='w-full'>
							{weekDay}
						</p>
					)
				)}
			</div>
			<CalendarMonthGrid
				today={today}
				currentMonth={activeDate}
				workingDays={workingDays}
				scheduledEventsList={scheduledEventsList}
				onViewEventDetail={onViewEventDetail}
				onViewAllDateEvents={onViewAllDateEvents}
			/>
		</div>
	);
};
