import { useMemo } from 'react';
import clsx from 'clsx';
import PageTitle from '../PageTitle';

import { ReactComponent as CalendarIcon } from '../../assets/icons/Calendar.svg';
import { ReactComponent as RefreshIcon } from '../../assets/icons/refresh.svg';
import { ReactComponent as PlusIcon } from '../../assets/icons/Plus.svg';
import { ReactComponent as ChevronIcon } from '../../assets/icons/Chevron.svg';

const switchingButtons = [
	{ label: 'Today', value: 'day' },
	{ label: 'This Week', value: 'week' },
	{ label: 'This Month', value: 'month' },
];

export const CalendarPageHeader = ({
	refreshLoading,
	activeView,
	activeDate,
	onRefresh,
	onAddNewMeeting,
	onAddAbsence,
	changeActiveView,
	updateActiveDate,
}) => {
	const { date, month, year, week } = useMemo(() => {
		// Format Week Display
		const startOfWeek = activeDate.startOf('week').set('day', 1);
		const endOfWeek = activeDate.endOf('week').set('day', 0);
		let formattedStart = startOfWeek.format('DD MMM');
		const formattedEnd = endOfWeek.format('DD MMM');
		// If the month is the same, omit it from the first part of the week range.
		if (startOfWeek.isSame(endOfWeek, 'month')) {
			formattedStart = startOfWeek.format('DD');
		}
		const weekRange = `${formattedStart} - ${formattedEnd}`;

		return {
			date: activeDate.format('DD MMM YYYY'),
			month: activeDate.format('MMM'),
			year: activeDate.format('YYYY'),
			week: weekRange,
		};
	}, [activeDate]);

	return (
		<div className=''>
			<div className='flex items-center justify-between'>
				<div className='w-[356px]'>
					<PageTitle Icon={CalendarIcon} text='Calendar' />
				</div>
				<h3 className='text-2xl'>{date}</h3>
				<div className='flex flex-grow justify-end mr-12 h-[36px]'>
					<div className='flex gap-8 border-b border-gray500 px-3 pt-3'>
						{switchingButtons.map(({ label, value }) => (
							<button
								key={value}
								className={clsx(
									'w-[145px] text-sm text-gray500 text-center rounded-t-[2px] relative',
									activeView === value
										? 'bg-primary text-neutral opacity-80'
										: 'hover:bg-primary hover:text-neutral hover:opacity-80',
									activeView === value &&
										'after:content-[""] after:absolute after:h-[1px] after:w-[169px] after:bg-primary after:bottom-[-0.8px] after:left-[-12px]'
								)}
								onClick={() => changeActiveView(value)}
							>
								{label}
							</button>
						))}
					</div>
				</div>
			</div>
			<div
				className={clsx(
					'flex items-center justify-between',
					'flex-wrap 2lg:flex-nowrap gap-y-4',
					'mt-7 mb-4 mx-[34px]'
				)}
			>
				<div className='flex gap-[10px]'>
					<button
						className={clsx(
							'flex items-center',
							'border border-gray500',
							'w-[280px] px-[25px] py-3 bg-neutral rounded-full',
							'shadow-theme'
						)}
						disabled={refreshLoading}
						onClick={onRefresh}
					>
						<RefreshIcon
							className={clsx(refreshLoading && 'animate-spin')}
							fill='transparent'
						/>
						<span className='flex-grow'>Refresh</span>
					</button>
					<button
						className={clsx(
							'flex items-center gap-8',
							'border border-gray500',
							'w-[280px] px-[25px] py-3 bg-neutral rounded-full',
							'shadow-theme'
						)}
						onClick={onAddNewMeeting}
					>
						<PlusIcon />
						<span className='flex-grow text-left'>Schedule new meeting</span>
					</button>
					<button
						className={clsx(
							'flex items-center gap-8',
							'border border-gray500',
							'w-[280px] px-[25px] py-3 bg-neutral rounded-full',
							'shadow-theme'
						)}
						onClick={onAddAbsence}
					>
						<PlusIcon />
						<span className='flex-grow text-left'>Add absence</span>
					</button>
				</div>
				<div className='flex flex-wrap justify-end gap-3 text-gray500 mx-auto 2lg:mx-0'>
					{activeView === 'day' && (
						<div className='flex items-center justify-between px-3 py-1 w-[188px]'>
							<button onClick={() => updateActiveDate('prev', 'day')}>
								<ChevronIcon className='fill-[transparent] rotate-[190deg]' />
							</button>
							<p>{date}</p>
							<button onClick={() => updateActiveDate('next', 'day')}>
								<ChevronIcon className='fill-[transparent]' />
							</button>
						</div>
					)}
					{activeView === 'week' && (
						<div className='flex items-center justify-between px-3 py-1 w-[188px]'>
							<button onClick={() => updateActiveDate('prev', 'week')}>
								<ChevronIcon className='fill-[transparent] rotate-[190deg]' />
							</button>
							<p>{week}</p>
							<button onClick={() => updateActiveDate('next', 'week')}>
								<ChevronIcon className='fill-[transparent]' />
							</button>
						</div>
					)}
					{activeView === 'month' && (
						<div className='flex items-center justify-between px-3 py-1 w-[188px]'>
							<button onClick={() => updateActiveDate('prev', 'month')}>
								<ChevronIcon className='fill-[transparent] rotate-[190deg]' />
							</button>
							<p>{month}</p>
							<button onClick={() => updateActiveDate('next', 'month')}>
								<ChevronIcon className='fill-[transparent]' />
							</button>
						</div>
					)}

					{activeView !== 'day' && (
						<div className='flex items-center justify-between px-3 py-1 w-[188px]'>
							<button onClick={() => updateActiveDate('prev', 'year')}>
								<ChevronIcon className='fill-[transparent] rotate-[190deg]' />
							</button>
							<p>{year}</p>
							<button onClick={() => updateActiveDate('next', 'year')}>
								<ChevronIcon className='fill-[transparent]' />
							</button>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};
