import clsx from 'clsx';

export const Switch = ({ name, checked, value, disabled, onChange }) => {
	return (
		<div className='flex'>
			<label
				className={clsx(
					'relative inline-flex items-center',
					!disabled && 'cursor-pointer'
				)}
			>
				<input
					type='checkbox'
					name={name}
					checked={checked}
					className='sr-only peer'
					disabled={disabled}
					value={value}
					onChange={onChange}
				/>
				<div
					className={clsx(
						'w-9 h-5 rounded-full',

						disabled
							? 'bg-gray300 peer-checked:bg-danger500'
							: 'bg-gray500 peer-checked:bg-danger',
						'peer-checked:after:translate-x-full peer-checked:after:border-neutral',
						"after:content-[''] after:absolute after:transition-all",
						'after:border after:rounded-full',
						'after:bg-neutral after:border-neutral',
						'after:top-0.5 after:left-[2px] after:h-4 after:w-4'
					)}
				/>
			</label>
		</div>
	);
};
