import { useEffect, useState } from "react";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import PageTitle from "../../components/PageTitle";
import { Select } from "../../theme/Select";
import { MeetingRequestCard } from "../../components/meeting-requests/MeetingRequestCard";
import { MeetingRequestEmptyCard } from "../../components/meeting-requests/MeetingRequestEmptyCard";
import { Spinner } from "../../components/Spinner";
import { Modal } from "../../components/layout/Modal";
import { MeetingAgendModal } from "../../components/modals/MeetingAgendModal";
import { ConfirmationModal } from "../../components/modals/ConfirmationModal";
import {
  fetchMeetingInvitations,
  resetMeetingResponseError,
  resetMeetingResponseStatus,
  responseToMeetingInvitation,
  selectMeetingInvitations,
  selectMeetingResponseStatus,
} from "../../store/meetingRequests";
import {
  MEETING_INVITATION_RESPONSE,
  MEETING_REQUESTS_FILTER_OPTIONS,
} from "../../utils/constants";

import { ReactComponent as RefreshIcon } from "../../assets/icons/refresh.svg";
import { ReactComponent as ArrowBack } from "../../assets/icons/IoIosArrowBack.svg";

export const MeetingRequests = () => {
  const dispatch = useDispatch();
  const {
    meetingInvitations,
    meetingInvitationsLoading,
    meetingInvitationsRefreshing,
  } = useSelector(selectMeetingInvitations);

  const {
    meetingResponseLoading,
    meetingResponseSuccess,
    meetingResponseError,
  } = useSelector(selectMeetingResponseStatus);

  const [activeFilter, setActiveFilter] = useState("pending");
  const [showAgendaDetailModal, setShowAgendaDetailModal] = useState("");
  const [invitionAction, setInvitationAction] = useState(null);
  const [filteredInvitationsRequest, setFilteredInvitationsRequest] = useState(
    []
  );

  useEffect(() => {
    dispatch(fetchMeetingInvitations());
  }, []);

  useEffect(() => {
    const filteredInvitations = meetingInvitations.filter((request) => {
      if (request.isHost) {
        return false;
      }
      if (activeFilter === "accepted") {
        return request.response === MEETING_INVITATION_RESPONSE.ACCEPTED;
      }
      if (activeFilter === "declined") {
        return request.response === MEETING_INVITATION_RESPONSE.REJECTED;
      }
      if (activeFilter === "pending") {
        return !request.response;
      } else if (activeFilter === "all") {
        return true;
      }

      return true;
    });

    setFilteredInvitationsRequest(filteredInvitations);
  }, [activeFilter, meetingInvitations]);

  const onChangeActiveFilter = () => (value) => {
    setActiveFilter(value);
  };
  const onRefresh = () => {
    dispatch(fetchMeetingInvitations(true));
  };

  const onInvitationAction = (proposal, response) => [
    setInvitationAction({
      proposalId: proposal?.meetingProposalID,
      mepaId: proposal?.mepaId,
      response,
    }),
  ];

  const handleResponse = (invitationActions) => {
    if (invitationActions.mepaId) {
      dispatch(
        responseToMeetingInvitation({
          mepaId: invitationActions?.mepaId,
          response: invitationActions?.response,
        })
      );
    } else {
      dispatch(
        responseToMeetingInvitation({
          proposalId: invitationActions?.proposalId,
          response: invitationActions?.response,
        })
      );
    }
    setTimeout(() => {
      dispatch(fetchMeetingInvitations());
    }, 3000);
  };

  const onClearResponseActionError = () => {
    dispatch(resetMeetingResponseError());
  };

  const onHideInvitionActionModal = () => {
    setInvitationAction(null);
    dispatch(resetMeetingResponseStatus());
  };

  return (
    <div className="font-semibold text-sm">
      <div className="flex items-center">
        <PageTitle
          Icon={ArrowBack}
          text="Meeting requests"
          link="dashboard"
        />
      </div>

      <div className="flex items-center justify-between py-4 px-4">
        <div className="flex items-center">
          <button
            className="flex items-center rounded-2xl border border-gray-400 py-1 px-2 cursor-pointer scheduleButton"
            disabled={meetingInvitationsRefreshing || meetingInvitationsLoading}
            onClick={onRefresh}
          >
            <RefreshIcon
              className={clsx(meetingInvitationsRefreshing && "animate-spin")}
              fill="transparent"
            />
            <span className="flex-grow mx-2">Refresh</span>
          </button>
        </div>

        <div className="ml-auto">
          <Select
            options={MEETING_REQUESTS_FILTER_OPTIONS}
            value={activeFilter}
            onChange={onChangeActiveFilter}
            containerClassName="min-w-[120px]"
            controlClassName=""
          />
        </div>
      </div>

      <div
        className={clsx(
          "overflow-scroll",
          "px-4 py-8 font-semibold",
          
        )}
      >
        {meetingInvitationsLoading && (
          <div className="text-center my-8">
            <Spinner className="!w-10 !h-10" />
          </div>
        )}
        {filteredInvitationsRequest.map((meetingRequestItem, index) => (
          <MeetingRequestCard
            key={index}
            meetingRequest={meetingRequestItem}
            onAccept={() =>
              onInvitationAction(
                {
                  mepaId: meetingRequestItem.mepaId,
                  meetingProposalID: meetingRequestItem.meetingProposalID,
                },
                1
              )
            }
            onDecline={() =>
              onInvitationAction(
                {
                  mepaId: meetingRequestItem.mepaId,
                  meetingProposalID: meetingRequestItem.meetingProposalID,
                },
                2
              )
            }
            setShowAgendaDetailModal={setShowAgendaDetailModal}
          />
        ))}
        {filteredInvitationsRequest.length === 0 &&
          !meetingInvitationsLoading && (
            <MeetingRequestEmptyCard filter={activeFilter} />
          )}
      </div>
      {showAgendaDetailModal && (
        <Modal
          isOpen={!!showAgendaDetailModal}
          onClose={() => setShowAgendaDetailModal("")}
          preventAnimate={true}
          hideHeader={true}
          modalClassName="border border-gray500 shadow-theme p-0"
        >
          <MeetingAgendModal
            onClose={() => setShowAgendaDetailModal("")}
            agendaText={showAgendaDetailModal}
          />
        </Modal>
      )}
      {!!invitionAction && (
        <Modal
          isOpen={!!invitionAction}
          onClose={onHideInvitionActionModal}
          preventAnimate={true}
          hideHeader={true}
          modalClassName={clsx("border border-gray500 shadow-theme py-8")}
          preventBackdropClose={meetingResponseLoading}
        >
          <ConfirmationModal
            description={`Are you sure you want to ${
              invitionAction.response === MEETING_INVITATION_RESPONSE.ACCEPTED
                ? "accept"
                : "decline"
            }?`}
            confirmButtonTitle="Confirm"
            modalTitle="Response to invitation failed."
            actionLoading={meetingResponseLoading}
            actionSuccess={meetingResponseSuccess}
            actionError={meetingResponseError}
            onConfirm={() => handleResponse(invitionAction)}
            onCancel={onHideInvitionActionModal}
            onClearError={onClearResponseActionError}
          />
        </Modal>
      )}
    </div>
  );
};
