import React, { useEffect, useState } from "react";
import calendar from "../../../src/assets/images/calendar.png";
import time from "../../assets/images/time.svg";
import logout from "../../assets/images/logout.png";
import closecircle from "../../assets/images/closecircle.png";
import { useDispatch, useSelector } from "react-redux";
import { clearTimeoutId, postMeetingTimeSLotData } from "../../store/meetingRequests";
import dayjs from "dayjs";
import { toast } from "react-toastify";

const MeetingTimeSlot = ({
  timeSlotsData,
  onCancel,
  onFormStatusChange,
  addMepaTimesloteSuccess,
}) => {
  const dispatch = useDispatch();

  const [selectedIds, setSelectedIds] = useState([]);
  function simplifyTime(timestamp) {
    const date = new Date(timestamp);
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    return `${hours}:${minutes}`;
  }

  // function simplifyDate(timestamp) {
  //   const date = new Date(timestamp);
  //   const day = date.getDate().toString().padStart(2, "0");
  //   const month = (date.getMonth() + 1).toString().padStart(2, "0");
  //   const year = date.getFullYear().toString().slice(-2);

  //   return `${year}-${month}-${day}`;
  // }
  
  const handleCheckboxChange = (id) => {
    const selectedIndex = selectedIds.indexOf(id);
    if (selectedIndex === -1) {
      setSelectedIds([...selectedIds, id]);
    } else {
      setSelectedIds([
        ...selectedIds.slice(0, selectedIndex),
        ...selectedIds.slice(selectedIndex + 1),
      ]);
    }
  };

  const createdMepaResponseId = useSelector((state) => state?.meetingRequests?.createdMepaResponseId);


  const handleSubmit = () => {
    if (selectedIds.length > 2) {
      const payload = {
        mepaId: createdMepaResponseId,
        selectedProposals: selectedIds
      }
      dispatch(postMeetingTimeSLotData(payload));
    } else {
      toast.error('Please select at least 3 time slots');
    }

  };


  useEffect(() => {
    dispatch(clearTimeoutId())
  }, [])

  return (
    <>
      <div className="w-full h-[500px] flex flex-col justify-between">
        <div className=" w-4/5  mx-auto p-2">
          <div>
            <p className="text-xl text-center">
              Please mark the time slots you want to propose to invitees.
            </p>
          </div>
          <div className="mt-6">
            {timeSlotsData?.length > 0 &&
              timeSlotsData.map((elm) => {
                return (
                  <>
                    <div className="pt-3 flex  items-center justify-between">
                      <img src={calendar} alt="" className="w-6 h-6" />
                      <p className="font-medium w-[50%] flex justify-center">
                        {/* {simplifyDate(elm?.scheduledStart)} */}
                        {dayjs(elm?.scheduledStart).format("DD.MM.YYYY")}
                      </p>
                      <img src={time} alt="" className="w-6 h-6" />
                      <p className="font-medium flex justify-center w-[50%]">
                        {simplifyTime(elm?.scheduledStart)}-
                        {simplifyTime(elm?.scheduledFinish)}
                      </p>
                      <input
                        onChange={() =>
                          handleCheckboxChange(elm.meetingProposalId)
                        }
                        type="checkbox"
                        className="w-4 h-4"
                      />
                    </div>
                  </>
                );
              })}
          </div>
        </div>

        <div className="w-2/3 mx-auto">
          <div
            style={{ border: "1px solid lightgray" }}
            className={`flex items-center gap-3  ${selectedIds.length < 3 && "bg-[#EAF9F8]/5"} rounded-full justify-center p-2 m-2 bg-[#EAF9F8]`}
          >
            <img src={logout} alt="" />{" "}
            <button
              className=""
              // disabled={selectedIds.length < 3}
              onClick={handleSubmit}
            >
              {" "}
              Submit Time Slot
            </button>
          </div>
          <div
          onClick={onCancel}
            style={{ border: "1px solid lightgray" }}
            className="flex items-center gap-3 rounded-full justify-center p-2 m-2 mt-4 mb-4 cursor-pointer"
          >
            <img src={closecircle} alt="" />{" "}
            <button className="">
              {" "}
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default MeetingTimeSlot;
