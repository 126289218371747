import { useMemo, useState } from "react";
import clsx from "clsx";
import { Tooltip } from "react-tooltip";
import { ParticipantsChip } from "../ParticipantsChip";
import { getHoursDifferenceBetweenTimes } from "../../utils/dateTime";

import { ReactComponent as ChevronIcon } from "../../assets/icons/Chevron-Circle.svg";
import { ReactComponent as ChevronDangerIcon } from "../../assets/icons/chevron-danger.svg";

export const CalendarEventChip = ({
  // 	meetingId,
  // 	meetingTime,
  // 	topic,
  // 	meetingLength,
  // 	meetingStart,
  // 	nonAdjustable,
  // 	isProposal,
  // 	isExternalMeeting,
  // 	participants,
  // 	remainingCounter,
  // 	dayStartTime,
  // 	containerClassName,
  // 	onViewEventDetail,
  // 	overlapping,
  //   numberOfOverlappingEvents,
  meetingId,
  meetingTime,
  topic,
  start,
  meetingLength,
  meetingStart,
  nonAdjustable,
  isProposal,
  isExternalMeeting,
  participants,
  remainingCounter,
  dayStartTime,
  containerClassName,
  onViewEventDetail,
  overlapping,
  numberOfOverlappingEvents,
  overlappingGroups,
}) => {
  const [time, setTime] = useState({});
  // const { postionTop, chipHeight } = useMemo(() => {
  // 	const hoursFromDayStart = getHoursDifferenceBetweenTimes(
  // 		dayStartTime,
  // 		meetingStart
  // 	);

  // 	return {
  // 		postionTop: hoursFromDayStart * 91 + 2,
  // 		chipHeight: meetingLength * 91 - 4,
  // 	};
  // }, [dayStartTime, meetingStart, meetingLength]);

  const { postionTop, chipHeight } = useMemo(() => {
    let highestEndTime = new Date("2000-01-01T00:00:00");
    let highestStartTime = new Date("3000-01-01T00:00:00");
    const hoursFromDayStart = getHoursDifferenceBetweenTimes(
      dayStartTime,
      meetingStart
    );

    overlappingGroups.forEach((group) => {
      if (group.length > 1) {
        group.forEach((item) => {
          console.log("item :", item);
          const endTime = new Date(item?.end);
          const startTime = new Date(item?.start);
          if (endTime > highestEndTime) {
            highestEndTime = endTime;
            setTime((prevTime) => ({ ...prevTime, highestEndTime: endTime }));
          }
          if (startTime < highestStartTime) {
            highestStartTime = startTime;
            setTime((prevTime) => ({
              ...prevTime,
              highestStartTime: startTime,
            }));
          }
        });
      }
    });

    console.log("meetingLength @:", highestEndTime, meetingTime);
    let differencStartAndEndTime = highestStartTime - highestEndTime;
    let eventDurationInHours =
      Math.abs(differencStartAndEndTime) / (1000 * 60 * 60);

    // Now, eventDurationInHours represents the difference in hours
    console.log("eventDurationInHours", eventDurationInHours);
    return {
      postionTop: hoursFromDayStart * 91 + 2,
      chipHeight: overlapping
        ? eventDurationInHours * 91 - 4
        : meetingLength * 91 - 4,
    };
  }, [
    dayStartTime,
    meetingStart,
    meetingLength,
    overlapping,
    numberOfOverlappingEvents,
  ]);

  console.log("meetingLength @2222222222:", time.highestEndTime);
  // Initialize with a low date

  // Function to format time in HH:MM format
  // const formatTime = (time) => {
  //   return `${time.getHours().toString().padStart(2, "0")}:${time
  //     .getMinutes()
  //     .toString()
  //     .padStart(2, "0")}`;
  // };

  const handleEventDetail = () => {
    if (isExternalMeeting) {
      return;
    }
    onViewEventDetail(meetingId, isProposal);
  };
  console.log(
    "overlapping :",
    overlapping,
    "numberof verlappingEvent:",
    meetingStart
  );

  return (
    <div
      className={clsx(
        "border rounded-[8px] p-2 absolute",
        "bg-gradient-to-b text-neutral min-h-[35px] hover:z-50",
        isExternalMeeting
          ? "border-success bg-success100 !text-success !cursor-default"
          : nonAdjustable
          ? "border-[transparent] from-gradientDangerStart to-gradientDangerEnd"
          : "border-info400 from-gradientInfoStart to-gradientInfoEnd",
        "flex w-full cursor-pointer",
        containerClassName
      )}
      style={{ height: chipHeight, top: postionTop }}
      onClick={handleEventDetail}
      id={`calendarMeeting${meetingId}`}
    >
      {meetingLength < 1 ? (
        <div className="flex items-start justify-between w-full ">
          <div className="w-[80%]">
            <p
              className={clsx(
                "text-sm",
                "overflow-hidden whitespace-nowrap text-ellipsis w-full"
              )}
            >
              {isExternalMeeting ? "External Meeting" : topic}
            </p>
            {meetingLength > 0.64 && (
              <>
                {overlapping ? (
                  <p className="text-[10px]">{"Multiple than one meetings"}</p>
                ) : (
                  <p className="text-[10px]">{meetingTime}</p>
                )}
              </>
            )}
          </div>

          {!isExternalMeeting && (
            <div className="w-5 h-5 rounded-full bg-neutral">
              {nonAdjustable ? (
                <ChevronDangerIcon
                  className="w-full h-full"
                  fill="transparent"
                />
              ) : (
                <ChevronIcon className="w-full h-full" fill="transparent" />
              )}
            </div>
          )}
        </div>
      ) : (
        <div className="flex flex-col justify-between w-full">
          <div className="flex gap-2">
            <div
              className={clsx(
                isExternalMeeting ? "w-[calc(100%-28px)]" : "w-full"
              )}
            >
              <p
                className={clsx(
                  "text-sm w-full leading-tight",
                  "overflow-hidden whitespace-nowrap text-ellipsis w-full"
                )}
              >
                {isExternalMeeting ? "External Meeting" : topic}
              </p>
              {overlapping ? (
                <p className="text-[10px]">{"More than one meetings"}</p>
              ) : (
                <p className="text-[10px] leading-none">{meetingTime}</p>
              )}
            </div>
          </div>
          {!isExternalMeeting && (
            <ParticipantsChip
              remainingCounter={remainingCounter}
              participants={participants}
              containerClassName="bg-neutral border-neutral"
            />
          )}
        </div>
      )}
      {isExternalMeeting && (
        <Tooltip
          anchorSelect={`#calendarMeeting${meetingId}`}
          content="Please check your external calendar"
          place="top-start"
          offset={4}
          className="!py-1 !px-2 !rounded-md !text-[10px] text-center"
          noArrow={true}
        />
      )}
    </div>
  );
};
