import { useMemo } from 'react';
import Select from 'react-select';
import clsx from 'clsx';
import { ReactComponent as PlusIcon } from '../../assets/icons/Plus.svg';
import { ReactComponent as MinusIcon } from '../../assets/icons/Minus.svg';
import { DAYS_OF_WEEK } from '../../utils/constants';

export const DaySelector = ({
	buttonType,
	name,
	selectedDays,
	availableDays,
	unavailableDays,
	hasError,
	error,
	onSelect,
	onAddException,
	onRemoveException,
}) => {
	const handleChange = ({ value }) => {
		let selected = [...selectedDays];
		selected.push(value);
		selected = selected.sort((a, b) => {
			if (a === '0') {
				return 1;
			} else if (b === '0') {
				return -1;
			} else {
				return a - b;
			}
		});

		onSelect(name, selected);
	};

	const avaialbleWeekdsy = useMemo(() => {
		const allowedDays = availableDays
			? DAYS_OF_WEEK.filter(({ value }) => availableDays.includes(value))
			: DAYS_OF_WEEK;
		if (!unavailableDays || !unavailableDays.length) {
			return allowedDays;
		}
		const daysToDisplay = allowedDays.filter(
			({ value }) => !unavailableDays.some((day) => day === value)
		);
		return daysToDisplay;
	}, [unavailableDays, availableDays]);

	return (
		<div className='flex items-center my-[10px]'>
			<h5 className='text-sm font-semibold text-gray500'>Apply to</h5>
			<div className=''>
				<Select
					value={null}
					placeholder='Select a day'
					onChange={handleChange}
					options={avaialbleWeekdsy}
					components={{ ClearIndicator: null, IndicatorSeparator: null }}
					isSearchable={false}
					menuPortalTarget={document.body}
					isOptionDisabled={({ value }) =>
						selectedDays?.some((day) => day === value)
					}
					classNames={{
						container: () => 'ml-3 mr-[5px] min-w-[140px]]',
						menuList: () => 'text-sm font-semibold',
						menuPortal: () => '!z-[3]',
						control: () =>
							clsx(
								'text-sm font-semibold text-gray500 !bg-[transparent]',
								hasError ? '!border-danger' : '!border-gray500'
							),
					}}
				/>
				{hasError && <p className='text-danger text-sm'>{error}</p>}
			</div>
			{buttonType === 'add' && (
				<button onClick={onAddException}>
					<PlusIcon />
				</button>
			)}
			{buttonType === 'remove' && (
				<button onClick={onRemoveException}>
					<MinusIcon />
				</button>
			)}
		</div>
	);
};
