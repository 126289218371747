import clsx from 'clsx';
import { ReactComponent as TickIcon } from '../../assets/icons/Tick.svg';

export const ActionSuccessModal = ({ message, onClose }) => {
	return (
		<div className=' font-semibold'>
			<div className={clsx('flex flex-col items-center', 'my-6')}>
				<div
					className={clsx(
						'flex items-center justify-center',
						'bg-[green] w-[70px] h-[70px] text-[white] rounded-full'
					)}
				>
					<TickIcon fill='transparent' />
				</div>
				<h3 className='text-2xl my-3 text-center'>{message}</h3>
				<button
					className={clsx(
						'flex items-center justify-center',
						'border border-gray500',
						'w-[280px] px-[25px] py-3 mx-auto bg-neutral rounded-full',
						'shadow-theme'
					)}
					type='button'
					onClick={onClose}
				>
					Close
				</button>
			</div>
		</div>
	);
};
