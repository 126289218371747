import { useEffect, useMemo } from "react";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { Checkbox, Input, Select, Textarea } from "../../theme";
import Separator from "../Separator";
import { Spinner } from "../Spinner";
import { DatePicker } from "../date-picker";
import { InvitableUsersSelection } from "../meeting-requests/InvitableUsersSelection";
import { MeetingRequestSuccess } from "../meeting-requests/MeetingRequestSuccess";
import { MeetingRequestError } from "../meeting-requests/MeetingRequestError";
import {
  addUpdateMeetingRequest,
  validateMeetingRequest,
  resetAddMeetingError,
  selectInvitableUsers,
} from "../../store/meetingRequests";
import {
  resetScheduledEventUpdateError,
  selectEventDetail,
  updateScheduledMeeting,
} from "../../store/scheduledEvents";
import { useLinkedMeetingApps } from "../../hooks/integrations";
import {
  convertHoursToMinutes,
  getReadableDateRange,
  parseAndFormatProvidedTime,
} from "../../utils/dateTime";
import {
  getInitialMeetingRequestData,
  meetingProposalFormSchema,
} from "../../utils/formsValidators";

import { ReactComponent as CalendarIcon } from "../../assets/icons/Calendar.svg";
import { ReactComponent as ClockIcon } from "../../assets/icons/time.svg";
import { ReactComponent as GroupIcon } from "../../assets/icons/Group.svg";
import { ReactComponent as AgendaIcon } from "../../assets/icons/Agenda.svg";
import { ReactComponent as PinIcon } from "../../assets/icons/pin.svg";
import { ReactComponent as PlusIcon } from "../../assets/icons/Plus.svg";
import { ReactComponent as LocationIcon } from "../../assets/icons/Location.svg";
import { extractAndParseValue } from "../../utils/dataParser";

export const CreateMeetingModal = ({
  existingMeetingData,
  actionLoading,
  actionSuccess,
  checkMeetingValidity,
  checkMeetingValidityError,
  actionError,
  onFormStatusChange,
  isUpdate,
  onClose,
  setIsActive
}) => {
  const userLinkedMeetingApps = useLinkedMeetingApps();
  const { invitableUsers } = useSelector(selectInvitableUsers);
  const dispatch = useDispatch();

  const {
		scheduledEventDetail,
	} = useSelector(selectEventDetail);

  const {
    values,
    errors,
    touched,
    dirty,
    handleChange,
    setFieldValue,
    handleBlur,
    submitForm,
  } = useFormik({
    // initialValues: getInitialMeetingRequestData(scheduledEventDetail),
    initialValues : isUpdate ?  {
      id: scheduledEventDetail?.id,
      topic: existingMeetingData?.topic,
      agenda: scheduledEventDetail?.agenda,
      platformId: scheduledEventDetail?.platformId,
      dateTimesToBeHeldStart: existingMeetingData?.start ? existingMeetingData?.start : new Date(
        Date.now() + 5 * 24 * 60 * 60 * 1000
      ).toISOString(), 
      dateTimesToBeHeldEnd: existingMeetingData?.end ? existingMeetingData?.end : new Date(
        Date.now() + 19 * 24 * 60 * 60 * 1000
      ).toISOString(),
      nonAdjustable: scheduledEventDetail?.nonAdjustable,
      minLength: scheduledEventDetail?.minLength,
      invitees: scheduledEventDetail?.participants,
      creationTime: new Date(Date.now()).toISOString(),
      hostPersonID: scheduledEventDetail?.hostPersonID,
      isProposal: existingMeetingData?.isProposal,
  } : getInitialMeetingRequestData(existingMeetingData),
    validationSchema: meetingProposalFormSchema,
    onSubmit: (values) => {
      onFormStatusChange(false);
      if (values.isProposal) {
        dispatch(validateMeetingRequest(values));
      } else {
        dispatch(updateScheduledMeeting(values));
      }
    },
  });

  useEffect(() => {
    if (scheduledEventDetail && isUpdate) {
      setFieldValue('id', scheduledEventDetail.id);
      setFieldValue('topic', existingMeetingData?.topic);
      setFieldValue('agenda', scheduledEventDetail.agenda);
      setFieldValue('platformId', (scheduledEventDetail.platformId).toString());
      setFieldValue('nonAdjustable', scheduledEventDetail.nonAdjustable);
      setFieldValue('minLength', Math.round(extractAndParseValue(
        existingMeetingData,
        "eventDuration",
        "30",
        convertHoursToMinutes
      )));
      setFieldValue('invitees', scheduledEventDetail?.participants.filter(user => user.personID)?.map(user => user.personID));
      setFieldValue('creationTime', new Date(Date.now()).toISOString());
      setFieldValue('hostPersonID', scheduledEventDetail.hostPersonID);
      setFieldValue('isProposal', existingMeetingData?.isProposal);
    }
  }, [scheduledEventDetail]);

  const onRangeChange = (range) => {
    const [start, end] = range;
    setFieldValue("dateTimesToBeHeldStart", start, true);
    setFieldValue("dateTimesToBeHeldEnd", end, true);
  };


  const onClearError = () => {
    dispatch(resetAddMeetingError());
    dispatch(resetScheduledEventUpdateError());
  };

  const meetingPlatformsList = useMemo(() => {
    const parsedPlatforms = userLinkedMeetingApps.map((platform) => ({
      label: platform.title,
      value: platform.id.toString(),
      isDisabled: !platform.enabled || !platform.isConnected,
    }));

    // Add Offline platform as well
    parsedPlatforms.unshift({
      label: "Offline",
      value: "0",
      isDisabled: false,
    });

    return parsedPlatforms;
  }, [userLinkedMeetingApps]);

  

  const { timeFrameDisplay, formatedRangeStart, formatedRangeEnd } =
    useMemo(() => {
      const timeFrameDisplay = getReadableDateRange(
        values.dateTimesToBeHeldStart,
        values.dateTimesToBeHeldEnd
      );
      const formatedRangeStart = parseAndFormatProvidedTime("DD/MM/YYYY")(
        values.dateTimesToBeHeldStart
      );
      const formatedRangeEnd = parseAndFormatProvidedTime("DD/MM/YYYY")(
        values.dateTimesToBeHeldEnd
      );

      return { timeFrameDisplay, formatedRangeStart, formatedRangeEnd };
    }, [values.dateTimesToBeHeldStart, values.dateTimesToBeHeldEnd]);

  useEffect(() => {
    // Send event when the form status chnages
    onFormStatusChange(dirty);
  }, [dirty]);



  useEffect(() => {
    if (checkMeetingValidity) {
      dispatch(addUpdateMeetingRequest(values));
      if(isUpdate) {
        setTimeout(() => {
          setIsActive(true)
        },2000)
      }
    }
  }, [checkMeetingValidity]);


  return (
    <div className={clsx("font-semibold mb-8 px-8")}>
      {!actionSuccess && !actionError && (
        <div>
          <p className="text-gray500 font-medium">Set up your meeting infos</p>
          <div className="mt-3">
            <div className="">
              <div className="flex items-center my-4">
                <p className="flex items-center gap-2 text-base font-light shrink-0">
                  <AgendaIcon fill="transparent" />
                  <span>Meeting title</span>
                </p>
                <Separator className="ml-4" />
              </div>
              <Input
                name="topic"
                placeholder="Meeting title"
                value={values.topic}
                className="mb-2 py-[9px] font-thin"
                onChange={handleChange}
                onBlur={handleBlur}
                hasError={!!(errors.topic && touched.topic)}
                error={errors.topic}
              />
            </div>
            <div className="">
              <div className="flex items-center mt-4">
                <p className="flex items-center gap-2 text-base font-light shrink-0">
                  <CalendarIcon fill="transparent" />
                  <span>Time Window</span>
                </p>
                <Separator className="ml-4" />
              </div>
              <DatePicker
                calendarTitle="Pick a day"
                highlightType="info"
                startDate={values.dateTimesToBeHeldStart}
                endDate={values.dateTimesToBeHeldEnd}
                showSplittedRange={true}
                formatedRangeStart={formatedRangeStart}
                formatedRangeEnd={formatedRangeEnd}
                pickerButtonClassName="my-4 px-3 py-2 text-sm"
                onRangeChange={onRangeChange}
              />
            </div>
            <div className="">
              <div className="flex items-center mt-4">
                <p className="flex items-center gap-2 text-base font-light shrink-0">
                  <ClockIcon fill="transparent" />
                  <span>Meeting Length</span>
                </p>
                <Separator className="ml-4" />
              </div>
              <Input
                name="minLength"
                placeholder="Minutes"
                value={values.minLength}
                type="number"
                className="mb-2 py-[9px] mt-4"
                onChange={handleChange}
                onBlur={handleBlur}
                hasError={!!(errors.minLength && touched.minLength)}
                error={errors.minLength}
              />
            </div>
            <div>
              <div className="flex items-center mt-4">
                <p className="flex items-center gap-2 text-base font-light">
                  <GroupIcon fill="transparent" />
                  <p className="whitespace-nowrap ">Internal Participants</p>
                </p>
                <Separator className="ml-4" />
              </div>
              <InvitableUsersSelection
                invitableUsers={invitableUsers}
                selectedUsers={values.invitees}
                fieldName="invitees"
                hasError={!!(errors.invitees && touched.invitees)}
                error={errors.invitees}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
              />
            </div>
            <div>
              <div className="flex items-center mt-4">
                <p className="flex items-center gap-2 text-base font-light">
                  <AgendaIcon fill="transparent" />
                  <span>Agenda</span>
                </p>
                <Separator className="ml-4" />
              </div>
              <Textarea
                name="agenda"
                placeholder="What's the agenda of the meeting?"
                value={values.agenda}
                onChange={handleChange}
                onBlur={handleBlur}
                containerClassName="mb-[18px]"
                className={clsx(
                  "mt-[9px] !text-sm font-thin",
                  !(errors.agenda && touched.agenda) && "!border-primary"
                )}
                rows={3}
                hasError={errors.agenda && touched.agenda}
                error={errors.agenda}
              />
            </div>
            <div className="">
              <div className="flex items-center mt-4">
                <p className="flex items-center gap-2 text-base font-light">
                  <LocationIcon fill="transparent" />
                  <span>Location</span>
                </p>
                <Separator className="ml-4" />
              </div>
              <Select
                name="platformId"
                placeholder="Meeting Platform"
                value={values.platformId}
                options={meetingPlatformsList}
                onChange={handleChange}
                onBlur={handleBlur}
                containerClassName="mt-4"
                controlClassName={clsx(
                  errors.platformId && touched.platformId
                    ? "!border-danger"
                    : "!border-primary"
                )}
                hasError={!!(errors.platformId && touched.platformId)}
                error={errors.platformId}
                errorClassName="text-xs font-semibold mt-2"
              />
            </div>

            <Separator className="my-5" />

            <div
              className={clsx(
                "py-6 flex items-center justify-center w-full",
                "border border-primary rounded-[10px] font-light"
              )}
            >
              <PinIcon fill="transparent" />
              <p className="ml-2 mr-4">Non-adjustable meeting</p>
              <Checkbox
                checked={values.nonAdjustable}
                onChange={(updatedValue) =>
                  setFieldValue("nonAdjustable", updatedValue, true)
                }
              />
            </div>

            <div className="mt-9">
              <button
                className={clsx(
                  "flex items-center justify-center w-full",
                  "border border-gray500",
                  "w-[280px] px-[25px] py-3 mx-auto bg-neutral rounded-full",
                  "shadow-theme"
                )}
                onClick={submitForm}
              >
                <PlusIcon className="mr-4" fill="transparent" />
                <span>Schedule meeting</span>
              </button>
            </div>
          </div>

          {actionLoading && (
            <div
              className={clsx(
                "absolute top-0 left-0",
                "flex flex-col items-center justify-center",
                "w-full h-full bg-[#b5b5b5b5] rounded-[10px]"
              )}
            >
              <Spinner className="!w-6 !h-6 mb-2" />
              <p>Loading...</p>
            </div>
          )}
        </div>
      )}
      {actionSuccess && (
        <MeetingRequestSuccess
          nonAdjustable={values.nonAdjustable}
          timeFrame={timeFrameDisplay}
          selectedInvitees={values.invitees}
          invitableUsers={invitableUsers}
          platformId={values.platformId}
          availablePlatforms={meetingPlatformsList}
          isUpdateAction={!!values.id}
          onClose={onClose}
        />
      )}
      {(actionError || checkMeetingValidityError) && (
        <MeetingRequestError
          actionError={actionError || checkMeetingValidityError}
          isUpdateAction={!!values.id}
          onClose={onClose}
          onClearError={onClearError}
        />
      )}
    </div>
  );
};
