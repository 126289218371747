import { useMemo } from "react";
import clsx from "clsx";
import ReactSelect, { components } from "react-select";
import Avatar from "../Avatar";
import { SelectedUsersDropdown } from "./SelectedUsersDropdown";

export const InvitableUsersSelection = ({
  fieldName,
  invitableUsers,
  selectedUsers,
  hasError,
  error,
  errorClassName,
  setFieldValue,
  handleBlur,
  placeholder,
}) => {
  const parsedInvitableUsers = useMemo(() => {
    return invitableUsers?.map((user) => ({
      firstName: user?.firstName,
      lastName: user?.lastName,
      userName: user?.userName,
      fullName: `${user?.firstName} ${user?.lastName} `,
      value: user?.id,
      label: user?.firstName,
    }));
  }, [invitableUsers]);

  const [parsedSelectedUsers, remaingCounter, allMappedUsers] = useMemo(() => {
    let usersMapped = selectedUsers
      ?.map((userId) => {
        const foundUser = invitableUsers?.find((item) => item?.id === userId);
        return foundUser;
      })
      .filter((user) => !!user);
    const allMappedUsers = usersMapped;
    let overflowUsers = 0;
    if (usersMapped?.length > 4) {
      overflowUsers = usersMapped.length - 3;
      usersMapped = usersMapped.slice(0, 3);
    }

    return [usersMapped, overflowUsers, allMappedUsers];
  }, [selectedUsers, invitableUsers]);
  const handleAddUser = () => {};
  const filterOptions = (option, searchValue) => {
    if (!searchValue) {
      return true;
    }
    const loweredCaseSearch = searchValue.toLowerCase();
    return Object.values(option.data).some((filedValue) => {
      return filedValue?.toLowerCase().startsWith(loweredCaseSearch);
    });
  };

  const handleSelection = (selectedValue) => {
    if (selectedUsers?.includes(selectedValue.value)) {
      return;
    }
    const updatedUsers = [...selectedUsers, selectedValue.value];
    setFieldValue(fieldName, updatedUsers, true);
  };

  const handleRemoveUser = (userId) => {
    if (!selectedUsers.includes(userId)) {
      return;
    }
    const updatedUsers = selectedUsers.filter(
      (selectedUserId) => selectedUserId !== userId
    );
    setFieldValue(fieldName, updatedUsers, true);
  };

  return (
    <div className="flex items-center gap-4  mt-4 mb-8 bg-netural">
      <div className="flex-grow w-full">
        <ReactSelect
          value={null}
          placeholder={`${
            placeholder === "Who is invited?" ? placeholder : "Who is invited?"
          }`}
          options={parsedInvitableUsers}
          components={{
            Option: InvitableUserOption,
            ClearIndicator: null,
            IndicatorSeparator: null,
          }}
          isOptionSelected={(option) => selectedUsers?.includes(option.value)}
          menuPortalTarget={document.body}
          filterOption={filterOptions}
          onChange={handleSelection}
          onBlur={handleBlur}
          classNames={{
            menuPortal: () => "!z-20",
            menuList: () => "text-sm font-semibold",
            control: () =>
              clsx(
                "text-xs font-medium !bg-[transparent] !min-h-[36px] !h-9 w-[172px]",
                hasError ? "!border-danger" : "!border-primary"
              ),
            valueContainer: () => "!pt-0",
            dropdownIndicator: () => "!py-0 !pl-0",
            option: () => "!p-0",
          }}
        />
        {hasError && (
          <p className={clsx("text-danger text-xs", errorClassName)}>{error}</p>
        )}
      </div>
      {placeholder && (
        <div className="">
          <button
            onClick={handleAddUser}
            className="p-2 border border-slate-400 text-xs font-medium rounded-md"
          >
            Add
          </button>
        </div>
      )}
      <div className="w-[130px] ">
        {selectedUsers?.length > 0 && (
          <SelectedUsersDropdown
            selectedUsers={allMappedUsers}
            parsedSelectedUsers={parsedSelectedUsers}
            remaingCounter={remaingCounter}
            onRemoveUser={handleRemoveUser}
          />
        )}
      </div>
    </div>
  );
};

const InvitableUserOption = (props) => {
  const { data, isSelected, isFocused } = props;
  return (
    <components.Option {...props}>
      <div
        className={clsx({
          "flex items-center  gap-1 px-2 py-1 text-xs my-1 select-none": true,
          "bg-info100 pointer-events-none": isSelected,
          "bg-info100": isFocused,
          "cursor-pointer hover:bg-info100": !isSelected,
        })}
      >
        <Avatar
          userId={data.value}
          firstName={data.firstName}
          lastName={data.lastName}
          profileById={true}
          className="w-6 h-6 pointer-events-none"
        />
        <div className="">
          <p className="text-primary">{`${data.firstName} ${data.lastName}`}</p>
          {/* <p className="text-[10px] text-gray500">{data.userName}</p> */}
          <p className="text-[10px] text-gray500 truncate max-w-[16ch]">
            {data.userName}
          </p>
        </div>
      </div>
    </components.Option>
  );
};
