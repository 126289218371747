import dayjs from "dayjs";

export const getLeftCursorSpace = (workDayStart, sliceStart) => {
  const [dayStartHours, dayStartMins] = workDayStart
    .split(":")
    .map((val) => parseInt(val, 10));
  const [sliceHours, sliceMins] = sliceStart
    .split(":")
    .map((val) => parseInt(val, 10));

  const hoursDiff = sliceHours - dayStartHours;
  const minsDiff = sliceMins - dayStartMins;

  return hoursDiff + minsDiff / 60;
};

export const getSliceFromValues = (
  workDayStart,
  sliceStartValue,
  sliceLength
) => {
  // Parse Start Slice
  const startHours = Math.floor(sliceStartValue);
  const startMins = Math.round((sliceStartValue - startHours) * 60);

  // Calculate End Slice
  const sliceHours = Math.floor(sliceLength);
  const sliceMins = Math.round((sliceLength - sliceHours) * 60);

  const [dayStartHours, dayStartMins] = workDayStart
    .split(":")
    .map((val) => parseInt(val, 10));

  // If Minutes are more than 59 - Increment the hours and restart the minutes
  let sliceStartHours = dayStartHours + startHours;
  let sliceStartMinutes = dayStartMins + startMins;

  if (sliceStartMinutes > 59) {
    sliceStartHours += 1;
    sliceStartMinutes = sliceStartMinutes % 60;
  }
  const startSlice = `${padZero(sliceStartHours)}:${padZero(
    sliceStartMinutes
  )}`;

  let sliceEndHours = dayStartHours + startHours + sliceHours;
  let sliceEndMinutes = dayStartMins + startMins + sliceMins;

  // If Minutes are more than 59 - Increment the hours and restart the minutes
  if (sliceEndMinutes > 59) {
    sliceEndHours += 1;
    sliceEndMinutes = sliceEndMinutes % 60;
  }

  // Slice End
  const endSlice = `${padZero(sliceEndHours)}:${padZero(sliceEndMinutes)}`;

  return [startSlice, endSlice];
};

export const convertToMinutes = (time) => {
  if (!time) {
    return 0;
  }
  const [hours, minutes] = time.split(":").map(Number);
  return hours * 60 + minutes;
};
export const convertHoursToMinutes = (hours) => {
  if (!hours) {
    return 0;
  }
  return hours * 60;
};

export const convertTimeToHours = (time) => {
  const totalMinutes = convertToMinutes(time);
  return parseFloat(totalMinutes / 60)
    .toFixed(2)
    .replace(".00", "");
};

export const formatTime = (minutes) => {
  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;
  return `${padZero(hours)}:${padZero(mins)}`;
};

export const minutesToLongTime = (minutes) => {
  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;
  return `${padZero(hours)}:${padZero(mins)}:00`;
};

// Helper function to pad single digits with leading zeros
const padZero = (number) => {
  return number.toString().padStart(2, "0");
};

const getHourLength = (start, end) => {
  const timeSliceLengthInMinutes = end - start;
  return timeSliceLengthInMinutes / 60;
};

export const getHoursDifferenceBetweenTimes = (start, end) => {
  const startMinutes = convertToMinutes(start);
  const endMinutes = convertToMinutes(end);
  return getHourLength(startMinutes, endMinutes);
};

export const getMergedTimeSlices = (slices, existingSlice) => {
  let newStart = convertToMinutes(existingSlice.start);
  let newEnd = convertToMinutes(existingSlice.end);

  const mergedSlices = [];
  let mergedSliceAdded = false;

  for (let i = 0; i < slices.length; i++) {
    const slice = slices[i];
    const start = convertToMinutes(slice.start);
    const end = convertToMinutes(slice.end);

    if (newStart <= end && newEnd >= start) {
      // New slice overlaps or connects with an existing slice
      newStart = Math.min(newStart, start);
      newEnd = Math.max(newEnd, end);
      mergedSliceAdded = true;
    } else {
      // No overlap or connection, add the existing slice to the result
      mergedSlices.push(slice);
    }
  }

  if (mergedSliceAdded) {
    // Add the merged slice to the result
    const formatedStart = formatTime(newStart);
    const formatedEnd = formatTime(newEnd);
    mergedSlices.push({
      id: `${formatedStart}_${formatedEnd}`,
      start: formatedStart,
      end: formatedEnd,
      timeSliceLength: getHourLength(newStart, newEnd),
    });
  } else {
    // No overlap or connection found, add the new slice to the result
    mergedSlices.push(existingSlice);
  }

  return mergedSlices;
};

export const getHoursBetween = (startTime, endTime) => {
  const start = new Date(`2000-01-01T${startTime}`);
  const end = new Date(`2000-01-01T${endTime}`);
  const result = [];

  // Handle the first hour separately if it has minutes
  if (start.getMinutes() > 0) {
    const minutes = start.getMinutes();
    const hours = start.getHours() + 1;
    const formattedStart = startTime;
    const formattedEnd = `${padZero(hours)}:00`;
    const hour = padZero(start.getHours());

    result.push({
      id: `${formattedStart}_${formattedEnd}`,
      start: formattedStart,
      end: formattedEnd,
      minutes: minutes,
      minutesDisplay: formattedStart,
      timeSliceLength: (60 - minutes) / 60,
      hour,
    });

    start.setMinutes(0);
    start.setHours(start.getHours() + 1);
  }

  while (end.getHours() - start.getHours() > 0) {
    const hour = start.getHours();
    const formattedStart = `${padZero(hour)}:00`;
    const formattedEnd = `${padZero(hour + 1)}:00`;

    result.push({
      id: `${formattedStart}_${formattedEnd}`,
      start: formattedStart,
      end: formattedEnd,
      hour: padZero(hour),
      timeSliceLength: 1,
      minutesDisplay: null,
      minutes: 0,
    });

    start.setHours(hour + 1);
  }

  // Handle the last hour separately if it has minutes
  if (end.getMinutes() > 0) {
    const minutes = end.getMinutes();
    const formattedStart = `${padZero(end.getHours())}:00`;
    const formattedEnd = endTime;

    result.push({
      id: `${formattedStart}_${formattedEnd}`,
      start: formattedStart,
      end: formattedEnd,
      hour: padZero(end.getHours()),
      timeSliceLength: minutes / 60,
      minutesDisplay: formattedEnd,
    });
  }

  return result;
};

export const parseTimeSlotsForRangeSlots = (timeSlots) => {
  return timeSlots.map((timeSlotItem) => {
    const startTime = convertToMinutes(timeSlotItem.start);
    const endTime = convertToMinutes(timeSlotItem.end);

    return {
      id: `${timeSlotItem.start}_${timeSlotItem.end}`,
      ...timeSlotItem,
      timeSliceLength: getHourLength(startTime, endTime),
    };
  });
};

export const hasMinDifferenceBetweenTimes = (start, end) => {
  const startMinutes = convertToMinutes(start);
  const endMinutes = convertToMinutes(end);
  const hoursBetween = getHourLength(startMinutes, endMinutes);
  return hoursBetween >= 1;
};

export const removeSecondsFromTime = (time) => {
  return time.slice(0, -3);
};

export const parseAbsencesData = (absencesList) => {
  const parsedAbsences = absencesList
    .map((item) => {
      const { id, motivation, fromDate, toDate, absenceTimeString } = item;

      const fromDateTime = dayjs(fromDate);
      const toDateTime = dayjs(toDate);

      const [startTime, endTime] = absenceTimeString.split("T");

      // Date display
      let dateDisplay;
      const sameDay = fromDateTime.isSame(toDateTime, "date");
      const toDateString = toDateTime.format("DD/MM/YYYY");
      if (sameDay) {
        dateDisplay = toDateString;
      } else {
        const fromDateString = fromDateTime.format("DD/MM");
        dateDisplay = `${fromDateString} - ${toDateString}`;
      }

      // Check if both times are equal to determine if it's an all-day event
      const isAllDay = startTime === "00:00" && endTime === "23:59";

      return {
        id,
        motivation,
        fromDate,
        toDate,
        dateDisplay,
        fromTime: startTime,
        toTime: endTime,
        timeDisplay: `${startTime} - ${endTime}`,
        isAllDay,
      };
    })
    .sort((a, b) => {
      const dateComparison = a.fromDate.localeCompare(b.fromDate);
      if (dateComparison !== 0) {
        return dateComparison;
      }
      // If the fromDate is the same, compare the fromTime
      return a.fromTime.localeCompare(b.fromTime);
    });

  return parsedAbsences;
};

export const getReadableDateRange = (startDate, endDate) => {
  const start = dayjs(startDate);
  const end = dayjs(endDate);
  
  const formattedEnd = end.format("MMM DD, YYYY");
  if (start.isSame(end, "date")) {
    return formattedEnd;
  }

  return `${start.format("MMM DD")} - ${formattedEnd}`;
};

export const getCurrentDateTime = (format) => {
  return dayjs().format(format);
};
export const getStartOfDateTime = (currentDate, startOf, outputFormat) => {
	return dayjs(currentDate).startOf(startOf).format(outputFormat);
};
export const getEndOfDateTime = (currentDate, endOf, outputFormat) => {
	return dayjs(currentDate).endOf(endOf).format(outputFormat);
};
// export const getStartOfDateTime = (
//   currentDate,
//   startOf,
//   outputFormat,
//   userTimezone
// ) => {
//   return dayjs
//     .utc(currentDate)
//     .tz(userTimezone)
//     .startOf(startOf)
//     .format(outputFormat);
// };

// export const getEndOfDateTime = (
//   currentDate,
//   endOf,
//   outputFormat,
//   userTimezone
// ) => {
//   return dayjs
//     .utc(currentDate)
//     .tz(userTimezone)
//     .endOf(endOf)
//     .format(outputFormat);
// };
export const parseAndFormatProvidedTime =
  (format, convertTimezone) => (date) => {
    if (convertTimezone) {
      return dayjs.utc(date).tz(convertTimezone).format(format);
    }
    return dayjs(date).format(format);
  };

export const getHumanizedDifference = (start, end) => {
  const startDateTime = dayjs(start);
  const endDateTime = dayjs(end);
  const diffInMinutes = endDateTime.diff(startDateTime, "minute");
  let humanizedDifference = "";
  const hours = Math.floor(diffInMinutes / 60);
  const minutes = diffInMinutes % 60;
  if (hours > 0) {
    humanizedDifference += `${hours} hour${hours > 1 ? "s" : ""}`;
  }
  if (minutes > 0) {
    if (humanizedDifference !== "") {
      humanizedDifference += " ";
    }
    humanizedDifference += `${minutes} minute${minutes > 1 ? "s" : ""}`;
  }

  return humanizedDifference;
};

export const convertDurationToHumanReadable = (durationString) => {
  const [hours, minutes, seconds] = durationString.split(":").map(Number);

  if (isNaN(hours) || isNaN(minutes) || isNaN(seconds)) {
    return "Invalid duration format";
  }

  let humanReadable = "";

  if (hours > 0) {
    humanReadable += hours === 1 ? "1 hour" : `${hours} hours`;
  }

  if (minutes > 0) {
    if (humanReadable.length > 0) {
      humanReadable += " ";
    }
    humanReadable += minutes === 1 ? "1 minute" : `${minutes} minutes`;
  }

  if (hours === 0 && minutes === 0 && seconds > 0) {
    if (humanReadable.length > 0) {
      humanReadable += " ";
    }
    humanReadable += seconds === 1 ? "1 second" : `${seconds} seconds`;
  }

  return humanReadable;
};
