import {
	createSlice,
	createAsyncThunk,
	createSelector,
	isAnyOf,
} from '@reduxjs/toolkit';
import {
	deleteAuthTokenLocally,
	getStoredAuthToken,
	saveAuthTokenLocally,
} from '../utils/localStorage';
import axiosClient from '../utils/axiosClient';
import {
	FETCH_MY_PROFILE_API,
	GET_MY_MEETING_PREFERENCE,
	GET_MY_PROFILE_IMAGE_API,
	LOGIN_API,
	RECOVER_PASSWORD_API,
} from '../utils/apiRoutes';
import { resetScheduledEventsState } from './scheduledEvents';
import { resetMeetingRequestsState } from './meetingRequests';
import { resetConfigsState } from './configs';
import { resetAbsencesState } from './absences';
import { resetNotificationsState } from './notifications';

const initialState = {
	currentUser: null,
	authToken: getStoredAuthToken(),
	isAuthenticating: true,
	loginLoading: false,
	loginError: null,
	passwordRecoveryLoading: false,
	passwordRecoveryExpires: null,
	myMeetingPreferences: null,
};

export const authenticateUser = createAsyncThunk(
	'user/authenticateUser',
	async (_, { getState, fulfillWithValue, rejectWithValue }) => {
		const { serverUrl } = getState().configs;
		const { authToken } = getState().user;
		if (!authToken) {
			return rejectWithValue();
		}

		const { data: result } = await axiosClient.get(
			`${serverUrl}/${FETCH_MY_PROFILE_API}`
		);
		return fulfillWithValue(result);
	}
);

export const login = createAsyncThunk(
	'user/login',
	async (data, { dispatch, getState }) => {
		const { serverUrl } = getState().configs;
		const { data: result } = await axiosClient.post(
			`${serverUrl}/${LOGIN_API}`,
			data
		);
		dispatch(setAuthToken(result.token));
		saveAuthTokenLocally(result.token);

		dispatch(
			setAuthData({
				userFirstName: result.userFirstName,
				userLastName: result.userLastName,
				userName: result.userName,
				userId: result.userId,
				userRoleName: result.userRoleName,
				phoneNumber: result.phoneNumber,
				linkedInfrastructureIds: result.linkedInfrastructureIds,
				linkedVideoConferencingPlatformIds:
					result.linkedVideoConferencingPlatformIds,
				enabledMessageTypeIdsForNotifications:
					result.enabledMessageTypeIdsForNotifications,
				timeZone: result.timeZone,
			})
		);
		return null;
	}
);

export const fetchMyProfilePhoto = createAsyncThunk(
	'user/fetchMyProfilePhoto',
	async (_, { dispatch, getState }) => {
		const { serverUrl } = getState().configs;

		const { data: result } = await axiosClient.get(
			`${serverUrl}/${GET_MY_PROFILE_IMAGE_API}`,
			{
				responseType: 'blob',
			}
		);
		if (result.size > 0 && result.type.startsWith('image')) {
			const imageUrl = URL.createObjectURL(result);
			dispatch(setProfilePhoto(imageUrl));
		}
	}
);

export const fetchMyMeetingPreferences = createAsyncThunk(
	'user/fetchMyMeetingPreferences',
	async (_, { getState }) => {
		const { serverUrl } = getState().configs;
		const { data: result } = await axiosClient.get(
			`${serverUrl}/${GET_MY_MEETING_PREFERENCE}`
		);
		return result;
	}
);

export const passwordRecovery = createAsyncThunk(
	'user/passwordRecovery',
	async (data, { getState, fulfillWithValue }) => {
		const { serverUrl } = getState().configs;
		await axiosClient.get(
			`${serverUrl}/${RECOVER_PASSWORD_API}?email=${data.username}`
		);

		return fulfillWithValue(null);
	}
);

export const logout = createAsyncThunk(
	'user/logout',
	async (_, { dispatch }) => {
		deleteAuthTokenLocally();
		dispatch(resetUserState());
		dispatch(resetScheduledEventsState());
		dispatch(resetMeetingRequestsState());
		dispatch(resetConfigsState());
		dispatch(resetAbsencesState());
		dispatch(resetNotificationsState());
	}
);

const slice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setAuthData: (state, { payload }) => {
			state.isAuthenticating = false;
			state.currentUser = !payload
				? payload
				: { ...state.currentUser, ...payload };
		},
		setProfilePhoto: (state, { payload }) => {
			state.currentUser = state.currentUser && {
				...state.currentUser,
				profilePhoto: payload,
			};
		},
		setAuthToken: (state, { payload }) => {
			state.authToken = payload;
		},
		resetLoginError: (state) => {
			state.loginError = null;
		},
		resetRecoveryExpirationTimout: (state) => {
			state.passwordRecoveryExpires = null;
		},
		resetUserState: (state) => {
			state.authToken = null;
			state.currentUser = null;
			state.myMeetingPreferences = null;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(authenticateUser.pending, (state) => {
			state.currentUser = null;
			state.isAuthenticating = true;
		});
		builder.addCase(authenticateUser.fulfilled, (state, { payload }) => {
			state.currentUser = state.currentUser
				? { ...state.currentUser, ...payload }
				: payload;
			state.isAuthenticating = false;
		});
		builder.addCase(authenticateUser.rejected, (state) => {
			state.currentUser = null;
			state.isAuthenticating = false;
			state.authToken = null;
		});
		builder.addCase(login.pending, (state) => {
			state.loginError = null;
			state.loginLoading = true;
		});
		builder.addCase(login.rejected, (state) => {
			state.loginError =
				"This didn't work, please try again with valid credentials";
			state.loginLoading = false;
		});
		builder.addCase(login.fulfilled, (state) => {
			state.loginLoading = false;
		});
		builder.addCase(
			fetchMyMeetingPreferences.fulfilled,
			(state, { payload }) => {
				state.myMeetingPreferences = payload;
			}
		);
		builder.addCase(passwordRecovery.pending, (state) => {
			state.passwordRecoveryLoading = true;
			state.passwordRecoveryExpires = null;
		});
		builder.addMatcher(
			isAnyOf(passwordRecovery.fulfilled, passwordRecovery.rejected),
			(state) => {
				state.passwordRecoveryLoading = false;
				state.passwordRecoveryExpires = Date.now() + 5 * 1000 * 60;
			}
		);
	},
});

// ACTIONS
export const {
	setAuthData,
	setProfilePhoto,
	setAuthToken,
	resetLoginError,
	resetRecoveryExpirationTimout,
	resetUserState,
} = slice.actions;

// SELECTORS
const selectUserData = (state) => {
	return state.user;
};

export const selectUserAuthData = createSelector(selectUserData, (data) => ({
	currentUser: data.currentUser,
	authToken: data.authToken,
	isAuthenticating: data.isAuthenticating,
}));

export const selectLoggedInUser = createSelector(
	selectUserData,
	(data) => data.currentUser
);

export const selectLoginRequestData = createSelector(
	selectUserData,
	(data) => ({
		loginLoading: data.loginLoading,
		loginError: data.loginError,
	})
);

export const selectPasswordRecoveryRequestData = createSelector(
	selectUserData,
	(data) => ({
		loading: data.passwordRecoveryLoading,
		expiresAt: data.passwordRecoveryExpires,
	})
);

export const selectMyMeetingPreferences = createSelector(
	selectUserData,
	(data) => data.myMeetingPreferences
);

export const selectLinkedMeetingPlatforms = createSelector(
	selectUserData,
	(data) => {
		return data.currentUser?.linkedVideoConferencingPlatformIds || '';
	}
);

export default slice.reducer;
