import { useEffect } from 'react';
import clsx from 'clsx';
import { useFormik } from 'formik';
import { serverConfigFormSchema } from '../../utils/formsValidators';

export const ServerConfigModal = ({ isOpen, userServers, onSave }) => {
	const { isValid, values, handleSubmit, handleChange, setFieldValue } =
		useFormik({
			initialValues: {
				url: '',
			},
			validationSchema: serverConfigFormSchema,
			onSubmit: (values) => {
				onSave(values.url);
			},
		});

	useEffect(() => {
		setFieldValue('url', '');
	}, [isOpen]);

	return (
		<div className='w-[350px]'>
			<h4 className='text-center font-semibold'>Select Server</h4>
			<p className='text-center text-xs'>
				Please select the server to which you want to log in.
			</p>
			<form onSubmit={handleSubmit}>
				<ul className='flex flex-col gap-3 mt-4'>
					{userServers.map((server, index) => (
						<li key={index}>
							<input
								type='radio'
								id={server.url}
								name='url'
								value={server.url}
								className='hidden peer'
								onChange={handleChange}
								checked={values.url === server.url}
							/>
							<label
								htmlFor={server.url}
								className={clsx(
									'inline-flex items-center justify-between w-full p-4',
									'text-gray500 border rounded-md cursor-pointer',
									'peer-checked:border-primary peer-checked:border-2 peer-checked:text-primary hover:text-primary'
								)}
							>
								<div className='block'>
									<div className='w-full text-sm font-semibold'>
										{server.name}
									</div>
									<div className='w-full text-xs'>{server.url}</div>
								</div>
							</label>
						</li>
					))}
				</ul>

				<div className='flex items-center justify-end gap-1 mt-4'>
					<button
						className={clsx(
							'py-2 px-4 rounded-[20px]',
							'text-neutral font-semibold text-xs',
							!isValid ? 'bg-gray500' : 'bg-primary'
						)}
						type='submit'
						disabled={!isValid}
					>
						Save Changes
					</button>
				</div>
			</form>
		</div>
	);
};
