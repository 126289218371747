import clsx from 'clsx';

export const Textarea = ({
	name,
	label,
	value,
	placeholder,
	onChange,
	onBlur,
	hasError,
	error,
	className,
	containerClassName,
	rows,
	disabled,
}) => {
	return (
		<div className={clsx('mt-4', containerClassName)}>
			{label && <label className='text-sm font-semibold'>{label}</label>}
			<textarea
				className={clsx(
					'border',
					'w-full p-[12px] rounded-[6px] bg-neutral',
					'text-xs resize-none',
					hasError ? '!border-danger' : 'border-primary',
					className
				)}
				name={name}
				onChange={onChange}
				onBlur={onBlur}
				value={value}
				placeholder={placeholder}
				rows={rows || '8'}
				disabled={disabled}
			></textarea>
			{hasError && (
				<span className='text-danger text-xs mt-[2px]'>{error}</span>
			)}
		</div>
	);
};
