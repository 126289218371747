import {
  createSlice,
  createSelector,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axiosClient from "../utils/axiosClient";
import {
  RESET_MY_PASSWORD_API,
  SET_MY_PROFILE_IMAGE_API,
  UPDATE_MY_MEETING_PREFERENCE,
  UPDATE_MY_PROFILE_API,
  UPDATE_MY_TIMEZONE_API,
} from "../utils/apiRoutes";
import {
  authenticateUser,
  fetchMyMeetingPreferences,
  fetchMyProfilePhoto,
} from "./user";
import {
  parseMeetingPreferencesDataForApi,
  parseProfileFormsDataForApi,
  queryfyUrl,
} from "../utils/dataParser";

const initialState = {
  profileFormSubmitting: false,
  timezoneFormSubmitting: false,
  securityFromSubmitting: false,
  profilePicUploading: false,
  preferencesFormSubmitting: false,
  passwordLastUpdated: null,
};

export const uploadProfilePic = createAsyncThunk(
  "configs/uploadProfilePic",
  async (profilePicFile, { getState, dispatch }) => {
    const { serverUrl } = getState().configs;
    try {
      dispatch(
        updateFromSubmittingStatus({ key: "profilePicUploading", value: true })
      );
      const formData = new FormData();
      if (profilePicFile) {
        formData.append("file", profilePicFile);
      }
      await axiosClient.post(
        `${serverUrl}/${SET_MY_PROFILE_IMAGE_API}`,
        formData
      );
      toast.success("Profile pic updated successfully.");

      dispatch(fetchMyProfilePhoto());
    } catch (error) {
      console.log("PROFILE PIC UPLOAD FAILED::", error.response);
      toast.error("Failed to save profile photo, please retry");
    } finally {
      dispatch(
        updateFromSubmittingStatus({
          key: "profilePicUploading",
          value: false,
        })
      );
    }
  }
);

export const updateTimezone = createAsyncThunk(
  "configs/updateTimezone",
  async (updatedTimezone, { getState, dispatch }) => {
    const { serverUrl } = getState().configs;
    try {
      dispatch(
        updateFromSubmittingStatus({
          key: "timezoneFormSubmitting",
          value: true,
        })
      );

      const url = queryfyUrl(`${serverUrl}/${UPDATE_MY_TIMEZONE_API}`, {
        ianaTimeZoneId: updatedTimezone,
        permanent: true,
      });
      await axiosClient.get(url);
      toast.success("Timezone updated successfully.");
    } catch (error) {
      const parsedErrors = Object.values(error.response.data?.errors);
      console.log("TIMEZONE DATA UPDATE FAILED::", parsedErrors);
      toast.error(
        parsedErrors || "There was an error with your timezone update."
      );
    } finally {
      dispatch(
        updateFromSubmittingStatus({
          key: "timezoneFormSubmitting",
          value: false,
        })
      );
    }
  }
);

export const updateProfileData = createAsyncThunk(
  "configs/updateProfileData",
  async ({ profileData, notificationsData }, { getState, dispatch }) => {
    const { serverUrl } = getState().configs;
    const { currentUser } = getState().user;
    try {
      dispatch(
        updateFromSubmittingStatus({
          key: "profileFormSubmitting",
          value: true,
        })
      );
      const parsedData = parseProfileFormsDataForApi(
        currentUser,
        profileData,
        notificationsData
      );
      await axiosClient.post(
        `${serverUrl}/${UPDATE_MY_PROFILE_API}`,
        parsedData
      );
      toast.success("Profile data updated successfully.");
      dispatch(authenticateUser());
    } catch (error) {
    //   const parsedErrors = Object.values(error.response.data?.errors);
      const parseErrorsTitle = error.response?.data?.title;
      toast.error(
        parseErrorsTitle || "There was an error with your profile update."
      );
    } finally {
      dispatch(
        updateFromSubmittingStatus({
          key: "profileFormSubmitting",
          value: false,
        })
      );
    }
  }
);

export const updatePassword = createAsyncThunk(
  "configs/updatePassword",
  async (data, { getState, dispatch }) => {
    const { serverUrl } = getState().configs;
    try {
      dispatch(
        updateFromSubmittingStatus({
          key: "securityFromSubmitting",
          value: true,
        })
      );
      const url = queryfyUrl(`${serverUrl}/${RESET_MY_PASSWORD_API}`, data);
      await axiosClient.post(url);
      toast.success("Password has been updated successfully.");
      dispatch(setPasswordLastUpdateTime());
    } catch (error) {
      console.log("PROFILE DATA UPDATE FAILED::", error.response);
      toast.error(
        error.response.data || "There was an error with your password update."
      );
    } finally {
      dispatch(
        updateFromSubmittingStatus({
          key: "securityFromSubmitting",
          value: false,
        })
      );
    }
  }
);

export const updateMyMeetingPreferences = createAsyncThunk(
  "configs/updateMyMeetingPreferences",
  async (data, { getState, dispatch }) => {
    const { serverUrl } = getState().configs;
    const { myMeetingPreferences, currentUser } = getState().user;
    try {
      dispatch(
        updateFromSubmittingStatus({
          key: "preferencesFormSubmitting",
          value: true,
        })
      );

      const parsedData = parseMeetingPreferencesDataForApi(
        myMeetingPreferences,
        data,
        currentUser
      );
      if (myMeetingPreferences) {
        await axiosClient.put(
          `${serverUrl}/${UPDATE_MY_MEETING_PREFERENCE}`,
          parsedData
        );
        toast.success("Meeting preferences updated successfully.");
      } else {
        await axiosClient.post(
          `${serverUrl}/${UPDATE_MY_MEETING_PREFERENCE}`,
          parsedData
        );
        toast.success("Meeting preferences added successfully.");
      }
      dispatch(fetchMyMeetingPreferences());
    } catch (error) {
      const parsedErrors = Object.values(error.response.data?.errors);
      console.log("MEETING PREFERENCES UPDATE FAILED::", parsedErrors);
      toast.error(
        parsedErrors ||
          "There was an error with your meeting preferences update."
      );
    } finally {
      dispatch(
        updateFromSubmittingStatus({
          key: "preferencesFormSubmitting",
          value: false,
        })
      );
    }
  }
);

const slice = createSlice({
  name: "settingsForms",
  initialState,
  reducers: {
    updateFromSubmittingStatus: (state, { payload }) => {
      state[payload.key] = payload.value;
    },
    resetSuccessfullFroms: (state) => {
      state.successfulForms = [];
    },
    setPasswordLastUpdateTime: (state) => {
      state.passwordLastUpdated = Date.now();
    },
  },
});

// ACTIONS
export const { updateFromSubmittingStatus, setPasswordLastUpdateTime } =
  slice.actions;

// SELECTORS
const selectSettingsFromsData = (state) => {
  return state.settingsForms;
};

export const selectIfAllFormsDone = createSelector(
  selectSettingsFromsData,
  ({
    profileFormSubmitting,
    timezoneFormSubmitting,
    securityFromSubmitting,
    profilePicUploading,
    preferencesFormSubmitting,
  }) => {
    return !(
      profileFormSubmitting ||
      timezoneFormSubmitting ||
      securityFromSubmitting ||
      preferencesFormSubmitting ||
      profilePicUploading
    );
  }
);

export const selectPasswordLastUpdated = createSelector(
  selectSettingsFromsData,
  ({ passwordLastUpdated }) => passwordLastUpdated
);

export default slice.reducer;
