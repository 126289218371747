import logo from '../../assets/images/logo.png';

export const AuthLoader = () => {
	return (
		<div className='h-screen flex items-center justify-center'>
			<div className='w-[500px] px-[52px]'>
				<img
					src={logo}
					className='w-[313px] mb-[16px] mx-auto'
					alt='Zelvor Logo'
				/>
				<h3 className='text-center mt-8 text-lg font-semibold text-primary'>
					Authenticating...
				</h3>
			</div>
		</div>
	);
};
