import { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import dayjs from 'dayjs';
import * as signalR from '@microsoft/signalr';
import { Sidebar } from './Sidebar';
import { Header } from './Header';
import {
	fetchAllCalendarPlatforms,
	fetchAllMeetingPlatforms,
	fetchAllNotificationsTypes,
	fetchTimezones,
	selectServerUrl,
} from '../../store/configs';
import {
	fetchMyMeetingPreferences,
	fetchMyProfilePhoto,
	selectUserAuthData,
} from '../../store/user';
import { fetchLatestNotification } from '../../store/notifications';
import { MESSAGING_HUB_URL } from '../../utils/apiRoutes';

export const AdminContainer = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const serverUrl = useSelector(selectServerUrl);
	const { authToken, currentUser } = useSelector(selectUserAuthData);
	const [timezoneMismatchError, setTimezoneMismatchError] = useState('');

	const onGoToProfileSettings = () => {
		navigate('/admin/settings?section=profile');
	};

	const onHideTimezoneError = () => {
		setTimezoneMismatchError('');
	};

	useEffect(() => {
		dispatch(fetchTimezones());
		dispatch(fetchMyProfilePhoto());
		dispatch(fetchAllMeetingPlatforms());
		dispatch(fetchAllCalendarPlatforms());
		dispatch(fetchAllNotificationsTypes());
		dispatch(fetchMyMeetingPreferences());
	}, []);

	useEffect(() => {
		if (authToken) {
			const hubConnection = new signalR.HubConnectionBuilder()
				.withUrl(`${serverUrl}/${MESSAGING_HUB_URL}`, {
					withCredentials: false,
					accessTokenFactory: () => authToken,
				})
				.build();

			hubConnection
				.start()
				.then((_) => {
					console.log('Notif Listening Started');
				})
				.catch((err) => {
					console.log('Notif Listening ERRROR', err);
				});

			hubConnection.on('ReceiveMessage', () => {
				dispatch(fetchLatestNotification());
			});

			return () => {
				hubConnection
					.stop()
					.then((_) => {
						console.log('Notif Listening Stopped:');
					})
					.catch();
			};
		}
	}, [authToken]);

	useEffect(() => {
		const systemTimezone = dayjs.tz.guess();
		if (!currentUser?.timeZone) {
			setTimezoneMismatchError('Please set the timezone in profile settings.');
		} else if (systemTimezone !== currentUser.timeZone) {
			setTimezoneMismatchError(
				'Your selected timezone is different from the system timezone.'
			);
		}
	}, [currentUser?.timeZone]);

	return (
		<div className='h-screen flex'>
			{/* <Sidebar /> */}
			<div className='w-full pt-8 overflow-y-scroll'>
				{/* <Header /> */}
				<div className=''>
					<Outlet />
				</div>
			</div>
		</div>
	);
};
