import { useEffect, useState } from 'react';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { debounce } from 'lodash';
import { MonthGrid } from './MonthGrid';
import { useGetWorkingDays } from '../../hooks/workingHours';
import { ReactComponent as ChevronIcon } from '../../assets/icons/Chevron.svg';

export const DatePickerCalendar = ({
	calendarTitle,
	highlightType,
	startDate,
	endDate,
	today,
	showEndDateInitial,
	toggleDatePicker,
	onRangeChange,
	mepa,
}) => {
	const [activeMonth, setActiveMonth] = useState(
		dayjs(showEndDateInitial ? endDate : startDate)
	);
	const [currentMonthDisplay, setCurrentMonthDisplay] = useState('');
	const [rangeStart, setRangeStart] = useState(dayjs(startDate));
	const [rangeEnd, setRangeEnd] = useState(dayjs(endDate));

	const workingDays = useGetWorkingDays();

	const onSwitchMonth = (action) => {
		if (activeMonth.isSame(today, 'month') && action === 'prev') {
			return;
		}
		let currentMonth = activeMonth;
		if (action === 'next') {
			currentMonth = currentMonth.add(1, 'month');
		} else {
			currentMonth = currentMonth.subtract(1, 'month');
		}
		setActiveMonth(currentMonth);
	};

	const handleRangeUpdate = (day) => {
		if (showEndDateInitial) {
			if (!rangeEnd || day.isAfter(rangeStart, 'day')) {
				setRangeEnd(day);
				onRangeChange(rangeStart, day);
			}
		} else {
			if (!rangeEnd) {
				setRangeEnd(day);
				onRangeChange(rangeStart, day);
			} else {
				setRangeStart(day);
				setRangeEnd(null);
			}
		}
	};

	const handleWheel = debounce((e) => {
		if (!e.deltaY) {
			return;
		}
		onSwitchMonth(e.deltaY > 0 ? 'next' : 'prev');
	}, 50);

	useEffect(() => {
		setCurrentMonthDisplay(activeMonth.format('MMM YYYY'));
	}, [activeMonth]);

	useEffect(() => {
		setRangeStart(dayjs(startDate));
		setRangeEnd(endDate ? dayjs(endDate) : null);
	}, [startDate, endDate]);

	return (
		<div
			className={clsx(
				'fixed left-0 top-0 bg-[#0000004a] z-[10] overflow-hidden',
				'w-full h-full flex items-center justify-center'
			)}
			onClick={toggleDatePicker}
		>
			<div
				className={clsx(
					'bg-neutral border border-gray500 shadow-theme rounded-[12px]',
					'w-[340px]'
				)}
				onClick={(e) => e.stopPropagation()}
			>
				<p
					className={clsx(
						'text-base font-smibold text-gray500',
						'pl-9 py-3',
						'border-b border-gray500'
					)}
				>
					{calendarTitle}
				</p>
				<div className='mx-9 my-5 select-none' onWheel={handleWheel}>
					<div className='flex items-center justify-between text-primary mb-8'>
						<button
							onClick={() => onSwitchMonth('prev')}
							disabled={today.isSame(activeMonth, 'month')}
						>
							<ChevronIcon
								className={clsx(
									'rotate-180',
									today.isSame(activeMonth, 'month')
										? 'text-gray500'
										: 'text-primary'
								)}
								fill='transparent'
							/>
						</button>
						<p className='text-base font-semibold'>{currentMonthDisplay}</p>
						<button onClick={() => onSwitchMonth('next')}>
							<ChevronIcon className='text-primary' fill='transparent' />
						</button>
					</div>
					<div className='flex columns-7 mb-[20px] text-gray500 text-sm font-normal'>
						{['M', 'T', 'W', 'T', 'F', 'S', 'S'].map((weekDay, index) => (
							<p key={index} className='w-full text-center'>
								{weekDay}
							</p>
						))}
					</div>
					<MonthGrid
						highlightType={highlightType}
						rangeStart={rangeStart}
						rangeEnd={rangeEnd}
						startDate={startDate}
						today={today}
						currentMonth={activeMonth}
						workingDays={workingDays}
						onSelectDay={handleRangeUpdate}
						mepa={mepa}
					/>
				</div>
			</div>
		</div>
	);
};
