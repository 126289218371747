import React from "react";
import logo from './../../assets/images/logo.png';

export const Header = () => {
  return (
    <div className="flex items-center justify-center">
      <img src={logo} alt="Logo" className="h-auto w-6/12" />
    </div>
  );
};
