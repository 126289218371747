import { useState } from 'react'; // Import useState hook
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { toast } from "react-toastify";

import { deleteMepaMeetingById, fetchFixTimeSlots, fetchSelectTimeSlots } from '../../store/scheduledEvents';
import { fetchMyAllUpcomingMeetings } from '../../store/meetingRequests';
import { MeetingDetailLoader } from '../meetings/MeetingDetailLoader';

import { ReactComponent as CalendarIcon } from '../../assets/icons/Calendar.svg';
import { ReactComponent as ClockIcon } from '../../assets/icons/time.svg';
import { ReactComponent as CrossIcon } from '../../assets/icons/Circled_cross.svg';
import { ReactComponent as ExportIcons } from '../../assets/icons/export.svg';


export const MeetingTimeSlotModalMepa = ({
    onClose,
    mepaId,
    setIsActive,
}) => {

    const [selectedSlot, setSelectedSlot] = useState(null);
    const [seletedSlotList, setSelectedSlotList] = useState([]);

    const scheduleEventLoader = useSelector(state => state?.scheduledEvents?.scheduledEventDetailLoading);
    const meetingSlotsWithInvitee = useSelector(state => state?.scheduledEvents?.meetingSlotsWithInvitee);
    const meetingProposalStatus = useSelector(state => state?.scheduledEvents?.meetingProposalStatus);


    const dispatch = useDispatch();

    const handleSlotSelection = (slot) => {
        setSelectedSlot(slot);
    };

    const handleConfirm = () => {
        if (seletedSlotList?.length >= 3) {
            const payload = {
                mepaId: mepaId,
                selectedProposals: seletedSlotList.map(item => item?.meetingProposalId)
            }
            dispatch(fetchSelectTimeSlots(payload));
            dispatch(fetchMyAllUpcomingMeetings())
            onClose();
            setIsActive(true);
        } else {
            toast.error("You have to select at least 3 time slots.");
        }

        if (meetingSlotsWithInvitee && selectedSlot) {
            const payload = {
                mepaId: mepaId,
                proposalId: selectedSlot?.meetingProposalId,
            }
            dispatch(fetchFixTimeSlots(payload));
            dispatch(fetchMyAllUpcomingMeetings());
            onClose();
            setIsActive(true);
        }

    };

    const handleMultipleSlotSelection = (slot) => {
        if (slot?.isValid) {
            const isInclude = seletedSlotList?.find(item => item === slot);
            if (isInclude) {
                const tempArr = seletedSlotList?.filter(item => item !== slot);
                setSelectedSlotList(tempArr);
            } else {
                setSelectedSlotList([...seletedSlotList, slot]);
            }
        }
    }

    const handleDeleteMeeting = () => {
        dispatch(deleteMepaMeetingById({ mepaId: mepaId }));
        onClose();
        setIsActive(true);
    }

    return (
        <>
            {scheduleEventLoader ? <MeetingDetailLoader />
                :
                <div className='w-[560px] font-semibold p-20'>
                    <div>
                        <p className='text-2xl text-center'>Please mark the time slots you want to propose to invitees.</p>
                    </div>
                    <div className='ml-[8%] mt-[10%]'>
                        <p className=''>{meetingProposalStatus?.length > 0 && "Please select at least three time slots"}</p>
                    </div>
                    <div className='flex flex-col items-center my-2'>
                        {
                            !scheduleEventLoader && meetingSlotsWithInvitee && meetingSlotsWithInvitee?.meetingProposalInvites?.length > 0 ?
                                meetingSlotsWithInvitee?.meetingProposalInvites?.map((meetingSlot, index) => {
                                    return (
                                        <>
                                            <div key={index} className='flex items-center gap-8 my-3'>
                                                <div className='flex gap-2 items-center'>
                                                    <CalendarIcon className='text-primary w-8 h-8' fill='transparent' /> {dayjs(meetingSlot.lastScheduleTime).format('DD.MM.YYYY')}
                                                </div>
                                                <div className='flex gap-2 items-center'>
                                                    <ClockIcon className='text-primary w-8 h-8' fill='transparent' /> {dayjs(meetingSlot?.lastScheduleTime).format('HH:MM')}
                                                </div>
                                                <input
                                                    type="checkbox"
                                                    checked={selectedSlot === meetingSlot}
                                                    onChange={() => handleSlotSelection(meetingSlot)}
                                                />
                                            </div>

                                        </>
                                    );
                                })
                                : <>
                                    {
                                        !meetingProposalStatus && <p>No time slots available</p>
                                    }
                                </>

                        }

                        {
                            !scheduleEventLoader && meetingProposalStatus && meetingProposalStatus?.length > 0 ?
                                meetingProposalStatus?.map((meetingSlot, index) => {
                                    return (
                                        <div key={index} className='flex items-center gap-8 my-3'>
                                            <div className='flex gap-2 items-center'>
                                                <CalendarIcon className='text-primary w-8 h-8' fill='transparent' /> {dayjs(meetingSlot.scheduledStart).format('DD.MM.YYYY')}
                                            </div>
                                            <div className='flex gap-2 items-center'>
                                                <ClockIcon className='text-primary w-8 h-8' fill='transparent' />
                                                {dayjs(meetingSlot?.scheduledStart).format('HH:MM')} - {dayjs(meetingSlot?.scheduledFinish).format('HH:MM')}
                                            </div>
                                            <input
                                                type="checkbox"
                                                checked={seletedSlotList?.includes(meetingSlot)} // Check if the slot is selected
                                                onChange={() => handleMultipleSlotSelection(meetingSlot)} // Call handleSlotSelection function on change
                                            />
                                        </div>
                                    );
                                }) :
                                <>
                                    {
                                        !meetingSlotsWithInvitee && <p>No time slots available</p>
                                    }
                                </>

                        }

                        {
                            (meetingSlotsWithInvitee?.meetingProposalInvites?.length > 0 || meetingProposalStatus?.length > 0) &&
                            <>
                                <button onClick={handleConfirm} disabled={selectedSlot ? false : seletedSlotList?.length > 0 ? false : true}
                                    className="px-4 flex items-center gap-2 justify-center mt-6 w-[80%] py-2 shadow rounded-[25px] bg-[#EAF9F8]  border border-gray500" type="button">
                                    {/* Schedule External Meeting */}
                                    <ExportIcons />
                                    {
                                        meetingProposalStatus?.length > 0 ? "Send out invitations" : "Schedule meeting"
                                    }
                                </button>
                                <button onClick={handleDeleteMeeting} className="px-4 w-[80%] flex items-center gap-2 justify-center my-2 py-2 rounded-[25px]  border shadow border-gray500" type="button">
                                    <CrossIcon className='text-primary w-6 h-6' fill='transparent' />  Cancel meeting
                                </button>
                            </>
                        }
                    </div>

                </div>
            }
        </>

    );
};
