const getHashOfString = (string) => {
	let hash = 0;
	for (let i = 0; i < string.length; i++) {
		hash = string.charCodeAt(i) + ((hash << 5) - hash);
	}
	hash = Math.abs(hash);
	return hash;
};

const normalizeHash = (hash, min, max) => {
	return Math.floor((hash % (max - min)) + min);
};

export const generateAvatarColor = (userName) => {
	const nameHash = getHashOfString(userName);
	const h = normalizeHash(nameHash, 0, 360);
	const s = normalizeHash(nameHash, 50, 75);
	const l = normalizeHash(nameHash, 25, 60);
	return `hsl(${h}, ${s}%, ${l}%)`;
};
