import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectAvailableMeetingPlatforms } from '../store/configs';
import { selectLinkedMeetingPlatforms } from '../store/user';

export const useLinkedMeetingApps = () => {
	const availablePlatforms = useSelector(selectAvailableMeetingPlatforms);
	const linkedMeetingApps = useSelector(selectLinkedMeetingPlatforms);

	const [userLinkedMeetingApps, setUserLinkedMeetingApps] = useState([]);

	useEffect(() => {
		let parsedLinkedApps = [];
		if (linkedMeetingApps) {
			parsedLinkedApps = linkedMeetingApps.split(',').map((id) => parseInt(id));
		}

		const userLinkedAppsList = availablePlatforms.map((app) => ({
			...app,
			isConnected: parsedLinkedApps.includes(app.id),
		}));

		setUserLinkedMeetingApps(userLinkedAppsList);
	}, [availablePlatforms, linkedMeetingApps]);

	return userLinkedMeetingApps;
};
