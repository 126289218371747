import { useState } from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import PageTitle from '../../components/PageTitle';
import { Spinner } from '../../components/Spinner';
import { Input, Textarea } from '../../theme';
import { selectLoggedInUser } from '../../store/user';
import { ReactComponent as SupportIcon } from '../../assets/icons/support.svg';
import { selectServerUrl } from '../../store/configs';
import axiosClient from '../../utils/axiosClient';
import { QUERY_SUBMISSION_API } from '../../utils/apiRoutes';

export const SupportCenter = () => {
	const currentUser = useSelector(selectLoggedInUser);
	const serverUrl = useSelector(selectServerUrl);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [successMessage, setSuccessMessage] = useState(null);
	const [errorMessage, setErrorMessage] = useState(null);

	const validateFrom = (values) => {
		const errors = {};
		try {
			if (!values.body) {
				errors.body = 'Please provide the message';
			}
			if (
				!values.PhoneNumber.match(
					/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/
				)
			) {
				errors.PhoneNumber = 'Please provide a valid phone number';
			}
		} catch (error) {
			console.log('VALIDATION ERROR:::', error);
		}
		return errors;
	};

	const { values, errors, touched, handleChange, handleSubmit, resetForm } =
		useFormik({
			initialValues: {
				firstName: currentUser.userFirstName,
				lastName: currentUser.userLastName || currentUser.userFirstName,
				email: currentUser.userName,
				PhoneNumber: currentUser.phoneNumber || '',
				body: '',
			},
			validate: validateFrom,
			onSubmit: async (values) => {
				try {
					setIsSubmitting(true);
					setSuccessMessage(null);
					setErrorMessage(null);

					await axiosClient.post(
						`${serverUrl}/${QUERY_SUBMISSION_API}`,
						values
					);
					// SET SUCCESS MESSAGE
					setSuccessMessage('Your query has been submitted successfully.');
					resetForm({
						firstName: currentUser.userFirstName,
						lastName: currentUser.userLastName || currentUser.userFirstName,
						email: currentUser.userName,
						PhoneNumber: currentUser.phoneNumber || '',
						body: '',
					});
				} catch (error) {
					setErrorMessage(
						'There was an error with your submission, please retry.'
					);
				} finally {
					setIsSubmitting(false);
				}
			},
		});

	return (
		<div className='scroll'>
			<PageTitle Icon={SupportIcon} text='Support Center' />
			<div className='p-8 flex font-semibold'>
				<div className='mt-[105px] ml-[68px] mr-4'>
					<h2 className='text-3xl mb-4 font-normal'>Contact us</h2>
					<p className='max-w-[462px] leading-loose'>
						We are happy to help you in case of any questions. Reach out to our
						support team via email or the contact form.
					</p>
					<a
						className='flex mt-7 leading-loose'
						href='mailto:support@zelvor.com'
					>
						support@zelvor.com
					</a>
					{/* <a className='flex mt-7 leading-loose' href='tel:+45 2345543210'>
						+45 2345543210
					</a> */}
				</div>
				<div className='flex justify-end flex-grow'>
					<div
						className={clsx(
							'bg-neutral border border-gray500 rounded-[10px]',
							'shadow-theme',
							'w-[500px] 5xl:w-[600px]'
						)}
					>
						<div className='border-b border-gray500 pt-8 px-6 pb-6'>
							<h3 className='text-2xl'>Contact us</h3>
						</div>
						<form onSubmit={handleSubmit}>
							<div className='pt-9 px-[50px] pb-8'>
								<div className='flex'>
									<Input
										name='firstName'
										placeholder='First Name'
										label='First Name'
										value={values.firstName}
										className='!border-0 !pl-0'
										containerClassName='!text-gray500'
										disabled={true}
									/>
									<Input
										name='lastName'
										placeholder=''
										label='Last Name'
										value={values.lastName}
										className='!border-0 !pl-0'
										containerClassName='!text-gray500'
										disabled={true}
									/>
								</div>
								<Input
									name='email'
									type='eamil'
									placeholder='Business e-mail'
									label='E-mail'
									value={values.email}
									className='!border-0 !pl-0'
									containerClassName='!text-gray500'
									disabled={true}
								/>
								<Input
									name='PhoneNumber'
									placeholder='Phone number'
									label='Phone number'
									value={values.PhoneNumber}
									className={
										!(errors.PhoneNumber && touched.PhoneNumber) &&
										'!border-gray500'
									}
									containerClassName='!text-gray500'
									onChange={handleChange}
									hasError={!!(errors.PhoneNumber && touched.PhoneNumber)}
									error={errors.PhoneNumber}
								/>
								<Textarea
									name='body'
									value={values.body}
									placeholder='How can we help?'
									className={
										!(errors.body && touched.body) && '!border-gray500'
									}
									containerClassName='!text-gray500 !mt-6'
									onChange={handleChange}
									hasError={!!(errors.body && touched.body)}
									error={errors.body}
								/>
								<p className='text-xs my-4 text-gray500'>
									By submitting this form, you agree to accept the following{' '}
									<a
										className='text-info400 underline'
										target='_blank'
										rel='noreferrer'
										href='https://zelvor.com/terms-conditions/'
									>
										Terms of Use
									</a>{' '}
									for the use of the Site, which constitute a legal agreement
									between you and Zelvor.io , which reserves the right to modify
									these Terms of Use without notice, and each use of the Site
									constitutes your acceptance to be bound by the terms set forth
									in the Terms of Use as modified at the time of such use. Your
									data is protected by our{' '}
									<a
										className='text-info400 underline'
										target='_blank'
										rel='noreferrer'
										href='https://zelvor.com/privacy-policy/'
									>
										privacy policy
									</a>
									.
								</p>
								{successMessage && (
									<p
										className={clsx(
											'text-success bg-success100 border border-success',
											'w-full my-4 text-sm  p-2 font-normal  rounded-[4px]'
										)}
									>
										{successMessage}
									</p>
								)}
								{errorMessage && (
									<p
										className={clsx(
											'text-danger bg-danger400 border border-danger',
											'w-full my-4 text-sm  p-2 font-normal  rounded-[4px]'
										)}
									>
										{errorMessage}
									</p>
								)}
								<button
									className={clsx(
										'bg-neutral border border-gray500 rounded-full',
										'px-[25px] py-[11px]',
										'shadow-theme'
									)}
									type='submit'
									disabled={isSubmitting}
								>
									<span>Submit</span>
									{isSubmitting && <Spinner className='ml-2' />}
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};
