import clsx from 'clsx';
import { ReactComponent as AgendaIcon } from '../../assets/icons/Agenda.svg';

export const MeetingAgendModal = ({ agendaText, onClose }) => {
	return (
		<div className={clsx('font-semibold max-w-[660px] w-[90vw]')}>
			<p
				className={clsx(
					'flex gap-2',
					'text-base font-normal',
					'px-9 pt-4 pb-3',
					'border-b border-gray500 '
				)}
			>
				<AgendaIcon fill='transparent' />
				<span>Agenda</span>
			</p>
			<div className='px-9 py-6'>
				<div className='mb-6 font-normal flex flex-col gap-3 text-justify'>
					{agendaText}
				</div>
				<div className='text-center'>
					<button
						className={clsx(
							'border border-gray500',
							'w-[320px] px-[25px] py-3 rounded-full',
							'shadow-theme'
						)}
						onClick={onClose}
					>
						<span className='flex-grow'>Close</span>
					</button>
				</div>
			</div>
		</div>
	);
};
