// AUTH TOKEN
export const getStoredAuthToken = () => {
	return localStorage.getItem('authToken');
};

export const saveAuthTokenLocally = (token) => {
	localStorage.setItem('authToken', token);
};

export const deleteAuthTokenLocally = () => {
	localStorage.removeItem('authToken');
};

// SERVER CONFIGS
export const getServerUrl = () => {
	return localStorage.getItem('zelvorServerUrl');
};

export const updateServerUrl = (url) => {
	localStorage.setItem('zelvorServerUrl', url);
};

export const deleteServerUrl = () => {
	localStorage.removeItem('zelvorServerUrl');
};

// User Creds
export const getStoredLoginData = () => {
	return localStorage.getItem('loginData');
};

export const saveLoginDataLocally = (cypherText) => {
	localStorage.setItem('loginData', cypherText);
};
