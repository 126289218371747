import { useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import { Tooltip } from "react-tooltip";
import { ParticipantsChip } from "../ParticipantsChip";
import { getHoursDifferenceBetweenTimes } from "../../utils/dateTime";

import { ReactComponent as ChevronIcon } from "../../assets/icons/Chevron-Circle.svg";
import { ReactComponent as ChevronDangerIcon } from "../../assets/icons/chevron-danger.svg";

export const CalendarEventChipLarge = ({
  meetingId,
  meetingTime,
  topic,
  meetingLength,
  meetingStart,
  nonAdjustable,
  isProposal,
  isExternalMeeting,
  meetingPlatform,
  participants,
  remainingCounter,
  dayStartTime,
  onViewEventDetail,
  overlapping,
  numberOfOverlappingEvents,
}) => {

  const [widthPercentage, setWidthPercentage] = useState('100%');

  useEffect(() => {
    if (overlapping) {
      const result = `${parseInt(100 / (numberOfOverlappingEvents + 1))}%`;
      setWidthPercentage(result);
    }

  }, [overlapping, numberOfOverlappingEvents]);


  const { postionTop, chipHeight } = useMemo(() => {
    const hoursFromDayStart = getHoursDifferenceBetweenTimes(
      dayStartTime,
      meetingStart
    );

    if (overlapping) {
      return {
        postionTop: hoursFromDayStart * 91 + 2,
        chipHeight: meetingLength * 91 - 2
      };
    } else {
      return {
        postionTop: hoursFromDayStart * 93.5 + 2,
        chipHeight: meetingLength * 93.5 - 2,
      };
    }
  }, [dayStartTime, meetingStart, meetingLength]);

  const handleEventDetail = () => {
    if (isExternalMeeting) {
      return;
    }
    onViewEventDetail(meetingId, isProposal);
  };

  return (
    <div
      className={clsx(
        `border rounded-[8px] ${overlapping
          ? `relative hover:z-10`
          : `absolute md:w-[87%] w-auto hover:z-10`
        }  cursor-pointer`,
        "bg-gradient-to-b text-neutral min-h-[35px]",
        isExternalMeeting
          ? "border-success bg-success100 !text-success !cursor-default"
          : nonAdjustable
            ? "border-[transparent] from-gradientDangerStart to-gradientDangerEnd"
            : "border-info400 from-gradientInfoStart to-gradientInfoEnd"
      )}
      style={overlapping ? { width: widthPercentage, height: chipHeight, top: postionTop, } : { height: chipHeight, top: postionTop, }}
      onClick={handleEventDetail}
      id={`calendarMeeting${meetingId}`}
    >
      <div className="flex flex-col justify-around h-full px-2 py-[2px]">
        <div
          className={clsx(
            "flex items-center justify-between gap-8",
            "whitespace-nowrap"
          )}
        >
          <p
            className={clsx(
              "text-md leading-none",
              "overflow-hidden text-ellipsis"
            )}
          >
            {isExternalMeeting ? "External Meeting" : topic}
          </p>
          <div className="flex items-center">
            <p className="text-xs leading-none">{meetingTime}</p>
          </div>
        </div>
        {/* Display this only if meeting length is more than 1hour */}
        {meetingLength >= 1 && (
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2 -mt-2">
              {!isExternalMeeting && (
                <>
                  <ParticipantsChip
                    remainingCounter={remainingCounter}
                    participants={participants}
                    containerClassName="bg-neutral border-neutral"
                  />
                  <p className="text-base">{meetingPlatform}</p>
                </>
              )}
            </div>

            {!isExternalMeeting && (
              <div className="w-8 h-8 rounded-full cursor-pointer bg-neutral">
                {nonAdjustable ? (
                  <ChevronDangerIcon
                    className="w-full h-full"
                    fill="transparent"
                  />
                ) : (
                  <ChevronIcon className="w-full h-full" fill="transparent" />
                )}
              </div>
            )}
          </div>
        )}
      </div>
      {isExternalMeeting && (
        <Tooltip
          anchorSelect={`#calendarMeeting${meetingId}`}
          content="Please check your external calendar"
          place="top-start"
          offset={4}
          className="!py-1 !px-2 !rounded-md !text-[10px] text-center"
          noArrow={true}
        />
      )}
    </div>
  );
};
