import { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import Avatar from '../Avatar';
import { ParticipantsChip } from '../ParticipantsChip';

import { ReactComponent as ChevronDownIcon } from '../../assets/icons/Chevron2.svg';
import { ReactComponent as CrossIcon } from '../../assets/icons/Cross.svg';

export const SelectedUsersDropdown = ({
	selectedUsers,
	parsedSelectedUsers,
	remaingCounter,
	onRemoveUser,
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const dropdownRef = useRef(null);
	const participatnsRef = useRef(null);

	const toggleDropdown = () => {
		setIsOpen(!isOpen);
	};

	const onHandleRemove = (userId) => {
		onRemoveUser(userId);
		toggleDropdown();
	};

	useEffect(() => {
		// Listen to outside clicks
		const handleOutsideClick = (event) => {
			if (
				dropdownRef.current &&
				participatnsRef.current &&
				!dropdownRef.current.contains(event.target) &&
				!participatnsRef.current.contains(event.target)
			) {
				setIsOpen(false);
			}
		};

		if (isOpen) {
			document.addEventListener('mousedown', handleOutsideClick);
		} else {
			document.removeEventListener('mousedown', handleOutsideClick);
		}
		return () => {
			document.removeEventListener('mousedown', handleOutsideClick);
		};
	}, [isOpen, dropdownRef.current, participatnsRef.current]);

	return (
		<div className='flex items-center gap-4'>
			<div ref={participatnsRef}>
				<ParticipantsChip
					containerClassName={clsx(
						'!border-gray500 !bg-gray500 !cursor-pointer'
					)}
					participants={parsedSelectedUsers}
					remainingCounter={remaingCounter}
					onClick={toggleDropdown}
				/>
			</div>
			{selectedUsers.length > 0 && (
				<div className='relative' ref={dropdownRef}>
					<button onClick={toggleDropdown}>
						<ChevronDownIcon
							fill='transparent'
							className={clsx(
								'w-4 h-4 text-primary font-semibold',
								isOpen && 'rotate-180'
							)}
						/>
					</button>
					{isOpen && (
						<div
							className={clsx(
								'absolute right-0 z-10 bg-neutral',
								'mt-2 py-2 border border-gray500 rounded-[10px] shadow-theme w-[220px]'
							)}
						>
							{selectedUsers.map((selectedUser) => (
								<div
									key={selectedUser.id}
									className={clsx(
										'flex items-center gap-1 px-2 py-1 text-xs my-1 select-none'
									)}
								>
									<Avatar
										userId={selectedUser.id}
										firstName={selectedUser.firstName}
										lastName={selectedUser.lastName}
										profileById={true}
										className='w-6 h-6 pointer-events-none'
									/>
									<div className=''>
										<p>{`${selectedUser.firstName} ${selectedUser.lastName}`}</p>
										<p className='text-[10px] text-gray500'>
											{selectedUser.userName}
										</p>
									</div>
									<div className='flex-grow text-right'>
										<CrossIcon
											fill='transparent'
											className='text-danger cursor-pointer ml-auto w-5 h-5'
											onClick={() => onHandleRemove(selectedUser.id)}
										/>
									</div>
								</div>
							))}
						</div>
					)}
				</div>
			)}
		</div>
	);
};
