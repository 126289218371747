import clsx from 'clsx';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { passwordRecoverFormSchema } from '../utils/formsValidators';
import {
	passwordRecovery,
	resetRecoveryExpirationTimout,
	selectPasswordRecoveryRequestData,
} from '../store/user';
import { Input } from '../theme/Input';
import { useEffect } from 'react';

export const RecoverPassword = () => {
	const dispatch = useDispatch();
	const { loading, expiresAt } = useSelector(selectPasswordRecoveryRequestData);

	const { handleChange, errors, values, touched, handleSubmit } = useFormik({
		initialValues: {
			username: '',
		},
		validationSchema: passwordRecoverFormSchema,
		onSubmit: async (values) => {
			dispatch(passwordRecovery(values));
		},
	});

	useEffect(() => {
		if (expiresAt) {
			setTimeout(() => {
				dispatch(resetRecoveryExpirationTimout());
			}, 1000 * 60 * 5);
		}
	}, [expiresAt]);

	return (
		<div>
			<h3 className='text-center mt-[58px] text-2xl font-semibold text-primary'>
				Recover Password
			</h3>
			{expiresAt && (
				<p className='p-2 rounded-[4px] bg-success400 text-success font-semibold text-xs mt-3'>
					An email with with instructions has been sent.
				</p>
			)}
			<form onSubmit={handleSubmit}>
				<Input
					name='username'
					onChange={handleChange}
					value={values.username}
					placeholder='admin@admin.com'
					hasError={!!(errors.username && touched.username)}
					error={errors.username}
					className='mt-6 p-[12px] !text-base'
				/>
				<Link
					to='/auth/login'
					className='flex justify-center mt-[10px] mx-auto text-gray500 font-normal underline w-fit'
				>
					Back to login
				</Link>
				<button
					className={clsx(
						'flex w-[186px] justify-center',
						'py-[10px] px-[20px] rounded-[20px] mt-6 mx-auto',
						'text-neutral font-semibold text-xs',
						loading || expiresAt ? 'bg-gray500' : 'bg-primary'
					)}
					type='submit'
					disabled={loading || expiresAt}
				>
					{loading ? 'Submitting...' : 'Submit'}
				</button>
			</form>
		</div>
	);
};
