import PropTypes from "prop-types";

export const LinkCard = ({ title, onClick, iconSrc }) => {
  return (
    <a
      href="#"
      className="w-full max-w-md p-3 border border-gray-400 rounded mb-2 flex cursor-pointer items-center"
      onClick={onClick}
    >
      <img src={iconSrc} alt={title} className="mr-2" />
      <div className="flex flex-col justify-start">
        <p className="text-sm">{title}</p>
      </div>
    </a>
  );
};

LinkCard.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  iconSrc: PropTypes.string.isRequired,
};
