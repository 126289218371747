import { useMemo } from 'react';
import clsx from 'clsx';
import ReactSelect from 'react-select';

export const Select = ({
	name,
	value,
	options,
	placeholder,
	onChange,
	onBlur,
	containerClassName,
	controlClassName,
	menuPortalClassName,
	disabled,
	hasError,
	error,
	errorClassName,
	isSearchable,
	scrollId
}) => {
	const handleBlur = (e) => {
		onBlur && onBlur(name);
	};
	const handleChange = (selected) => {
		onChange(name)(selected.value);
	};

	const selectedOption = useMemo(
		() => options?.find((option) => option.value === value),
		[value, options]
	);


	return (
		<div className=''>
			<ReactSelect
				value={selectedOption}
				name={name}
				maxMenuHeight={220}
				menuPlacement="auto"
				placeholder={placeholder}
				onChange={handleChange}
				onBlur={handleBlur}
				options={options || []}
				components={{ ClearIndicator: null, IndicatorSeparator: null }}
				isSearchable={!!isSearchable}
				isDisabled={disabled}
				isOptionDisabled={({ isDisabled }) => isDisabled}
				menuPortalTarget={document.body}
				classNames={{
					container: () => clsx(containerClassName),
					menuList: () => `text-sm font-semibold ${scrollId === 'scroll' ? 'custom-scrollbar' : ''}`,
					menuPortal: () => clsx('!z-20', menuPortalClassName),
					control: () =>
						clsx(
							'text-sm font-thin',
							controlClassName,
							!disabled && '!bg-[transparent]',
							hasError && '!border-danger'
						),
					dropdownIndicator: () => '!py-0 !pl-0',
				}}
			/>
			{hasError && (
				<p className={clsx('text-danger text-sm', errorClassName)}>{error}</p>
			)}
		</div>
	);
};
