import { useCallback, useMemo } from 'react';
import dayjs from 'dayjs';
import { CalendarMonthDay } from './CalendarMonthDay';

export const CalendarMonthGrid = ({
	today,
	workingDays,
	currentMonth,
	scheduledEventsList,
	onViewEventDetail,
	onViewAllDateEvents,
}) => {
	const daysArray = useMemo(() => {
		const currentDate = dayjs(currentMonth);

		// Get the first day of the month and the last day of the month
		const startOfMonth = currentDate.startOf('month');
		const lastOfMonth = currentDate.endOf('month');
		const firstDayOfMonth = startOfMonth.get('day');

		const adjustedStartDayOfWeek =
			firstDayOfMonth === 0 ? 6 : firstDayOfMonth - 1;
		const daysInPreviousMonth = adjustedStartDayOfWeek;

		const daysInCurrentMonth = currentDate.daysInMonth();

		// Calculate the first and last day to display in the calendar
		const remainingDays = 6 * 7 - (daysInPreviousMonth + daysInCurrentMonth);

		const firstDayToDisplay = startOfMonth.subtract(daysInPreviousMonth, 'day');
		const lastDayToDisplay = lastOfMonth.add(remainingDays, 'day');

		const calendarDates = [];
		let currentDateToDisplay = firstDayToDisplay;
		while (currentDateToDisplay.isSameOrBefore(lastDayToDisplay)) {
			calendarDates.push(dayjs(currentDateToDisplay));
			currentDateToDisplay = currentDateToDisplay.add(1, 'day');
		}

		return calendarDates;
	}, [currentMonth]);

	const getCurrentDayValues = useCallback(
		(date) => {
			const isToday = date.isSame(today, 'date');
			const isPreviousMonth = date.isBefore(currentMonth, 'month');
			const isNextMonth = date.isAfter(currentMonth, 'month');
			const isWorkingDay = workingDays.includes(date.get('day'));

			const formattedDate = date.format('YYYY-MM-DD');

			const eventsOnDay = scheduledEventsList[formattedDate] || [];
			const filteredEvents = eventsOnDay.slice(0, 2);

			return {
				dayKey: date.toISOString(),
				dayValue: date.get('date'),
				isToday,
				disabled: isPreviousMonth || isNextMonth,
				isWorkingDay,
				eventsOnDay: filteredEvents,
				hasEvents: eventsOnDay.length > 0,
				overflowMeetingsCount: eventsOnDay.length - filteredEvents.length,
			};
		},
		[today, currentMonth, workingDays, scheduledEventsList]
	);

	return (
		<div className='grid grid-cols-7 text-sm h-[calc(100vh-340px-40px)] overflow-y-scroll'>
			{daysArray.map((date, index) => {
				const {
					dayKey,
					dayValue,
					isToday,
					disabled,
					isWorkingDay,
					eventsOnDay,
					hasEvents,
					overflowMeetingsCount,
				} = getCurrentDayValues(date);

				return (
					<CalendarMonthDay
						key={dayKey}
						dateValue={dayValue}
						isToday={isToday}
						disabled={disabled}
						isWorkingDay={isWorkingDay}
						hasMeeting={hasEvents}
						scheduledEvents={eventsOnDay}
						overflowMeetingsCount={overflowMeetingsCount}
						onViewEventDetail={onViewEventDetail}
						onViewAllDateEvents={() => onViewAllDateEvents(date)}
					/>
				);
			})}
		</div>
	);
};
