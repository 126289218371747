import clsx from 'clsx';
import { ReactComponent as TickIcon } from '../../assets/icons/Tick.svg';
import { Spinner } from '../Spinner';

export const DeleteAbsenceModal = ({
	absenceId,
	actionLoading,
	actionError,
	actionSucces,
	onConfirm,
	onCancel,
}) => {
	return (
		<div className='w-[560px] font-semibold relative'>
			{!actionSucces && (
				<div className='border-b border-gray500 pt-[22px] pl-[26px] pb-1'>
					<h3 className='text-2xl text-gray500'>Confirmation required</h3>
				</div>
			)}
			{!actionSucces && (
				<div className='text-center'>
					<h3 className='text-2xl mt-9'>Are you sure you want to delete?</h3>
					{actionError && (
						<p className='mt-2 text-xs text-danger'>{actionError}</p>
					)}
					<div className='flex items-center justify-center gap-9 mb-6 mt-9'>
						<button
							className={clsx(
								'flex items-center gap-8',
								'border border-gray500',
								'w-[190] px-[25px] py-3 bg-info100 rounded-full',
								'shadow-theme'
							)}
							onClick={() => onConfirm(absenceId)}
							disabled={actionLoading}
						>
							<TickIcon fill='transparent' />
							<span className='flex-grow text-left'>Delete Absence</span>
							{actionLoading && <Spinner />}
						</button>
						<button
							className='underline text-base leading-8'
							onClick={onCancel}
							disabled={actionLoading}
						>
							Go back
						</button>
					</div>
				</div>
			)}
			{actionSucces && (
				<div className={clsx('flex flex-col items-center', 'my-6')}>
					<div
						className={clsx(
							'flex items-center justify-center',
							'bg-[green] w-[70px] h-[70px] text-[white] rounded-full'
						)}
					>
						<TickIcon fill='transparent' />
					</div>
					<h3 className='text-2xl my-3'>{actionSucces}</h3>
					<button
						className={clsx(
							'flex items-center justify-center',
							'border border-gray500',
							'w-[280px] px-[25px] py-3 mx-auto bg-neutral rounded-full',
							'shadow-theme'
						)}
						type='button'
						onClick={onCancel}
					>
						Close
					</button>
				</div>
			)}
		</div>
	);
};
