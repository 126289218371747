import clsx from 'clsx';
import { ReactComponent as TickIcon } from '../../assets/icons/Tick.svg';
import { Spinner } from '../Spinner';
import { ActionSuccessModal } from './ActionSuccessModal';
import { ActionErrorModal } from './ActionErrorModal';

export const ConfirmationModal = ({
	description,
	confirmButtonTitle,
	actionLoading,
	actionError,
	actionSuccess,
	errorModalTitle,
	onConfirm,
	onCancel,
	onClearError,
}) => {
	return (
		<>
			{!actionSuccess && !actionError && (
				<div className='font-semibold relative'>
					<div className='border-b border-gray500 pt-[22px] pl-[26px] pb-1'>
						<h3 className='text-2xl text-gray500'>Confirmation required</h3>
					</div>
					<div className='text-center px-2'>
						<h5 className='text-base mt-9'>{description}</h5>
						<div className='flex items-center justify-center gap-9 mb-6 mt-9'>
							<button
								className={clsx(
									'flex items-center',
									'border border-gray500',
									'px-2 py-1 bg-info100 rounded-full',
									'shadow-theme'
								)}
								onClick={onConfirm}
								disabled={actionLoading}
							>
								<TickIcon fill='transparent' />
								<span className='flex-grow text-center px-1'>
									{confirmButtonTitle}
								</span>
								{actionLoading && <Spinner />}
							</button>
							<button
								className='underline text-base leading-8'
								onClick={onCancel}
								disabled={actionLoading}
							>
								Go back
							</button>
						</div>
					</div>
				</div>
			)}
			{actionSuccess && (
				<ActionSuccessModal message={actionSuccess} onClose={onCancel} />
			)}
			{actionError && (
				<ActionErrorModal
					onClose={onCancel}
					onClearError={onClearError}
					modalTitle={errorModalTitle}
					message={actionError}
				/>
			)}
		</>
	);
};
