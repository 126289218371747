import { useSelector } from 'react-redux';
import { selectUserAuthData } from '../../store/user';
import { Navigate } from 'react-router-dom';
import { AuthLoader } from './AuthLoader';

export const ProtectedRouter = ({ children }) => {
	const { currentUser, isAuthenticating } = useSelector(selectUserAuthData);

	if (!isAuthenticating && !currentUser) {
		return <Navigate to='/auth/login' />;
	}

	if (isAuthenticating) {
		return <AuthLoader />;
	}

	return children;
};
