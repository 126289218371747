import { Spinner } from '../../Spinner';

export const MeetingListingLoader = () => {
	return (
		<tr>
			<td className='text-center py-2' colSpan='7'>
				<Spinner className='!w-6 !h-6' />
			</td>
		</tr>
	);
};
